import React, { useState, useEffect } from 'react';
import { Breadcrumbs, IconButton, Menu, MenuItem, GlobalStyles, Link } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

import { menuItemData } from 'components/layout/data/GnbItemData';

// Icon
import { HomeIcon16, DarkHomeIcon16, BreadCrumbArrow16, DarkBreadCrumbArrow16 } from 'components/component/BasicIcon';
import BreadCrumbArrow_light from '../../assets/images/icon/ic_breadCurmb_arrow.svg';
import BreadCrumbArrow_dark from '../../assets/images/icon/ic_breadCurmb_arrow_dark.svg';

const globalStyles = (
  <GlobalStyles
    styles={{
      '.MuiPaper-root': {
        '.MuiButtonBase-root': {
          fontSize: '14px',
        },
        '&.MuiMenu-paper': {
          '.dark &': {
            backgroundColor: '#1C1C1C',
            border: '1px solid #484848',
          },
        },
      },
    }}
  />
);

// Styled
const CustomBreadcrumbs = styled(Breadcrumbs)`
  .MuiBreadcrumbs-ol {
    .MuiBreadcrumbs-li {
      .home-icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
      &:last-child {
        .path-arrow {
          display: none;
        }
      }

      .breadcrumb-item {
        display: flex;
        align-items: center;
        .MuiTypography-root {
          font-size: 14px;
          font-weight: 400;
          color: #909090;
          text-decoration: none;

          .dark & {
            color: #5d5d5d;
          }
        }
      }
      .option-wrap {
        display: flex;
        .MuiButtonBase-root {
          padding: 0;
          margin-left: 8px;
        }
      }
      .btn-breadcrumb-menu {
        display: inline-block;
        width: 18px;
        height: 18px;
        border-radius: 4px;
        background: url(${BreadCrumbArrow_light}) no-repeat;

        .dark & {
          background: url(${BreadCrumbArrow_dark}) no-repeat;
        }
      }
      .path-arrow {
        width: 16px;
        height: 16px;
        margin: 0 10px;
      }

      .MuiTypography-root {
        &.single-item {
          color: #000;

          .dark & {
            color: #fff;
          }
        }
      }
      .MuiTouchRipple-root {
        .dark & {
          border-color: #5d5d5d;
        }
      }
    }
  }

  .MuiBreadcrumbs-separator {
    width: 0;
    margin: 0;
  }
`;

// 메인 컨텐츠
function BasicBreadCrumb({ optionMenu, darkMode }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeMenu, setActiveMenu] = React.useState(null);
  const [breadcrumbsMain, setBreadcrumbsMain] = useState([]);
  const [gnbText, setGnbText] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (optionMenu) {
      const newBreadcrumbs = [
        { text: 'Home', path: '/', icon: true, single: false },
      ];

      // gnbMenu 처리
      if (optionMenu.gnbMenu) {
        const gnbItem = menuItemData.find(item => item.primaryText === optionMenu.gnbMenu);
        if (gnbItem) {
          newBreadcrumbs.push({
            text: gnbItem.primaryText,
            options: menuItemData
              .filter(item => item.primaryText !== 'Favorite Menu' && item.primaryText !== gnbItem.primaryText)
              .map(item => item.primaryText),
            single: false,
          });

          setGnbText(gnbItem.primaryText);

          // lnbMenu 처리
          if (optionMenu.lnbMenu) {
            const lnbItem = gnbItem.subItems.find(sub => sub.text === optionMenu.lnbMenu);
            if (lnbItem) {
              newBreadcrumbs.push({
                text: lnbItem.text,
                options: gnbItem.subItems
                  .filter(sub => sub.text !== lnbItem.text)
                  .map(sub => sub.text),
                single: false,
              });
            }
          }
        }
      }

      // normalMenu 처리
      if (optionMenu.normalMenu) {
        optionMenu.normalMenu.forEach((menu) => {
          newBreadcrumbs.push({
            text: menu,
            single: true,
          });
        });
      }

      setBreadcrumbsMain(newBreadcrumbs);
    }
  }, [optionMenu]);

  const handleClickMenu = (event, index) => {
    setAnchorEl(event.currentTarget);
    setActiveMenu(index);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setActiveMenu(null);
  };

  const handleBreadcrumbMenu = (event, breadcrumb) => {
    event.preventDefault();

    if (breadcrumb.path) {
      navigate(breadcrumb.path, { replace: true, state: { move: true, targetComponent: breadcrumb.text } });
    }
  };

  const handleOptionMenu = (event, optionMenu) => {
    event.preventDefault();
    handleCloseMenu();

    const gnbItem = menuItemData.find(item => item.primaryText === optionMenu);

    // OptionMenu가 Gnb item인 경우
    if (gnbItem) {
      if (gnbItem.subItems[0] && gnbItem.subItems[0].path) {
        navigate(gnbItem.subItems[0].path, { replace: true, state: { move: true, targetComponent: gnbItem.subItems[0].text } });
      }
    } else {
      const tempGnbItem = menuItemData.find(item => item.primaryText === gnbText);
      const lnbItem = tempGnbItem ? tempGnbItem.subItems.find(sub => sub.text === optionMenu) : '';

      if (lnbItem && lnbItem.path) {
        navigate(lnbItem.path, { replace: true, state: { move: true, targetComponent: lnbItem.text } });
      }
    }
  };

  return (
    <>
      {globalStyles}
      <CustomBreadcrumbs aria-label='breadcrumb' className='breadcrumb-container' separator={null}>
        {breadcrumbsMain.map((breadcrumb, index) => (
          <div className='breadcrumb-item' key={index}>
            {breadcrumb.icon && <div className='home-icon'>{darkMode ? <DarkHomeIcon16 /> : <HomeIcon16 />}</div>}

            <Link component='a' href={breadcrumb.path} onClick={(event) => handleBreadcrumbMenu(event, breadcrumb)} className={breadcrumb.single ? 'single-item' : ''}>
              {breadcrumb.text}
            </Link>

            {breadcrumb.options && (
              <div className='option-wrap'>
                <IconButton aria-label='dropdown' size='small' onClick={(event) => handleClickMenu(event, index)}>
                  <span className='btn-breadcrumb-menu'></span>
                </IconButton>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl) && activeMenu === index}
                  onClose={handleCloseMenu}
                  disableScrollLock
                >
                  {breadcrumb.options.map((option) => (
                    <MenuItem
                      key={option}
                      onClick={(event) => handleOptionMenu(event, option)}
                    >{option}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            )}
            <div className='path-arrow'>{darkMode ? <DarkBreadCrumbArrow16 /> : <BreadCrumbArrow16 />}</div>
          </div>
        ))}
      </CustomBreadcrumbs>
    </>
  );
}

export default BasicBreadCrumb;
