import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { InputAdornment, Container, Typography, Box, Link } from '@mui/material';
import TextField from 'components/component/BasicTextField';
import CheckBox from 'components/component/BasicCheckBox';
import Button from 'components/component/BasicButton';
import { styled } from '@mui/material/styles';

// images
import LoginIdIcon from 'assets/images/icon/ic_id_24_outline.svg';
import LoginPwIcon from 'assets/images/icon/ic_pass_24_outline.svg';

// css
import styles from 'assets/scss/page/login.module.scss';

import { BASE_URL } from 'constants/index';
import { getHeaders } from 'utils/Header';

const StyledContainer = styled(Container)`
  .MuiInputBase-root {
    .dark & {
      background: #1b1b1b;
    }
  }
  .large {
    .MuiInputBase-input {
      height: 56px;
    }
  }
  && {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f7f7f7;
    max-width: none;

    .dark & {
      background: #0c0c0c;
    }
  }
`;

const Title = styled(Typography)`
  font-size: 32px;
  font-weight: 700;
  color: #000;
  margin-bottom: 32px;

  .dark & {
    color: #fff;
  }
`;
const LoginWrapBox = styled(Box)`
  width: 400px;
`;
const CheckBoxWrap = styled(Box)`
  padding: 16px 0 32px;
`;
const ButtonWrap = styled(Box)`
  margin-bottom: 64px;
`;
const CopyRightText = styled(Typography)`
  color: #909090;
  font-size: 12px;
  font-weight: 400;
`;
const ContactNotice = styled(Typography)`
  color: #909090;
  font-size: 12px;
  font-weight: 400;
`;
const TextLink = styled(Link)`
  padding-left: 3px;
  font-weight: 700;
  color: #000;
  text-decoration: none;

  .dark & {
    color: #fff;
  }
`;
const WarningText = styled(Typography)`
  color: #e71414;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 5px;
`;

function Login(props) {
  const { message, currentUser } = props;

  const [name, setName] = React.useState('');
  const [password, setPassword] = useState('');
  const [isSaveId, setSaveId] = useState(false)
  const [isLoginDisplay, setLoginDisplay] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    setLoginErrorMessage('');
    setLoginDisplay(true);
  }, []);

  useEffect(() => {
    if (currentUser) {
      setSaveId(currentUser.isSaveId);
      setName(currentUser.name);
    }
  }, [currentUser]);

  useEffect(() => {
    if (message) {
      setLoginErrorMessage(message);
    }
  }, [message]);

  const handleIdChange = (event) => {
    setName(event.target.value);

    if (isSaveId) {
      const updateCurrentUser = {
        ...currentUser,
        name: event.target.value,
        isSaveId: isSaveId,
      };

      localStorage.setItem('currentUser', JSON.stringify(updateCurrentUser));
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = (data) => {
    const updateCurrentUser = {
      ...currentUser,
      name: name,
      accessToken: data.accessToken,
      isAuthenticated: true,
      loginTime: new Date().toISOString(),
      isSaveId: isSaveId,
      provider: data.provider,
      accessMenuList: data.accessMenuList,
      role: data.role,
      authority: data.authority,
    };

    localStorage.setItem('currentUser', JSON.stringify(updateCurrentUser));

    navigate('/', { replace: true, state: { auth: 'true', message: '' } });
  };

  const signinRequest = () => {
    if (!name || !password) {
      setLoginErrorMessage(!name ? 'Please enter your ID' : 'Please enter your password');
      return;
    }

    const userInfo = {
      usernameOrEmail: name,
      password: password,
    };

    fetch(`${BASE_URL}/api/auth/signin`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(userInfo),
    })
    .then((res) => {
      if (!res.ok) {
        if (res.status === 401) {
          throw new Error('The ID or password you entered is incorrect. Please double-check and try again');
        } else {
          throw new Error(`HTTP error : ${res.status} ${res.statusText}`);
        }
      }
      return res.json();
    })
    .then(body => {
      if (body.result === 'SUCCESS') {
        handleLogin(body.data);
      } else {
        throw new Error(`Error occurred during Login. Please contact your system administrator`)
      }
    })
    .catch(error => {
      setLoginErrorMessage(error.message.replace('Error: ', ''));
    }).finally(() => {});
  };

  const handleSaveId = (e) => {
    setSaveId(e.target.checked);

    const updateCurrentUser = {
      ...currentUser,
      isSaveId: e.target.checked,
      name: name,
    };

    localStorage.setItem('currentUser', JSON.stringify(updateCurrentUser));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      signinRequest();
    }
  };

  return (
    isLoginDisplay ? (
      <StyledContainer className={styles['login-container']}>
        <LoginWrapBox>
          <Box component='div' className={styles['login-top-area']}>
            <Title variant='h1' align='left' className={styles['title']}>
              Log-In
            </Title>
            <Typography variant='body2' className={styles['sub-text']}>
              Channel Management Platform
            </Typography>
          </Box>

          <Box component='form' noValidate autoComplete='off'>
            <TextField
              type='outlined'
              size='large'
              fullWidth
              placeholder='ID'
              defaultValue= {isSaveId ? name : ''}
              label=''
              name='email'
              autoComplete='off'
              autoFocus
              onChange={handleIdChange}
              onKeyDown={handleKeyDown}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Box component='img' src={LoginIdIcon} alt='Id' />
                  </InputAdornment>
                ),
              }}
              className={styles['login-input']}
            />
            <TextField
              type='password'
              margin='normal'
              size='large'
              required={false}
              fullWidth
              placeholder='Password'
              name='password'
              label=''
              id='password'
              autoComplete='current-password'
              onChange={handlePasswordChange}
              onKeyDown={handleKeyDown}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Box component='img' src={LoginPwIcon} alt='Password' />
                  </InputAdornment>
                ),
              }}
              className={styles['login-input']}
            />
            <CheckBoxWrap>
              <CheckBox label='Save ID' value='value' defaultChecked={isSaveId} onChange={handleSaveId}/>
            </CheckBoxWrap>
            <WarningText>
              {loginErrorMessage}
            </WarningText>
            <ButtonWrap>
              <Button
                color='primary'
                type='box'
                size='large'
                label='로그인'
                isIcon='false'
                fullsize={true}
                onClick={signinRequest}
              />
            </ButtonWrap>

            <ContactNotice variant='body2' color='textSecondary' align='center' className='notice-text'>
              If you forgot your password, please contact us via
              <TextLink href=''>Harmony</TextLink>
            </ContactNotice>
            <CopyRightText variant='body2' align='center' className='copyright'>
              Copyright(c) LG Electronics. All Rights Reserved
            </CopyRightText>
          </Box>
        </LoginWrapBox>
      </StyledContainer>
    ) : (
      <></>
    )
  );
}

export default Login;
