import React, { useEffect, useState } from 'react';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  DialogActions,
  Box,
  Chip,
  FormControlLabel,
  Typography,
  IconButton,
  Divider,
} from '@mui/material';

import SelectCheckBox from 'components/component/BasicSelectCheckBox';
import CheckBox from 'components/component/BasicCheckBox';
import Snackbar from 'components/component/BasicSnackbar';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import { styled } from '@mui/system';
// Icon
import { CloseIcon24 } from 'components/component/BasicIcon';
import CloseIcon32 from 'assets/images/icon/ic_close_32_outline.svg';
// css
import 'assets/scss/page/main.scss';

import { menuItemData } from './data/GnbItemData';

const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '1000px',
    maxWidth: 'none',
    padding: '16px 32px 32px',
    borderRadius: '20px',
  },
}));
const HeaderArea = styled('div')`
  padding: 10px 0;
  margin-bottom: 12px;
`;

const DialogTitle = styled(MuiDialogTitle)`
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 36px;
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 0;
  flex-shrink: 0;
`;
const BoxContainer = styled(Box)`
  display: flex;
  gap: 20px;
`;
const BoxLayout = styled(Box)`
  flex: 1;
`;

const StyledChip = styled(Chip)(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  borderRadius: '8px',
  border: '1px solid #ddd',
  background: '#f7f7f7',
  padding: '10px 12px',
  height: 'auto',
  gap: '10px',
  '& .MuiChip-label': {
    width: 'calc(100% - 32px)', // label의 너비를 설정하여 삭제 아이콘과 겹치지 않게 합니다.
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    fontWeight: 400,
    color: '#2196F3',
    whiteSpace: 'wrap',
  },
  '& .MuiChip-deleteIcon': {
    marginRight: 0,
  },
}));
const CloseIcon = styled('span')({
  display: 'block',
  width: '32px',
  height: '32px',
  backgroundImage: `url(${CloseIcon32})`,
  backgroundRepeat: 'no-repeat',
});

function PopFavoriteMenu(props) {
  const { isOpen, darkMode, callbackClose, callbackSelectedFavoriteMenu } = props;

  const [optionData, setOptionData] = useState([]);
  const [selectedMenuItems, setSelectedMenuItems] = useState([]);        // Options menu 에서 선택한 items
  const [displayMenuItems, setDisplayMenuItems] = useState([]);          // Favorite Menu Settings 화면 오른쪽에 있는 menu items
  const [favoriteMenuData, setFavoriteMenuData] = useState([]);          // Favorite Menu Settings 화면 왼쪽에 있는 favorite menu items
  const [favoriteMenuId, setFavoriteMenuId] = useState(1000);

  const [isSnackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const tempData = [
      {
        id: 'all',
        value: 'all',
        option: 'All',
        type: 'checkbox',
        defaultChecked: true,
      },
      ...menuItemData.filter(item => item.id !== 1000).map((item, index) => ({
        id: index,
        value: item.primaryText,
        option: item.primaryText,
        type: 'checkbox',
        defaultChecked: false,
      })),
    ];

    setOptionData(tempData);
    setSelectedMenuItems(menuItemData.filter(item => item.id !== 1000).map(item => item.primaryText));
    setFavoriteMenuId(menuItemData.find(item => item.id === 1000).id);
    setFavoriteMenuData(menuItemData.filter(item => item.id === 1000).map(item => item.subItems).flat());
    setSnackbarOpen(false);
  }, [isOpen]);

  useEffect(() => {
    setDisplayMenuItems(menuItemData.filter(item => selectedMenuItems.includes(item.primaryText)));
  }, [selectedMenuItems]);

  const handleSelectChange = (selectedItems) => {
    setSelectedMenuItems(menuItemData.filter(item => selectedItems.includes(item.primaryText)).map(item => item.primaryText));
  };

  const handleClose = () => {
    callbackClose(false);
  };

  const handleCheckItem = (e, gnbItem, lnbItem) => {
    if (favoriteMenuData.length >= 10) {
      setSnackbarOpen(true);
    } else {
      const findGnbItem = menuItemData.find(item => item.primaryText === gnbItem);
      const favoriteCandidateItem = findGnbItem && findGnbItem.subItems.find(item => item.text === lnbItem);
      const newItem = { ...favoriteCandidateItem, id: favoriteCandidateItem.id + favoriteMenuId };
      setFavoriteMenuData([...favoriteMenuData, newItem]);
    }
  };

  const handleDeleteFavoriteItem = (deleteItem) => {
    const newFavoriteItems = favoriteMenuData.filter(item => item.id !== deleteItem.id);
    setFavoriteMenuData([...newFavoriteItems]);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCancelButton = () => {
    handleClose();
  };

  const handleSaveButton = () => {
    callbackSelectedFavoriteMenu(favoriteMenuData);

    handleClose();
  };

  const buttons = [
    {
      minWidth: '150px',
      color: 'tertiary',
      type: 'outline',
      size: 'large',
      label: 'Cancel',
      isIcon: 'false',
      IconType: '',
      iconPos: undefined,
      className: 'btn a1',
      onClick: handleCancelButton
    },
    {
      minWidth: '150px',
      color: 'primary',
      type: 'box',
      size: 'large',
      label: 'Save',
      isIcon: 'false',
      IconType: '',
      iconPos: undefined,
      className: 'btn',
      onClick: handleSaveButton
    },
  ];

  return (
    <div className='component-view'>
      <Dialog open={isOpen} size='medium' fullWidth className='favorite-pop-container'>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 18,
          }}
        >
          <CloseIcon />
        </IconButton>
        <HeaderArea className='header-wrap'>
          <DialogTitle>Favorite Menu Settings</DialogTitle>
        </HeaderArea>
        <DialogContent className='pop-content'>
          <BoxContainer>
            {/* Favorite Menu */}
            <BoxLayout className='left'>
              <div className='box-header'>
                <Typography className='area-title' variant='h2'>
                  Favorite Menu
                </Typography>
                <div className='count'>
                  <span className='number'>{favoriteMenuData.length}</span>
                </div>
              </div>
              <Box className='menu-box-wrap'>
                <div className='box-scroll-wrap'>
                  <div className='inner-scroll-area'>
                    {favoriteMenuData.map((item, index) => (
                      <StyledChip
                        key={index}
                        label={item.text}
                        onDelete={() => handleDeleteFavoriteItem(item)}
                        clickable
                        color='default'
                        deleteIcon={<CloseIcon24 />}
                      />
                    ))}
                  </div>
                  <div className='message-area' style={{ display: 'none' }}>
                    <Typography className='message-text' variant='body2'>
                      *You can have up to 5 favorite menus.
                    </Typography>
                  </div>
                </div>
              </Box>
            </BoxLayout>

            {/* All Menu */}
            <BoxLayout className='right'>
              <div className='box-header'>
                <Typography className='area-title' variant='h2'>
                  All Menu
                </Typography>
                <div className='select-container'>
                  <SelectCheckBox
                    id='select-03'
                    size='medium'
                    fullWidth
                    label='All'
                    selectOptions={optionData}
                    disabled={false}
                    multiple={true}
                    isFirstSelected={true}
                    placeholder=''
                    onChange={handleSelectChange}
                  />
                </div>
              </div>
              <div className='menu-box-wrap'>
                <div className='box-area'>
                  <Box display='flex' flexDirection='column' gap={2}>
                  {
                    displayMenuItems.map(item => (
                      <Box className='fav-box-menu'>
                        <Typography variant='h3' className='box-title'>
                          {item.primaryText}
                        </Typography>
                        <div className='form-control-wrap'>
                        {
                          item.subItems.map((lnbItem, index) => {
                          const isFavorite = favoriteMenuData.some(fav => fav.text === lnbItem.text);
                          if (!isFavorite) {
                            return (
                              <FormControlLabel
                                control={<CheckBox label={lnbItem.text} value='' checked={false} />}
                                className='menu-item-checkbox'
                                key={index}
                                onChange={(e) => handleCheckItem(e, item.primaryText, lnbItem.text)}
                              />
                            );
                          }
                          return null;
                        })}
                        </div>
                      </Box>
                    ))
                  }
                  </Box>
                </div>
              </div>
            </BoxLayout>
          </BoxContainer>
        </DialogContent>

        <Divider sx={{ margin: '12px 0 24px' }} />
        <DialogActions className='align-center' sx={{ padding: 0 }}>
          <CustomButtonGroup buttons={buttons} justifyContent='center'/>
        </DialogActions>
      </Dialog>

      {/* severity: default, error, warning, info, success */}
      <Snackbar
        severity='warning'
        message='*You can have up to 10 favorite menus.'
        open={isSnackbarOpen}
        Duration='1000'
        bottom={'8%'}
        darkMode={darkMode}
        onClose={handleSnackbarClose}
      />
    </div>
  );
}

export default PopFavoriteMenu;