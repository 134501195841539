export const ABtestTitleOption = [
  { id: 'ab-1', value: 'ABtest Title', option: 'ABtest Title', type: 'text' },
  { id: 'ab-2', value: 'ABtest Title', option: 'ABtest Title', type: 'text' },
];

export const AnServiceDeviceTypeOption = [
  { id: 'US', value: 'US', option: 'US', type: 'text' },
  { id: 'Asia', value: 'Asia', option: 'Asia', type: 'text' },
  { id: 'country3', value: 'GR', option: 'GR', type: 'text' },
];
export const AnServiceCountryOption = [
  { id: 'All', value: 'All', option: 'All', type: 'text' },
  { id: 'op1', value: 'op1', option: 'op1', type: 'text' },
  { id: 'op2', value: 'op2', option: 'op2', type: 'text' },
];

export const PlatformOption = [
  { id: 'All', value: 'All', option: 'All', type: 'text' },
  { id: 'op1', value: 'op1', option: 'op1', type: 'text' },
  { id: 'op2', value: 'op2', option: 'op2', type: 'text' },
];

export const PlatformVersionOption = [
  { id: 'All', value: 'All', option: 'All', type: 'text' },
  { id: 'op1', value: 'op1', option: 'op1', type: 'text' },
  { id: 'op2', value: 'op2', option: 'op2', type: 'text' },
];
export const TypeOption = [
  { id: 'All', value: 'All', option: 'All', type: 'text' },
  { id: 'op1', value: 'op1', option: 'op1', type: 'text' },
  { id: 'op2', value: 'op2', option: 'op2', type: 'text' },
];

export const TabOption = [
  { id: 'Live', value: 'Live', option: 'Live', type: 'text' },
  { id: 'Status1', value: 'Status1', option: 'Status1', type: 'text' },
  { id: 'Status2', value: 'Status2', option: 'Status2', type: 'text' },
];

export const CategoryOption = [
  { id: 'all', value: 'all', option: 'All', type: 'text' },
  { id: 'Category1', value: 'Category1', option: 'Category', type: 'text' },
  { id: 'Category2', value: 'Category2', option: 'Category2', type: 'text' },
];

export const StatusOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];
export const StartDateOption = [
  { id: 'a', value: 'a', option: '2024.07.08', type: 'text' },
  { id: 'b', value: 'b', option: '2024.07.09', type: 'text' },
  { id: 'c', value: 'c', option: '2024..07.10', type: 'text' },
];
export const StartTimeOption = [
  { id: 'time-a', value: 'a', option: '06:00', type: 'text' },
  { id: 'time-b', value: 'b', option: '06:10', type: 'text' },
  { id: 'time-c', value: 'c', option: '06:20', type: 'text' },
];
export const EndTimeOption = [
  { id: 'time-a', value: 'a', option: '16:00', type: 'text' },
  { id: 'time-b', value: 'b', option: '17:00', type: 'text' },
  { id: 'time-c', value: 'c', option: '18:00', type: 'text' },
];

export const selectOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const DtypeOption = [
  { id: 'tv', value: 'tv', option: 'tv', type: 'text' },
  { id: 'mobile', value: 'mobile', option: 'mobile', type: 'text' },
];

export const AnServiceTh = [
  {
    id: 'AnService-1',
    align: 'left',
    label: 'Title',
    width: '12%',
    checkbox: true,
    sortable: true,
  },

  {
    id: 'AnService-2',
    align: 'left',
    label: 'Device Type',
    width: '8%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'AnService-3',
    align: 'left',
    label: 'Tab Name',
    width: '11%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'AnService-4',
    align: 'left',
    label: 'Category Name',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'AnService-5',
    align: 'left',
    label: 'Start Date',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'AnService-6',
    align: 'left',
    label: 'Initial End Date',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'AnService-7',
    align: 'left',
    label: 'Actual End Date',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'AnService-8',
    align: 'left',
    label: 'Platform Version',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'AnService-9',
    align: 'left',
    label: 'Status',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'sh-10',
    align: 'left',
    label: 'Action',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];
export const AnServiceTd = [
  {
    id: 'AnServiceTd-row-1',
    data01: 'AN TEST_1',
    data02: 'TV',
    data03: 'T-WATCHNOW Home',
    data04: 'Featured',
    data05: '2024-09-30 01:00',
    data06: '2024-09-30 01:00',
    data07: '2024-09-30 01:00',
    data08: '3.4.0',
    data09: 'ready',
    data10: [
      { btnText: 'End', disabled: false },
      { btnText: 'Segment', disabled: false },
    ],
  },
  {
    id: 'AnServiceTd-row-2',
    data01: 'AN TEST_2',
    data02: 'TV',
    data03: 'T-WATCHNOW Home',
    data04: 'Featured',
    data05: '2024-09-30 01:00',
    data06: '2024-09-30 01:00',
    data07: '2024-09-30 01:00',
    data08: '3.4.0',
    data09: 'done',
    data10: [
      { btnText: 'End', disabled: true },
      { btnText: 'Segment', disabled: false },
    ],
  },
  {
    id: 'AnServiceTd-row-3',
    data01: 'AN TEST_3',
    data02: 'TV',
    data03: 'T-WATCHNOW Home',
    data04: 'Featured',
    data05: '2024-09-30 01:00',
    data06: '2024-09-30 01:00',
    data07: '2024-09-30 01:00',
    data08: '3.4.0',
    data09: 'ready',
    data10: [
      { btnText: 'End', disabled: false },
      { btnText: 'Segment', disabled: false },
    ],
  },
  {
    id: 'AnServiceTd-row-4',
    data01: 'AN TEST_4',
    data02: 'TV',
    data03: 'T-WATCHNOW Home',
    data04: 'Featured',
    data05: '2024-09-30 01:00',
    data06: '2024-09-30 01:00',
    data07: '2024-09-30 01:00',
    data08: '3.4.0',
    data09: 'ready',
    data10: [
      { btnText: 'End', disabled: false },
      { btnText: 'Segment', disabled: false },
    ],
  },
];

export const SegmentTh = [
  {
    id: 'SegmentTh-1',
    align: 'left',
    label: 'Sub ID',
    width: '8%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'SegmentTh-2',
    align: 'left',
    label: 'Validity',
    width: '10%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'SegmentTh-3',
    align: 'left',
    label: 'Segment Target',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'SegmentTh-4',
    align: 'left',
    label: 'Segment Target rate',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'SegmentTh-5',
    align: 'left',
    label: 'B Group Recommendation Content',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'SegmentTh-6',
    align: 'left',
    label: 'C Group Recommendation Content',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'SegmentTh-7',
    align: 'left',
    label: 'Action',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];
export const SegmentTd = [
  {
    id: 'AnServiceTd-row-1',
    data01: '0',
    data02: 'default',
    data03: 'News1',
    data04: '20%',
    data05: 'News_Channel',
    data06: '2024-08-07 15:00',
    data07: [
      { btnText: 'ORGANIZE', disabled: false },
      { btnText: 'DELETE', disabled: false },
      { btnText: 'RESULT', disabled: false },
    ],
  },
  {
    id: 'AnServiceTd-row-2',
    data01: '2',
    data02: 'y',
    data03: 'News1',
    data04: '20%',
    data05: 'News_Channel',
    data06: 'Personalize_VOD_v1',
    data07: [
      { btnText: 'ORGANIZE', disabled: false },
      { btnText: 'DELETE', disabled: false },
      { btnText: 'RESULT', disabled: true },
    ],
  },
  {
    id: 'AnServiceTd-row-3',
    data01: '3',
    data02: 's',
    data03: 'News1',
    data04: '20%',
    data05: 'News_Channel',
    data06: 'Personalize_VOD_v1',
    data07: [
      { btnText: 'ORGANIZE', disabled: false },
      { btnText: 'DELETE', disabled: true },
      { btnText: 'RESULT', disabled: false },
    ],
  },
];

export const AnServiceResultTh = [
  {
    id: 'AnServiceResultTh-1',
    align: 'left',
    label: 'Group',
    width: '10%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'AnService-2',
    align: 'left',
    label: 'Group Code',
    width: '20%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'AnService-3',
    align: 'right',
    label: 'Number of UDs up to 2024-10-06',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'AnService-4',
    align: 'left',
    label: 'Description',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];
export const AnServiceResultTd = [
  {
    id: 'AnServiceResultTd-1',
    data01: 'A',
    data02: 'P_NO_SEGMENT_DEFAULT',
    data03: '2,278,546',
    data04: 'this Group(belongs to no segments) watches a list of contents organizes by the Tab/Categoty page.',
  },
  {
    id: 'AnServiceResultTd-2',
    data01: 'B',
    data02: 'T_AD_LGCH_MOVIE-0193_RECOMMEND_B',
    data03: '1,447',
    data04:
      'this Group(belongs to AD_LGCH_MOVIES-0913) watches a list of contents organizes via the A/N Test or service configuration pages.',
  },
  {
    id: 'AnServiceResultTd-3',
    data01: 'B',
    data02: 'T_AD_LGCH_MOVIE-0193_DEFAULT',
    data03: '1,447',
    data04: 'this Group(belongs to AD_LGCH_MOVIES-0913) watches a list of contents organizes by the Tab/Categoty page.',
  },
];
