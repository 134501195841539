import imgDummy01 from 'assets/images/img_dummy_04.png';

export const selOptionCountryData = [
  { id: 'country1', value: 'US', option: 'US', type: 'text' },
  { id: 'country2', value: 'FR', option: 'FR', type: 'text' },
  { id: 'country3', value: 'GR', option: 'GR', type: 'text' },
];

export const ChannelMapListOption = [
  { id: 'list1', value: 'US-ME-6.0.0', option: 'US-ME-6.0.0', type: 'text' },
  { id: 'list2', value: 'US-ME-6.0.1', option: 'US-ME-6.0.1', type: 'text' },
  { id: 'list3', value: 'US-ME-6.0.2', option: 'US-ME-6.0.2', type: 'text' },
];

export const ChannelMapStatusOption = [
  { id: 'Status1', value: 'Testing', option: 'Testing', type: 'text' },
  { id: 'Status2', value: 'Testing2', option: 'Testing2', type: 'text' },
];

export const ChannelMapReservationOption = [
  { id: 'Reservation1', value: '2024-09-02 02:00:00', option: '2024-09-02 02:00:00', type: 'text' },
  { id: 'Reservation2', value: '2024-09-02 03:00:00', option: '2024-09-02 02:00:00', type: 'text' },
  { id: 'Reservation3', value: '2024-09-02 04:00:00', option: '2024-09-02 03:00:00', type: 'text' },
];

export const selectOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const FeedTableTh = [
  {
    id: 'Logo',
    align: 'left',
    label: 'Logo',
    width: '24%',
    checkbox: true,
    sortable: true,
  },

  {
    id: 'Ch.Name',
    align: 'left',
    label: 'Ch.Name',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Ch.ID',
    align: 'left',
    label: 'Ch.ID',
    width: '35%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Genre',
    align: 'left',
    label: 'Genre',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const FeedTableTd = [
  {
    id: 'feedTd-1',
    data01: '',
    data02: '157',
    data03: [{ tag: true }, { text: 'Local Now' }],
    data04: 'newid_004',
  },
  {
    id: 'feedTd-2',
    data01: imgDummy01,
    data02: '278',
    data03: [{ tag: false }, { text: 'Mubeat' }],
    data04: 'newid_004',
  },
  {
    id: 'feedTd-3',
    data01: '',
    data02: '157',
    data03: [{ tag: true }, { text: 'Local Now' }],
    data04: 'newid_004',
  },
  {
    id: 'feedTd-4',
    data01: '',
    data02: '278',
    data03: [{ tag: false }, { text: 'Mubeat' }],
    data04: 'newid_004',
  },
  {
    id: 'feedTd-5',
    data01: '',
    data02: '157',
    data03: [{ tag: true }, { text: 'Local Now' }],
    data04: 'newid_004',
  },
  {
    id: 'feedTd-6',
    data01: '',
    data02: '278',
    data03: [{ tag: false }, { text: 'Mubeat' }],
    data04: 'newid_004',
  },
];

export const ChannelMapSelectedTh = [
  {
    id: 'chMapping-logo',
    align: 'left',
    label: 'Logo',
    width: '16%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'chMapping-chno',
    align: 'left',
    label: 'Ch.No',
    width: '13%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'chMapping-chname',
    align: 'left',
    label: 'Ch.Name',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'chMapping-chid',
    align: 'left',
    label: 'Ch.ID',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'chMapping-Name',
    align: 'left',
    label: 'CP Name',
    width: '11%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'chMappingGenre',
    align: 'left',
    label: 'Genre',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'chMapping-Modifier',
    align: 'left',
    label: 'Modifier',
    width: '11%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'chMapping-DMA Group Name',
    align: 'left',
    label: 'DMA Group Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
];

export const ChannelMapSelectedTd = [
  {
    id: 'chMapping-row-1',
    unsaved: true, //행이 저장되지 않은 상태 : true
    data01: '',
    data02: 'XM0C6LNWFGWKNK',
    data03: 'The Wedding Video',
    data04: 'us-lg_ncaachannel_1-TEST-',
    data05: 'Wurl',
    data06: 'Sport',
    data07: 'taril.lim',
    data08: 'primary', //그룹 이름 설정이 필요한 상태
  },
  {
    id: 'chMapping-row-2',
    unsaved: true, //행이 저장되지 않은 상태 : true
    data01: '',
    data02: 'XM0C6LNWFGWKNK',
    data03: 'The Wedding Video',
    data04: 'us-lg_ncaachannel_1-TEST-',
    data05: 'Wurl',
    data06: 'Sport',
    data07: 'taril.lim',
    data08: 'tertiary', // 그룹 이름 설정 완료 된 상태
  },
  {
    id: 'chMapping-row-3',
    unsaved: false,
    data01: '',
    data02: 'XM0C6LNWFGWKNK',
    data03: 'The Wedding Video',
    data04: 'us-lg_ncaachannel_1-TEST-',
    data05: 'Wurl',
    data06: 'Sport',
    data07: 'taril.lim',
    data08: 'tertiary', // 그룹 이름 설정 완료 된 상태
  },
  {
    id: 'chMapping-row-4',
    unsaved: false,
    data01: '',
    data02: 'XM0C6LNWFGWKNK',
    data03: 'The Wedding Video',
    data04: 'us-lg_ncaachannel_1-TEST-',
    data05: 'Wurl',
    data06: 'Sport',
    data07: 'taril.lim',
    data08: 'tertiary', // 그룹 이름 설정 완료 된 상태
  },
  {
    id: 'chMapping-row-5',
    unsaved: false,
    data01: '',
    data02: 'XM0C6LNWFGWKNK',
    data03: 'The Wedding Video',
    data04: 'us-lg_ncaachannel_1-TEST-',
    data05: 'Wurl',
    data06: 'Sport',
    data07: 'taril.lim',
    data08: 'tertiary', // 그룹 이름 설정 완료 된 상태
  },
  {
    id: 'chMapping-row-6',
    unsaved: false,
    data01: '',
    data02: 'XM0C6LNWFGWKNK',
    data03: 'The Wedding Video',
    data04: 'us-lg_ncaachannel_1-TEST-',
    data05: 'Wurl',
    data06: 'Sport',
    data07: 'taril.lim',
    data08: 'tertiary', // 그룹 이름 설정 완료 된 상태
  },
  {
    id: 'chMapping-row-7',
    unsaved: false,
    data01: '',
    data02: 'XM0C6LNWFGWKNK',
    data03: 'The Wedding Video',
    data04: 'us-lg_ncaachannel_1-TEST-',
    data05: 'Wurl',
    data06: 'Sport',
    data07: 'taril.lim',
    data08: 'tertiary', // 그룹 이름 설정 완료 된 상태
  },
  {
    id: 'chMapping-row-8',
    unsaved: false,
    data01: '',
    data02: 'XM0C6LNWFGWKNK',
    data03: 'The Wedding Video',
    data04: 'us-lg_ncaachannel_1-TEST-',
    data05: 'Wurl',
    data06: 'Sport',
    data07: 'taril.lim',
    data08: 'tertiary', // 그룹 이름 설정 완료 된 상태
  },
];

export const DMAgroupNamePopTh = [
  {
    id: 'PopChNo',
    align: 'left',
    label: 'Ch.No',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'PopChName',
    align: 'left',
    label: 'Ch.Name',
    width: '25%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'PopChID',
    align: 'left',
    label: 'Ch.ID',
    width: '30%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'PopDMAGroupName',
    align: 'left',
    label: 'DMA Group Name',
    width: '30%',
    checkbox: false,
    sortable: true,
  },
];
export const DMAgroupNamePopTd = [
  {
    id: 'PopChRow1',
    data01: '157',
    data02: 'Local Now Chicago',
    data03: 'Test-Monthly Picks: NCAA Channel',
    data04: {
      label: 'DMA Group Name',
      selectOption: 'Local Now Chicago',
    },
  },
  {
    id: 'PopChRow2',
    data01: '157',
    data02: 'Local Now Chicago',
    data03: 'Test-Monthly Picks: NCAA Channel',
    data04: {
      label: 'DMA Group Name',
      selectOption: 'Local Now Chicago',
    },
  },
  {
    id: 'PopChRow3',
    data01: '157',
    data02: 'Local Now Chicago',
    data03: 'Test-Monthly Picks: NCAA Channel',
    data04: {
      label: 'DMA Group Name',
      selectOption: 'Local Now Chicago',
    },
  },
  {
    id: 'PopChRow4',
    data01: '157',
    data02: 'Local Now Chicago',
    data03: 'Test-Monthly Picks: NCAA Channel',
    data04: {
      label: 'DMA Group Name',
      selectOption: 'Local Now Chicago',
    },
  },
  {
    id: 'PopChRow5',
    data01: '157',
    data02: 'Local Now Chicago',
    data03: 'Test-Monthly Picks: NCAA Channel',
    data04: {
      label: 'DMA Group Name',
      selectOption: 'Local Now Chicago',
    },
  },
  {
    id: 'PopChRow6',
    data01: '157',
    data02: 'Local Now Chicago',
    data03: 'Test-Monthly Picks: NCAA Channel',
    data04: {
      label: 'DMA Group Name',
      selectOption: 'Local Now Chicago',
    },
  },
  {
    id: 'PopChRow7',
    data01: '157',
    data02: 'Local Now Chicago',
    data03: 'Test-Monthly Picks: NCAA Channel',
    data04: {
      label: 'DMA Group Name',
      selectOption: 'Local Now Chicago',
    },
  },
  {
    id: 'PopChRow8',
    data01: '157',
    data02: 'Local Now Chicago',
    data03: 'Test-Monthly Picks: NCAA Channel',
    data04: {
      label: 'DMA Group Name',
      selectOption: 'Local Now Chicago',
    },
  },
];

export const DMAgroupOutputTd = [
  {
    id: 'DMAgroupOutputTd-1',
    data01: '157',
    data02: 'Local Now Chicago',
    data03: 'Test-Monthly Picks: NCAA Channel',
    data04: 'Local Now Chicago',
  },
  {
    id: 'DMAgroupOutputTd-2',
    data01: '157',
    data02: 'Local Now Detroit',
    data03: 'Test-Monthly Picks: NCAA Channel',
    data04: 'Local Now San Detroit',
  },
  {
    id: 'DMAgroupOutputTd-3',
    data01: '157',
    data02: 'Local Now Chicago',
    data03: 'Test-Monthly Picks: NCAA Channel',
    data04: 'Local Now Chicago',
  },
  {
    id: 'DMAgroupOutputTd-4',
    data01: '157',
    data02: 'Local Now DC',
    data03: 'Test-Monthly Picks: NCAA Channel',
    data04: 'Local Now DC',
  },
  {
    id: 'DMAgroupOutputTd-4',
    data01: '157',
    data02: 'Local Now LA',
    data03: 'Test-Monthly Picks: NCAA Channel',
    data04: 'Local Now LA',
  },
];

export const CheckDialogTh = [
  {
    id: 'ChNo',
    align: 'left',
    label: 'Ch.No',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ChName',
    align: 'left',
    label: 'Ch.Name',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Ch.ID',
    align: 'left',
    label: 'Ch.ID',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'DMA',
    align: 'left',
    label: 'DMA Group Name',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'LastChgDate',
    align: 'Event',
    label: 'LastChgDate',
    width: '25%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Event',
    align: 'left',
    label: 'Event',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const CheckDialogTd = [
  {
    id: 'CheckDialogTd-1',
    data01: '628',
    data02: 'Robocal POL',
    data03: 'newid_108',
    data04: 'Local Now Chicago',
    data05: '2024-07-01 01:51:57',
    data06: 'added',
  },
  {
    id: 'CheckDialogTd-2',
    data01: '627',
    data02: 'Robocal POL',
    data03: 'newid_108',
    data04: 'Local Now Chicago',
    data05: '2024-07-01 01:51:57',
    data06: 'removed',
  },
  {
    id: 'CheckDialogTd-3',
    data01: '626',
    data02: 'Robocal POL',
    data03: 'newid_108',
    data04: 'Local Now Chicago',
    data05: '2024-07-01 01:51:57',
    data06: 'update',
  },
];

export const BulkUploadTh = [
  {
    id: 'ChannelNo',
    align: 'left',
    label: 'Channel No',
    width: '8%',
    checkbox: true,
    sortable: true,
  },

  {
    id: 'ChannelID',
    align: 'left',
    label: 'Channel ID',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ChannelName',
    align: 'left',
    label: 'Channel Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'DMAGroupName',
    align: 'left',
    label: 'DMA Group Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'BulkUploadTh4',
    align: 'left',
    label: 'WebOS 3.4',
    width: '8%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'BulkUploadTh5',
    align: 'left',
    label: 'webOS 4.3',
    width: '8%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'BulkUploadTh6',
    align: 'left',
    label: 'WEE 5.0',
    width: '8%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'BulkUploadTh7',
    align: 'left',
    label: 'ME 6.0',
    width: '8%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'BulkUploadTh8',
    align: 'left',
    label: 'Auto 8.2',
    width: '8%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'Event',
    align: 'left',
    label: 'Event',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
];

export const BulkUploadTd = [
  {
    id: 'BulkUploadTd-1',
    data1: '100',
    data2: 'newid_rt036',
    data3: '뭉쳐야 찬다3',
    data4: 'DMA Group Name4',
    data5: 'O',
    data6: 'O',
    data7: 'O',
    data8: 'O',
    data9: 'X',
    data10: { type: 'fail', text: 'Fail(Not In Feed)' },
  },
  {
    id: 'BulkUploadTd-2',
    data1: '100',
    data2: 'newid_rt036',
    data3: '뭉쳐야 찬다3',
    data4: 'DMA Group Name4',
    data5: 'O',
    data6: 'O',
    data7: 'O',
    data8: 'O',
    data9: 'X',
    data10: { type: 'success', text: 'success' },
  },
  {
    id: 'BulkUploadTd-3',
    data1: '100',
    data2: 'newid_rt036',
    data3: '뭉쳐야 찬다3',
    data4: 'DMA Group Name4',
    data5: 'O',
    data6: 'O',
    data7: 'O',
    data8: 'O',
    data9: 'X',
    data10: { type: 'success', text: 'success' },
  },
  {
    id: 'BulkUploadTd-4',
    data1: '100',
    data2: 'newid_rt036',
    data3: '뭉쳐야 찬다3',
    data4: 'DMA Group Name4',
    data5: 'O',
    data6: 'O',
    data7: 'O',
    data8: 'O',
    data9: 'X',
    data10: { type: 'fail', text: 'Fail(Not In Feed)' },
  },
  {
    id: 'BulkUploadTd-5',
    data1: '100',
    data2: 'newid_rt036',
    data3: '뭉쳐야 찬다3',
    data4: 'DMA Group Name4',
    data5: 'O',
    data6: 'O',
    data7: 'O',
    data8: 'O',
    data9: 'X',
    data10: { type: 'fail', text: 'Fail(Not In Feed)' },
  },
  {
    id: 'BulkUploadTd-6',
    data1: '100',
    data2: 'newid_rt036',
    data3: '뭉쳐야 찬다3',
    data4: 'DMA Group Name4',
    data5: 'O',
    data6: 'O',
    data7: 'O',
    data8: 'O',
    data9: 'X',
    data10: { type: 'success', text: 'success' },
  },
];

export const chCountListData = [
  {
    id: 'count-01',
    label: '3.2',
    type: 'checkbox',
  },
  {
    id: 'count-02',
    label: '4.3',
    type: 'checkbox',
  },
  {
    id: 'count-03',
    label: 'WEE',
    type: 'checkbox',
  },
  {
    id: 'count-04',
    label: 'ME',
    type: 'checkbox',
  },
  {
    id: 'count-05',
    label: 'Auto',
    type: 'checkbox',
  },
  {
    id: 'count-05',
    label: 'Auto',
    type: 'checkbox',
  },
];
