import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import styled from '@emotion/styled';

// Icon
import {
  InfoIcon,
  DarkInfoIcon,
  SuccessIcon24,
  DarkSuccessIcon24,
  WarningIcon24,
  DarkWarningIcon24,
  DangerIcon24,
  DarkDangerIcon24,
  CloseIcon24Black,
  CloseIcon24White,
} from 'components/component/BasicIcon';

// Styled
const StyledSnackbar = styled(Snackbar)`
  &.MuiSnackbar-root {
    left: 50%;
    transform: translateX(-50%);

    .MuiPaper-root {
      min-width: 400px;
      max-width: 960px;
      box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.25);
      align-items: center;
      padding: 8px 12px;
      border-radius: 7px;
    }
    .MuiAlert-action {
      padding: 0;
    }
    .MuiButtonBase-root {
      padding: 0;
    }
    .MuiAlert-filledDefault {
      background-color: #525252;

      .dark & {
        background-color: #808080;
      }
    }
    .MuiAlert-standardInfo,
    .MuiAlert-filledInfo,
    .MuiAlert-outlinedInfo {
      background-color: #0077b5;
    }
    .MuiAlert-standardSuccess,
    .MuiAlert-filledSuccess,
    .MuiAlert-outlinedSuccess {
      background-color: #0d9488;
    }
    .MuiAlert-standardWarning,
    .MuiAlert-filledWarning,
    .MuiAlert-outlinedWarning {
      background-color: #f59e0b;
    }
    .MuiAlert-standardError,
    .MuiAlert-filledError,
    .MuiAlert-outlinedError {
      background-color: #b91c1c;
    }
  }
  .MuiAlert-icon {
    padding: 0;
    margin-right: 1rem;
  }
  .MuiAlert-message {
    width: 100%;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    text-transform: capitalize;
  }
  .MuiAlert-action {
    margin-right: 0;
  }
`;

// Snackbar Component
function BasicSnackbar({ open, onClose, message, severity, bottom, darkMode, duration }) {
  const getIcon = (severity) => {
    switch (severity) {
      case 'info':
        return darkMode ? <DarkInfoIcon /> : <InfoIcon />;
      case 'success':
        return darkMode ? <DarkSuccessIcon24 /> : <SuccessIcon24 />;
      case 'warning':
        return darkMode ? <DarkWarningIcon24 /> : <WarningIcon24 />;
      case 'error':
        return darkMode ? <DarkDangerIcon24 /> : <DangerIcon24 />;
      default:
        return null;
    }
  };

  return (
    <StyledSnackbar open={open} onClose={onClose} autoHideDuration={duration} sx={{ bottom: `${bottom} !important` }}>
      <Alert
        variant='filled'
        onClose={onClose}
        severity={severity}
        icon={getIcon(severity)}
        action={
          <IconButton aria-label='close' color='inherit' size='small' onClick={onClose}>
            {darkMode ? <CloseIcon24Black /> : <CloseIcon24White />}
          </IconButton>
        }
      >
        {message}
      </Alert>
    </StyledSnackbar>
  );
}

export default BasicSnackbar;
