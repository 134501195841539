// src/data.js

export const Region = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'Eu', value: 'Eu', option: 'Eu', type: 'text' },
  { id: 'Asia', value: 'Asia', option: 'Asia', type: 'text' },
];

export const DeviceSelectData = [
  { id: 'tv', value: 'Tv', option: 'Tv', type: 'text' },
  { id: 'tablet', value: 'Tablet', option: 'Tablet', type: 'text' },
];

export const ChannelMaptData = [
  { id: '4.9', value: 'US-webOS-4.9.0', option: 'US-webOS-4.9.0', type: 'text' },
  { id: '5.0', value: 'US-webOS-5.0.0', option: 'US-webOS-5.0.0', type: 'text' },
];
export const ChannelNameSelectData = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'option', value: 'option', option: 'option', type: 'text' },
];
export const CPNameData = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'option', value: 'option', option: 'option', type: 'text' },
];

export const TypeData = [
  { id: 'TvShow', value: 'TvShow', option: 'TvShow', type: 'text' },
  { id: 'TvShow-2', value: 'TvShow2', option: 'TvShow2', type: 'text' },
  { id: 'TvShow-3', value: 'TvShow2', option: 'TvShow3', type: 'text' },
];
export const GenreData = [
  { id: 'GenreData-1', value: 'All', option: 'All', type: 'text' },
  { id: 'GenreData-2', value: 'Genre-1', option: 'Genre-1', type: 'text' },
  { id: 'GenreData-3', value: 'Genre-2', option: 'Genre-2', type: 'text' },
];

export const CountryData = [
  { id: 'country-1', value: 'Us', option: 'Us', type: 'text' },
  { id: 'country-2', value: 'Italy', option: 'Italy', type: 'text' },
  { id: 'country-3', value: 'Ger', option: 'Ger', type: 'text' },
];
export const CPData = [
  { id: 'cp-1', value: 'US-ME-6.0.0', option: 'All', type: 'text' },
  { id: 'cp-2', value: 'US-ME-6.0.1', option: 'US-ME-6.0.1', type: 'text' },
  { id: 'cp-3', value: 'US-ME-6.0.2', option: 'US-ME-6.0.2', type: 'text' },
];
export const CPMapData = [
  { id: 'cp-map-1', value: 'cp-map-1', option: 'cp-map-1', type: 'text' },
  { id: 'cp-map-2', value: 'cp-map-2', option: 'cp-map-2', type: 'text' },
  { id: 'cp-map-3', value: 'cp-map-3', option: 'cp-map-3', type: 'text' },
];
export const statusData = [
  { id: 'status-1', value: 'status-1', option: 'status-1', type: 'text' },
  { id: 'status-2', value: 'status-2', option: 'status-2', type: 'text' },
  { id: 'status-3', value: 'status-3', option: 'status-3', type: 'text' },
];
export const selectOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const CategoryTypeData = [
  { id: 'ct-1', value: 'Monthly Picks: NCAA Ch', option: 'Monthly Picks: NCAA ChNCAA Ch', type: 'text' },
  { id: 'ct-2', value: 'item 01', option: 'item 01', type: 'text' },
  { id: 'ct-3', value: 'item 02', option: 'item 02', type: 'text' },
];
export const businessAreaData = [
  { id: 'ba-1', value: 'Channel Map', option: 'Channel Map', type: 'text' },
  { id: 'ba-2', value: 'Channel Map2', option: 'Channel Map2', type: 'text' },
  { id: 'ba-3', value: 'Channel Map3', option: 'Channel Map3', type: 'text' },
];

export const CountTh = [
  {
    id: 'data01',
    label: 'Ric',
    align: 'left',
    width: '10%',
    checkbox: true,
    sortable: false,
  },
  {
    id: 'data02',
    label: 'Country',
    align: 'left',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'data03',
    label: 'Channel Map ID',
    align: 'left',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'data04',
    label: 'Provider',
    align: 'left',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'data05',
    label: 'Test Channel Count',
    align: 'right',
    width: '17%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'data06',
    label: 'Publish Channel Count',
    align: 'right',
    width: 'auto',
    sortable: true,
  },
];

export const CountTd = [
  {
    id: 'td-1',
    data01: 'AIC',
    data02: 'AR',
    data03: 'AR-ME-6.0.0',
    data04: 'WRL',
    data05: '13',
    data06: '12',
  },
  {
    id: 'td-2',
    data01: 'AIC',
    data02: 'AR',
    data03: 'AR-ME-6.0.0',
    data04: 'WRL',
    data05: '44',
    data06: '11',
  },
  {
    id: 'td-3',
    data01: 'AIC',
    data02: 'AR',
    data03: 'AR-ME-6.0.0',
    data04: 'WRL',
    data05: '46',
    data06: '23',
  },
  {
    id: 'td-4',
    data01: 'AIC',
    data02: 'AR',
    data03: 'AR-ME-6.0.0',
    data04: 'WRL',
    data05: '86',
    data06: '34',
  },
  {
    id: 'td-5',
    data01: 'AIC',
    data02: 'AR',
    data03: 'AR-ME-6.0.0',
    data04: 'WRL',
    data05: '11',
    data06: '5',
  },
  {
    id: 'td-6',
    data01: 'AIC',
    data02: 'AR',
    data03: 'AR-ME-6.0.0',
    data04: 'WRL',
    data05: '11',
    data06: '4',
  },
  {
    id: 'td-7',
    data01: 'AIC',
    data02: 'AR',
    data03: 'AR-ME-6.0.0',
    data04: 'WRL',
    data05: '15',
    data06: '2',
  },
  {
    id: 'td-8',
    data01: 'AIC',
    data02: 'AR',
    data03: 'AR-ME-6.0.0',
    data04: 'WRL',
    data05: '74',
    data06: '1',
  },
  {
    id: 'td-9',
    data01: 'AIC',
    data02: 'AR',
    data03: 'AR-ME-6.0.0',
    data04: 'WRL',
    data05: '63',
    data06: '0',
  },
  {
    id: 'td-10',
    data01: 'AIC',
    data02: 'AR',
    data03: 'AR-ME-6.0.0',
    data04: 'WRL',
    data05: '10',
    data06: '0',
  },
];

export const SchedulesTh = [
  {
    id: 'time',
    align: 'left',
    label: 'Time',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'proID',
    align: 'left',
    label: 'Program ID',
    width: '40%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Title',
    align: 'left',
    label: 'Title',
    width: '40%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Action',
    align: 'left',
    label: 'Action',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
];

export const SchedulesTd = [
  {
    id: 'checkbox',
    data01: '20:11',
    data02: 'US-LGELECTRONICSINCCC-53034186-6645167-6633435',
    data03: '2023 FCS Football Championship: South Dakota State vs. Montana',
  },
  {
    id: 'row-1',
    data01: '20:10',
    data02: 'US-LGELECTRONICSINCCC-53034186-6645167-6633430',
    data03: '2023 FCS Football Championship: South Dakota State vs. Montana',
  },
  {
    id: 'row-2',
    data01: '20:07',
    data02: 'US-LGELECTRONICSINCCC-53034186-6645167-6633434',
    data03: '2023 FCS Football Championship: South Dakota State vs. Montana',
  },
  {
    id: 'row-3',
    data01: '20:10',
    data02: 'US-LGELECTRONICSINCCC-53034186-6645167-6633430',
    data03: '2023 FCS Football Championship: South Dakota State vs. Montana',
  },
  {
    id: 'row-4',
    data01: '20:07',
    data02: 'US-LGELECTRONICSINCCC-53034186-6645167-6633434',
    data03: '2023 FCS Football Championship: South Dakota State vs. Montana',
  },
  {
    id: 'row-5',
    data01: '20:10',
    data02: 'US-LGELECTRONICSINCCC-53034186-6645167-6633430',
    data03: '2023 FCS Football Championship: South Dakota State vs. Montana',
  },
  {
    id: 'row-6',
    data01: '20:07',
    data02: 'US-LGELECTRONICSINCCC-53034186-6645167-6633434',
    data03: '2023 FCS Football Championship: South Dakota State vs. Montana',
  },
  {
    id: 'row-7',
    data01: '20:10',
    data02: 'US-LGELECTRONICSINCCC-53034186-6645167-6633430',
    data03: '2023 FCS Football Championship: South Dakota State vs. Montana',
  },
  {
    id: 'row-8',
    data01: '20:07',
    data02: 'US-LGELECTRONICSINCCC-53034186-6645167-6633434',
    data03: '2023 FCS Football Championship: South Dakota State vs. Montana',
  },
];

export const ChannelMapTh = [
  {
    id: 'country',
    label: 'Ch.No',
    align: 'left',
    width: '5%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'cpName',
    label: 'CP Name',
    align: 'left',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'chname',
    label: 'Channel Name',
    align: 'left',
    width: '17%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Genre',
    label: 'Channel Genre',
    align: 'left',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ch-id',
    label: 'Channel ID',
    align: 'left',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'id-3',
    label: '3.4 ',
    align: 'left',
    width: '6%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'id-4',
    label: '4.3',
    align: 'left',
    width: '6%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'WEE ',
    label: 'WEE',
    align: 'left',
    width: '6%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Auto',
    label: 'Auto',
    align: 'left',
    width: '6%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'dma',
    label: 'DMA',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'date',
    label: 'Last Update',
    align: 'left',
    width: '10 %',
    checkbox: false,
    sortable: true,
  },
];
export const ChannelMapTd = [
  {
    data1: '100',
    data2: 'Wurl',
    data3: 'Monthly Picks: NCAA Channel 100',
    data4: 'Sport',
    data5: 'us-lg_ncaachannel_1',
    data6: 'O',
    data7: 'O',
    data8: 'O',
    data9: 'O',
    data10: 'Local Now Yakima',
    data11: '2024.08.07 14:00:00',
  },
  {
    data1: '101',
    data2: 'Wurl',
    data3: 'Monthly Picks: NCAA Channel 100',
    data4: 'Sport',
    data5: 'us-lg_ncaachannel_1',
    data6: 'O',
    data7: 'O',
    data8: 'O',
    data9: 'O',
    data10: 'Local Now Yakima',
    data11: '2024.08.07 14:00:00',
  },
  {
    data1: '111',
    data2: 'Wurl',
    data3: 'Monthly Picks: NCAA Channel 100',
    data4: 'Sport',
    data5: 'us-lg_ncaachannel_1',
    data6: 'O',
    data7: 'O',
    data8: 'O',
    data9: 'O',
    data10: 'Local Now Yakima',
    data11: '2024.08.07 14:00:00',
  },
  {
    data1: '123',
    data2: 'Wurl',
    data3: 'Monthly Picks: NCAA Channel 100',
    data4: 'Sport',
    data5: 'us-lg_ncaachannel_1',
    data6: 'O',
    data7: 'O',
    data8: 'O',
    data9: 'O',
    data10: 'Local Now Yakima',
    data11: '2024.08.07 14:00:00',
  },
  {
    data1: '100',
    data2: 'Wurl',
    data3: 'Monthly Picks: NCAA Channel 100',
    data4: 'Sport',
    data5: 'us-lg_ncaachannel_1',
    data6: 'O',
    data7: 'O',
    data8: 'O',
    data9: 'O',
    data10: 'Local Now Yakima',
    data11: '2024.08.07 14:00:00',
  },
  {
    data1: '100',
    data2: 'Wurl',
    data3: 'Monthly Picks: NCAA Channel 100',
    data4: 'Sport',
    data5: 'us-lg_ncaachannel_1',
    data6: 'O',
    data7: 'O',
    data8: 'O',
    data9: 'O',
    data10: 'Local Now Yakima',
    data11: '2024.08.07 14:00:00',
  },
  {
    data1: '100',
    data2: 'Wurl',
    data3: 'Monthly Picks: NCAA Channel 100',
    data4: 'Sport',
    data5: 'us-lg_ncaachannel_1',
    data6: 'O',
    data7: 'O',
    data8: 'O',
    data9: 'O',
    data10: 'Local Now Yakima',
    data11: '2024.08.07 14:00:00',
  },
];

export const MetaChannelTh = [
  {
    id: 'Logo',
    label: 'Logo',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'ch-15241',
    label: 'Ch.ID',
    align: 'left',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ch-140',
    label: 'Ch.No',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Ch-75981',
    label: 'Ch.Name',
    align: 'left',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ch-24325',
    label: 'CP Name',
    align: 'left',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ch-984',
    label: 'LastUpdated',
    align: 'left',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ch-74156',
    label: 'Action',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const MetaChannelTd = [
  {
    id: 'MetaChannelTd-01',
    data01: '',
    data02: 'us-lg_ncaachannel_1',
    data03: '100',
    data04: 'Monthly Picks: NCAA Channel',
    data05: 'NCAA',
    data06: '2024-06-26 23:31:28',
    data07: '',
  },
  {
    id: 'MetaChannelTd-02',
    data01: '',
    data02: 'us-lg_ncaachannel_1',
    data03: '100',
    data04: 'Monthly Picks: NCAA Channel',
    data05: 'NCAA',
    data06: '2024-06-26 23:31:28',
    data07: '',
  },
  {
    id: 'MetaChannelTd-03',
    data01: '',
    data02: 'us-lg_ncaachannel_1',
    data03: '100',
    data04: 'Monthly Picks: NCAA Channel',
    data05: 'NCAA',
    data06: '2024-06-26 23:31:28',
    data07: '',
  },
];

export const MetaProgramTh = [
  {
    id: 'name-9514',
    label: 'CP Name',
    align: 'left',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'chname-874',
    label: 'Ch.Name',
    align: 'left',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ch-0714',
    label: 'Image',
    align: 'left',
    width: '14%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'img-6951',
    label: 'Program ID',
    align: 'left',
    width: '17%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'tit-12024',
    label: 'Program Title',
    align: 'left',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'season-8547',
    label: 'Season',
    align: 'left',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Episode-0054',
    label: 'Episode',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Duration-74',
    label: 'Duration(sec)',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Action-52041',
    label: 'Action',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const MetaProgramTd = [
  {
    id: 'MetaProgramTd-01',
    data01: 'PLT',
    data02: 'CSI',
    data03: '',
    data04: '5f6241e3ae4310001abb2465',
    data05: 'Aurora',
    data06: '1',
    data07: '16',
    data08: '3600',
    data09: '',
  },
  {
    id: 'MetaProgramTd-02',
    data01: 'PLT',
    data02: 'CSI',
    data03: '',
    data04: '5f6241e3ae4310001abb2465',
    data05: 'Aurora',
    data06: '1',
    data07: '16',
    data08: '3600',
    data09: '',
  },
  {
    id: 'MetaProgramTd-03',
    data01: 'PLT',
    data02: 'CSI',
    data03: '',
    data04: '5f6241e3ae4310001abb2465',
    data05: 'Aurora',
    data06: '1',
    data07: '16',
    data08: '3600',
    data09: '',
  },
  {
    id: 'MetaProgramTd-04',
    data01: 'PLT',
    data02: 'CSI',
    data03: '',
    data04: '5f6241e3ae4310001abb2465',
    data05: 'Aurora',
    data06: '1',
    data07: '16',
    data08: '3600',
    data09: '',
  },
  {
    id: 'MetaProgramTd-05',
    data01: 'PLT',
    data02: 'CSI',
    data03: '',
    data04: '5f6241e3ae4310001abb2465',
    data05: 'Aurora',
    data06: '1',
    data07: '16',
    data08: '3600',
    data09: '',
  },
];

export const MetaVodTh = [
  {
    id: 'vod-No',
    label: 'CP Name',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vod-Type',
    label: 'Type',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vod-ID',
    label: 'VOD ID',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vod-Title',
    label: 'VOD Title',
    align: 'left',
    width: '24%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vod-Duration',
    label: 'Duration(sec)',
    align: 'left',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vod-EpisodeNo',
    label: 'Episode No.',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vod-ReleaseDate',
    label: 'Release Date',
    align: 'left',
    width: '12%',
    sortable: true,
  },
  {
    id: 'vod-Action',
    label: 'Action',
    align: 'left',
    width: 'auto',
    sortable: true,
  },
];

export const MetaVodTd = [
  {
    id: 'MetaVodTd-01',
    data01: 'NCAA',
    data02: 'tvshow',
    data03: '1251181',
    data04: 'Technically a Tech Stock',
    data05: '2309',
    data06: '3',
    data07: '2008',
    data08: '',
  },
  {
    id: 'MetaVodTd-02',
    data01: 'NCAA',
    data02: 'tvshow',
    data03: '1251181',
    data04: 'Technically a Tech Stock',
    data05: '2309',
    data06: '3',
    data07: '2008',
    data08: '',
  },
  {
    id: 'MetaVodTd-03',
    data01: 'NCAA',
    data02: 'tvshow',
    data03: '1251181',
    data04: 'Technically a Tech Stock',
    data05: '2309',
    data06: '3',
    data07: '2008',
    data08: '',
  },
];

export const MetaSeriesTh = [
  {
    id: 'vod-No',
    label: 'CP Name',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vod-Type',
    label: 'Series ID',
    align: 'left',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vod-ID',
    label: 'Series Title',
    align: 'left',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vod-Title',
    label: 'Season ID',
    align: 'left',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vod-Duration',
    label: 'Season title',
    align: 'left',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vod-EpisodeNo',
    label: 'VOD',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const MetaSeriesTd = [
  {
    id: 'MetaSeriesTd-01',
    data01: 'Amagi',
    data02: 'Bloomberg_Optimists_Guide',
    data03: 'An Optimists Guide to the Planet',
    data04: 'Bloomberg_Optimists_Guide_S1',
    data05: 'An Optimists Guide to the Planet Season 1',
    data06: '8',
  },
  {
    id: 'MetaSeriesTd-02',
    data01: 'Amagi',
    data02: 'Bloomberg_Optimists_Guide',
    data03: 'An Optimists Guide to the Planet',
    data04: 'Bloomberg_Optimists_Guide_S1',
    data05: 'An Optimists Guide to the Planet Season 1',
    data06: '8',
  },
  {
    id: 'MetaSeriesTd-03',
    data01: 'Amagi',
    data02: 'Bloomberg_Optimists_Guide',
    data03: 'An Optimists Guide to the Planet',
    data04: 'Bloomberg_Optimists_Guide_S1',
    data05: 'An Optimists Guide to the Planet Season 1',
    data06: '8',
  },
];

export const MetaSeriesVodTh = [
  {
    id: 'vod-No',
    label: 'VOD ID',
    align: 'left',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vod-Type',
    label: 'VOD Title',
    align: 'left',
    width: '40%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vod-ID',
    label: 'Episode Number',
    align: 'left',
    width: '25%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vod-Title',
    label: 'Action',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];
export const MetaSeriesVodTd = [
  {
    id: 'MetaSeriesTd-01',
    data01: '123456',
    data02: 'Technical on board',
    data03: '1',
    data04: '',
  },
  {
    id: 'MetaSeriesTd-02',
    data01: '123456',
    data02: 'Technical on board',
    data03: '1',
    data04: '',
  },
];
