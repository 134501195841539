import { BASE_URL } from 'constants';
import { getHeaders } from 'utils/Header';
import { COMMON_PROVIDER_INFO } from 'actions/actionTypes';

export const getProvider = () => async (dispatch) => {
  fetch(`${BASE_URL}/admin/common/provider`, {
    method: 'GET',
    headers: getHeaders(),
  })
  .then(res => res.json())
  .then(body => {
    if (body.result === 'SUCCESS') {
      return dispatch({
        type: COMMON_PROVIDER_INFO,
        payload: body.data.entire,
      });
    } else {
      return dispatch({
        type: COMMON_PROVIDER_INFO,
        payload: 'error',
      });
    }
  }).catch(ex => {
    return dispatch({
      type: COMMON_PROVIDER_INFO,
      payload: 'error',
    });
  });
};
