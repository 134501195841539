import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Pagination from 'components/component/BasicPagination';
import TextField from 'components/component/BasicTextField';
import Button from 'components/component/BasicButton';
import CustomBasicMenu from 'components/component/BasicMenu';
import SelectBox from 'components/component/BasicSelectBox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import CheckBox from 'components/component/BasicCheckBox';
import {
  Box,
  Typography,
  Grid,
  TableSortLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { CategoryTitleTh, CategoryTitleTd, selectOption } from './data/InteractiveDockData';
import { AddPlus, DelMinus } from 'components/component/BasicIcon';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// ************************** 메인 컨텐츠 ***********************************
function CategoryTitle({ name }) {
  const optionMenu = {
    gnbMenu: 'Promotion',
    lnbMenu: 'Interactive Dock - Recommendation',
    normalMenu: ['Category Title'],
  };

  // Title 컨텐츠 선택 여부 - false: 선택암함 / true: 선택함
  // eslint-disable-next-line no-unused-vars
  const [isContentsSelected, setIsContentsSelected] = useState(false);

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // TableBody Row
  const sortedContentTdRows = sortRows([...CategoryTitleTd], orderBy, order);
  const selPageData = [{ text: 'Select This Page' }, { text: 'Select All Page' }];

  return (
    <Box sx={{ display: 'flex', height: '100%' }} className='wrapper'>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Typography component='p' className='title'>
                Category Title
              </Typography>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap category-list ' component='div'>
          <Grid container columnSpacing={2.5} className='content-pack-area'>
            {/* Content List */}
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Category</Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        20
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Box Content Area */}
                <Box className='box-content'>
                  <Box className='content-inner-wrap'>
                    {/* Table */}
                    <CustomTableContainer className='table-container'>
                      <Table className='custom-table' aria-label='table' stickyHeader>
                        {/* table Head */}
                        <TableHead className='thead'>
                          <TableRow className='tr'>
                            {CategoryTitleTh.map((column, index) => (
                              <TableCell
                                key={column.id}
                                className='th'
                                align={column.align}
                                sx={{ width: column.width }}
                              >
                                {column.checkbox ? (
                                  <Box component='div' sx={{ display: 'flex', gap: '8px' }}>
                                    <Box
                                      component='div'
                                      sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                    >
                                      <CheckBox
                                        label=''
                                        value='select-all'
                                        defaultChecked={false}
                                        inputProps={{
                                          'aria-label': 'select all',
                                        }}
                                        style={{ width: '18px' }}
                                      />
                                      {index === 0 && (
                                        <CustomBasicMenu
                                          type='icon'
                                          title={<ArrowDropDownIcon sx={{ fontSize: '18px', color: '#000' }} />}
                                          options={selPageData}
                                        />
                                      )}
                                    </Box>
                                  </Box>
                                ) : (
                                  <>
                                    {column.sortable ? (
                                      <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'desc'}
                                        onClick={() => handleRequestSort(column.id)}
                                      >
                                        {column.label}
                                      </TableSortLabel>
                                    ) : (
                                      column.label
                                    )}
                                  </>
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        {/* table Body */}
                        <TableBody className='tbody'>
                          {sortedContentTdRows.map((td, index) => (
                            <TableRow className='tr' key={td.id || index}>
                              {CategoryTitleTh.map((column, colIndex) => (
                                <TableCell key={column.id} className='td' align={column.align}>
                                  {colIndex === 4 ? (
                                    <Button
                                      color='tertiary'
                                      type='outline'
                                      size='small'
                                      label='EDIT'
                                      isIcon={false}
                                      Icon=''
                                      iconPos={undefined}
                                      autosize
                                    />
                                  ) : (
                                    td[`data0${colIndex + 1}`]
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CustomTableContainer>
                    {/* // Table */}
                  </Box>

                  {/* Pagination */}
                  <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
                </Box>
              </Box>
            </Grid>

            {/* Scheduled Content List */}
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Title</Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Button color='primary' type='box' size='small' label='Save' isIcon={false} autosize />
                  </Box>
                </Box>

                <Box className='box-content'>
                  {/* Title */}
                  <Box className='content-inner-wrap'>
                    <Box className='title-selected-container'>
                      {isContentsSelected === false ? (
                        <Box className='content-empty-container'>
                          <Typography component='p' className='empty-message'>
                            Please select a category.
                          </Typography>
                        </Box>
                      ) : (
                        <Box className='content-container'>
                          <Grid container>
                            <Grid item xs={12} sx={{ margin: '12px 0' }}>
                              <TextField
                                type='outlined'
                                size='large'
                                placeholder=''
                                defaultValue=''
                                label='Name'
                                autoComplete='off'
                                required={false}
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>

                            <Grid item xs={12} sx={{ margin: '12px 0' }}>
                              <div className='title-area'>
                                <Typography className='field-label bold required'>Multilingual Name</Typography>
                              </div>
                              <Box component='div' className='field-box flex-start'>
                                <SelectBox
                                  id='Criteria-01'
                                  fullWidth
                                  required={false}
                                  size='large'
                                  maxWidth='170px'
                                  selectOptions={selectOption}
                                  disabled={false}
                                  label=''
                                />

                                <TextField
                                  type='outlined'
                                  size='large'
                                  placeholder=''
                                  defaultValue=''
                                  maxWidth='100%'
                                  label=''
                                  autoComplete='off'
                                  required={true}
                                  InputLabelProps={{ shrink: false }}
                                  disabled={false}
                                />
                                {/* 행 추가 버튼 */}
                                <Button
                                  color='tertiary'
                                  type='outline icon'
                                  size='medium'
                                  label=''
                                  isIcon={true}
                                  Icon={AddPlus}
                                  autosize
                                />
                              </Box>

                              <Box component='div' className='field-box flex-start'>
                                <SelectBox
                                  id='Criteria-02'
                                  fullWidth
                                  required={true}
                                  size='large'
                                  maxWidth='170px'
                                  selectOptions={selectOption}
                                  disabled={false}
                                  label=''
                                />

                                <TextField
                                  type='outlined'
                                  size='large'
                                  placeholder=''
                                  defaultValue=''
                                  maxWidth='100%'
                                  label=''
                                  autoComplete='off'
                                  required={false}
                                  InputLabelProps={{ shrink: false }}
                                  disabled={false}
                                />
                                {/* 행 추가 버튼 */}
                                <Button
                                  color='tertiary'
                                  type='outline icon'
                                  size='medium'
                                  label=''
                                  isIcon={true}
                                  Icon={AddPlus}
                                  autosize
                                />
                                {/* 행 삭제 버튼 */}
                                <Button
                                  color='tertiary'
                                  type='outline icon'
                                  size='medium'
                                  label=''
                                  isIcon={true}
                                  Icon={DelMinus}
                                  autosize
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
export default CategoryTitle;
