import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch } from '@mui/material';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

// Styled
const SwitchContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  .mode-label {
    font-size: 14px;
    color: #c6c6c6;
    margin-left: 2px;

    &.on {
      color: #2196f3;
    }
  }
`;

const FormSwitchStyled = styled(FormControlLabel)(({ theme }) => ({
  '&.MuiFormControlLabel-root': {
    margin: 0,
  },

  '& .MuiSwitch-root': {
    width: '48px',
    height: '28px',
  },
  '& .MuiButtonBase-root': {
    padding: '4px 6px',
    '& .MuiSwitch-thumb': {
      border: '1px solid #ddd',

      '.dark &': {
        border: '1px solid #262626',
        background: '#000',
      },
    },
    '& + .MuiSwitch-track': {
      opacity: 1,
      height: '5px',
      background: '#C6C6C6',

      '.dark &': {
        background: '#484848',
      },
    },
    '&.Mui-checked': {
      color: '#A50034',
      '.dark &': {
        color: '#A50034',
      },
      '& .MuiSwitch-thumb': {
        border: 0,

        '.dark &': {
          background: '#A50034',
        },
      },
      '& + .MuiSwitch-track': {
        background: '#EE93B3',
      },
      '&.Mui-disabled': {
        color: '#E4E7EA',
        '& + .MuiSwitch-track': {
          background: '#ddd',
          opacity: 0.6,
        },
      },
    },
    '&.Mui-disabled': {
      color: '#E4E7EA',
      '& .MuiSwitch-thumb': {
        border: 0,
      },
      '& + .MuiSwitch-track': {
        background: '#E4E7EA',
      },
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      color: '#ddd',
    },
  },
  '& .MuiTypography-root': {
    fontSize: '14px',
    color: '#000',
    fontWeight: 400,
  },
}));

// Switch Component
function BasicSwitch({ disabled, className, width, onOffMode, setSwitchOnOff, isLabelText, onText, offText }) {
  const handleOnOff = (event) => {
    setSwitchOnOff(event.target.checked);
  };

  const handleLabel = onOffMode ? onText : offText;

  return (
    <SwitchContainer sx={{ width: width }} className={className}>
      <FormSwitchStyled control={<Switch checked={onOffMode} onChange={handleOnOff} disabled={disabled} />} />
      {isLabelText && <span className={`mode-label ${onOffMode ? 'on' : ''}`}>{handleLabel}</span>}
    </SwitchContainer>
  );
}
BasicSwitch.propTypes = {
  disabled: PropTypes.bool,
  onOffMode: PropTypes.bool,
  setSwitchOnOff: PropTypes.func,
  isLabelText: PropTypes.bool,
  onText: PropTypes.string,
  offText: PropTypes.string,
};

export default BasicSwitch;
