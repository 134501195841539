import * as React from 'react';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

// Styled
const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 18,
  height: 18,
  border: '1px solid #ddd',
  '.dark &': {
    border: '1px solid rgba(163, 163, 163, 0.4)',
  },
  'input:hover ~ &': {
    backgroundColor: '#E4E7EA',
    borderColor: '#909090',

    '.dark &': {
      backgroundColor: '#5D5D5D',
    },
  },
  '& .MuiFormControlLabel-root': {
    margin: 0,
    '.MuiTypography-root': {
      fontSize: '14px',
      color: '#000',

      '&.Mui-disabled': {
        color: '#EEF0F1',
      },
    },
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#A50034',
  border: 0,
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '.dark &': {
    border: 0,
  },
  '&::before': {
    display: 'block',
    width: '8px',
    height: '8px',
    content: '""',
    background: '#fff',
    borderRadius: '50%',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',

    '.dark &': {
      background: '#1B1B1B',
    },
  },
  '&.Mui-disabled': {
    '&::before': {
      background: '#fff',
    },
  },
  'input:hover ~ &': {
    backgroundColor: '#D00D0A',

    '.dark &': {
      border: 0,
      backgroundColor: '#A50034',
    },
  },
});

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '.MuiTypography-root': {
    fontSize: '14px',
    fontWeight: 400,
    color: '#000',

    '.dark &': {
      color: '#fff',
    },

    '&.Mui-disabled': {
      color: '#B7BFCA',

      '.dark &': {
        color: '#262626',
      },
    },
  },
  '& .Mui-disabled': {
    color: '#B7BFCA',
    '& span': {
      background: '#EEF0F1',
      border: 0,

      '.dark &': {
        background: 'rgba(228, 231, 234, 0.1)',
        border: '1px solid rgba(228, 231, 234, 0.1)',

        '&:before': {
          background: '#1b1b1b',
        },
      },
    },
  },
}));

function BpRadio({ id, name, ...props }) {
  return <Radio id={id} name={name} disableRipple checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} {...props} />;
}

// Radio Component
function BasicRadio({ id, value, label, name, disabled, onChange, checked }) {
  return (
    <StyledFormControlLabel
      value={value}
      disabled={disabled}
      control={<BpRadio id={id} onChange={onChange} name={name} checked={checked} disabled={disabled} />}
      label={label}
    />
  );
}
export default BasicRadio;
