import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import CheckBox from 'components/component/BasicCheckBox';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import TextLink from 'components/component/BasicTextLink';
import SelectBox from 'components/component/BasicSelectBox';
import DatePicker from 'components/component/BasicDatePicker';
import Pagination from 'components/component/BasicPagination';
import TextField from 'components/component/BasicTextField';

import {
  Box,
  Link,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import { styled } from '@mui/material/styles';
// Icon

import {
  AnServiceDeviceTypeOption,
  AnServiceCountryOption,
  PlatformVersionOption,
  CategoryOption,
  StatusOption,
  TabOption,
  AnServiceTh,
  AnServiceTd,
} from 'pages/Organization/ANService/data/ANServicedata';

// Icon
import { RefreshIcon } from 'components/component/BasicIcon';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Button Data
const buttons = [
  {
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    label: 'Reset',
    isIcon: true,
    Icon: RefreshIcon,
    iconPos: 'left',
    className: 'custom-save-icon',
    width: 107,
  },
  {
    color: 'primary',
    type: 'box',
    size: 'medium',
    label: 'Search',
  },
];
const BoxButtons = [
  {
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    label: 'Delete',
    isIcon: false,
    autosize: true,
  },
  {
    color: 'primary',
    type: 'box',
    size: 'medium',
    label: 'Add',
    autosize: true,
  },
];

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// 메인 컨텐츠
function ANService() {
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'AN Service',
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const sortedTdRows = sortRows([...AnServiceTd], orderBy, order);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className='main-container category-tab-list' component='main' sx={{ flexGrow: 1 }}>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href=''>
                AN Service
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap' component='div'>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                {/* 필터영역 : 컴포넌트 개수에 따라 col-3,col-4, col-5, col-6  */}
                <Box className='component-wrap col-5'>
                  {/* Country */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='AnService-Country'
                      size='large'
                      shrink={true}
                      label='Country'
                      selectOptions={AnServiceCountryOption}
                      disabled={false}
                      placeholder=''
                      value=''
                    />
                  </Box>

                  {/* Device Type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='AnService-Device'
                      size='large'
                      shrink={true}
                      label='Device Type'
                      selectOptions={AnServiceDeviceTypeOption}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>

                  {/* Tab */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='AnService-Tab'
                      size='large'
                      shrink={true}
                      label='Tab'
                      selectOptions={TabOption}
                      disabled={false}
                      placeholder=''
                      value=''
                    />
                  </Box>

                  {/* Category */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='AnService-Category'
                      size='large'
                      shrink={true}
                      label='Category'
                      selectOptions={CategoryOption}
                      disabled={false}
                      placeholder=''
                      value=''
                    />
                  </Box>

                  {/* Platform Version */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='AnService-PlatformV'
                      size='large'
                      shrink={true}
                      label='Platform Version'
                      selectOptions={PlatformVersionOption}
                      disabled={false}
                      placeholder=''
                      value=''
                    />
                  </Box>
                </Box>

                {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
                <Box className='component-wrap col-5'>
                  {/* Status */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='AnService-Status'
                      size='large'
                      shrink={true}
                      label='Status'
                      selectOptions={StatusOption}
                      disabled={false}
                      placeholder=''
                      value=''
                    />
                  </Box>

                  {/* Status */}
                  <Box component='div' className='col'>
                    <TextField
                      type='outlined'
                      size='large'
                      placeholder='Title'
                      defaultValue=''
                      label=''
                      autoComplete='off'
                      required={true}
                      InputLabelProps={{ shrink: false }}
                    />
                  </Box>
                  <Box component='div' className='col auto'>
                    <DatePicker
                      className='input-date-picker'
                      format='MM/DD/YYYY'
                      size='large'
                      maxWidth='210px'
                      singleDate={false}
                      rangeDate={true}
                      wave={true}
                    />
                  </Box>
                </Box>
              </Box>
              <div className='form-area right'>
                <CustomButtonGroup buttons={buttons} />
              </div>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap single-box'>
            <div className='box-content'>
              <Box className='white-box-content-top-area'>
                <Box className='left-area'>
                  <Box className='box-title-area' display='flex' alignItems='center'>
                    <Box className='total-count'>
                      <Box component='span' className='label'>
                        Total
                      </Box>
                      <Box component='span' className='value'>
                        445
                      </Box>
                    </Box>
                    <Typography className='sm-sub-text' ml={3}>
                      Local Time Based
                    </Typography>
                  </Box>
                </Box>
                <Box className='right-area'>
                  <CustomButtonGroup buttons={BoxButtons} />
                </Box>
              </Box>
              <Box className='content-inner-wrap'>
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {AnServiceTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <Box display='flex' alignContent='center'>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  defaultChecked={false}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </Box>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedTdRows.map((td, index) => (
                        <TableRow className='tr' key={td.id || index}>
                          {AnServiceTh.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {colIndex === 0 ? (
                                <CheckBox
                                  label={<TextLink href='' text={td.data01} />}
                                  value='value'
                                  defaultChecked={false}
                                />
                              ) : colIndex === 8 ? (
                                <span className={td.data09 === 'ready' ? 'ready' : td.data09 === 'done' ? 'done' : ''}>
                                  {td.data09 === 'ready' ? 'Ready' : td.data09 === 'done' ? 'Done' : ''}
                                </span>
                              ) : colIndex === 9 ? (
                                <Box display='flex' gap={1}>
                                  {td.data10.map((buttonData, index) => (
                                    <Button
                                      key={index}
                                      color='tertiary'
                                      type='outline'
                                      size='small'
                                      label={buttonData.btnText}
                                      disabled={buttonData.disabled}
                                      isIcon={false}
                                      autosize
                                    />
                                  ))}
                                </Box>
                              ) : (
                                td[`data0${colIndex + 1}`]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
              </Box>

              {/* Pagination */}
              <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default ANService;
