import React, { useEffect, useState } from 'react';

import BreadCrumb from 'components/component/BasicBreadCrumb';
import Snackbar from 'components/component/BasicSnackbar';
import Button from 'components/component/BasicButton';
import CustomImage from 'components/component/BasicImage';
import CustomRadio from 'components/component/BasicRadio';
import { Box, Typography } from '@mui/material';

// css
import styles from 'assets/scss/page/myProfile.module.scss';

//images
import LightThemeImg from 'assets/images/LightTheme.png';
import DarkThemeImg from 'assets/images/DarkThemeImg.png';

import PopChangePassword from './PopChangePassword';

function MyProfile(props) {
  const { currentUser, darkMode, callBackDarkMode } = props;
  const [selectedTheme, setSelectedTheme] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (darkMode) {
      setSelectedTheme('dark');
    } else {
      setSelectedTheme('light');
    }
  }, [darkMode]);

  // BreadCrumb
  const optionMenu = {
    normalMenu: ['My Profile'],
  };

  const handleThemeChange = (event) => {
    setSelectedTheme(event.target.value);
    callBackDarkMode(event.target.value === 'dark' ? true : false);
  };

  const handleImageClick = (theme) => {
    setSelectedTheme(theme);
    callBackDarkMode(theme === 'dark' ? true : false);
  };

  const handlePassword = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container' component='main'>
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <span className='title'>My Profile</span>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>

        <Box className={styles.myProfileContainer}>
          <Box className={`${styles.whiteBox} ${styles.idBox}`}>
            <Box className={styles.whiteBoxWrap}>
              {/* ID */}
              <div className={`${styles.profileValueWrap} ${styles.id}`}>
                <Typography component='span' className={styles.label}>
                  ID
                </Typography>
                <div className={styles['value']}>
                  <Typography component='span' className={styles['text']}>
                    {currentUser.name}
                  </Typography>
                </div>
              </div>

              {/* Password */}
              <div className={`${styles.profileValueWrap} ${styles.password}`}>
                <Typography component='span' className={styles.label}>
                  Password
                </Typography>
                <div className={styles['value']}>
                  <Button color='tertiary' type='outline' size='medium' label='Change' isIcon={false} autosize onClick={handlePassword}/>
                </div>
              </div>
            </Box>
          </Box>

          <Box component='div' className={styles.whiteBox}>
            <Box className={styles.titleArea}>
              <Typography className={styles.boxTitle}>Theme</Typography>
            </Box>
            <Box className={styles.contentArea}>
              <Box className={styles.selectTheme}>
                <Box className={`${styles.light} ${styles.item}`} onClick={() => handleImageClick('light')}>
                  <CustomImage
                    src={LightThemeImg}
                    fit={false}
                    imgWidth='250px'
                    imgHeight='132px'
                    alt=''
                    rounded
                    sx={{ backgroundColor: '#f1f1f1' }}
                    className={styles.lightBox}
                  />
                  <Box mt={3}>
                    <CustomRadio
                      value=''
                      name='theme'
                      label='Light theme'
                      checked={selectedTheme === 'light'}
                      onChange={handleThemeChange}
                    />
                  </Box>
                </Box>
                <Box className={`${styles.dark} ${styles.item}`} onClick={() => handleImageClick('dark')}>
                  <CustomImage
                    src={DarkThemeImg}
                    fit={false}
                    imgWidth='250px'
                    imgHeight='132px'
                    alt=''
                    rounded
                    sx={{ backgroundColor: '#292929' }}
                    className={styles.darkBox}
                  />
                  <Box mt={3}>
                    <CustomRadio
                      value=''
                      name='theme'
                      label='Dark theme'
                      checked={selectedTheme === 'dark'}
                      onChange={handleThemeChange}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {/*
      Toast Pop-Up
      severity: default, error, warning, info, success
      */}
      <Snackbar severity='success' message='success' open={false} duration={1000} bottom={50} darkMode={darkMode} />
      <PopChangePassword currentUser={currentUser} isOpen={isOpen} callbackClose={handleClose} />
    </Box>
  );
}
export default MyProfile;
