import React from 'react';
import { Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import Button from 'components/component/BasicButton';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import CloseIcon32 from 'assets/images/icon/ic_close_32_outline.svg';
import DarkCloseIcon32 from 'assets/images/icon/ic_close_32_outline_white.svg';
import Divider from '@mui/material/Divider';

// Styled
const CustomDialog = styled(MuiDialog)(({ theme, size, customWidth }) => ({
  '& .MuiPaper-root': {
    padding: '16px 28px 32px 32px',
    backgroundColor: '#fff',
    boxShadow: '0px 12px 20px 0px rgba(0, 0, 0, 0.10)',
    borderRadius: '20px',
    maxWidth: size === 'small' ? '400px' : size === 'medium' ? '600px' : '1000px',
    width: customWidth || '100%',

    '.dark &': {
      backgroundColor: '#1C1C1C',
      boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.40), 0px 12px 25px 0px rgba(0, 0, 0, 0.50)',
    },
  },

  '& .MuiDialogTitle-root': {
    padding: '10px 0',
    margin: '0 0 12px',
    fontSize: '20px',
    lineHeight: '36px',
    fontWeight: 700,

    '.dark &': {
      color: '#fff',
    },
  },
  '& .MuiDialogContent-root': {
    padding: '0 3px 24px 0',
    border: 0,
  },
  '& .MuiDialogContentText-root': {
    color: '#4E5258',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',

    '&.MuiTypography-root': {
      '.dark &': {
        color: '#808080',
      },
    },
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'center',
    padding: 0,
  },
}));
const CustomDivider = styled(Divider)(({ theme }) => ({
  '&.MuiDivider-root': {
    borderColor: '#ddd',

    '.dark &': {
      borderColor: '#323232',
    },
  },
}));

const ContentTitleText = styled('div')({
  fontSize: '16px',
  color: '#4E5258',
  fontWeight: 700,
  marginBottom: '12px',

  '.dark &': {
    color: '#fff',
  },
});
const CloseIcon = styled('span')({
  display: 'block',
  width: '32px',
  height: '32px',
  backgroundImage: `url(${CloseIcon32})`,
  backgroundRepeat: 'no-repeat',

  '.dark &': {
    backgroundImage: `url(${DarkCloseIcon32})`,
  },
});
const BgBox = styled('div')({
  padding: '12px',
  marginBottom: '12px',
  backgroundColor: '#f7f7f7',

  '.dark &': {
    backgroundColor: '#0c0c0c',
  },
});
const BgBoxTitleText = styled('p')({
  fontSize: '16px',
  marginBottom: '12px',
  color: '#525252',
  fontWeight: 700,

  '.dark &': {
    color: '#fff',
  },
});
const DateText = styled('p')({
  fontSize: '12px',
  color: '#909090',
  fontWeight: 400,
});

// Dialog Component
function BasicDialog({
  open,
  handleClose,
  size,
  title,
  bottomDivider,
  contentTitle,
  contentTextProps,
  closeX,
  width,
  noticeBgBox,
  noticeDate,
  contentText,
  noticeBoxTitle,
  buttons = [],
  className = [],
  customWidth,
  ...rest
}) {
  return (
    <CustomDialog open={open} onClose={handleClose} size={size} className={`${className} ${size}`} {...rest}>
      <DialogTitle>
        {title}
        {closeX && (
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 24,
              top: 18,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>

      {/* BG BOX Title */}
      {noticeBgBox && (
        <BgBox className='bg-box'>
          <BgBoxTitleText className=''>{noticeBoxTitle}</BgBoxTitleText>
          <DateText>{noticeDate}</DateText>
        </BgBox>
      )}

      <DialogContent dividers>
        {contentTitle && <ContentTitleText>{contentTitle}</ContentTitleText>}
        {contentTextProps || <DialogContentText>{contentText}</DialogContentText>}
      </DialogContent>
      {bottomDivider && <CustomDivider sx={{ marginBottom: '24px' }} className='divider' />}
      <DialogActions>
        {buttons.map((button, index) => (
          <Button
            key={index}
            color={button.color}
            type={button.type}
            sx={{ width: button.width }}
            size={button.size}
            label={button.label}
            onClick={button.onClick}
            disabled={button.disabled}
          />
        ))}
      </DialogActions>
    </CustomDialog>
  );
}

export default BasicDialog;
