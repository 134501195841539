import { createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import cmp from 'reducers';

export default function configureStore(initialState) {
  // Redux DevTools Extension을 사용하여 Chrome Inspector에서 Redux 상태를 확인할 수 있도록 설정
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    cmp,
    initialState,
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
}