import React, { useState, useEffect } from 'react';
import { FormControl, MenuItem, Select, Paper, GlobalStyles, OutlinedInput, InputLabel } from '@mui/material';
import styled from '@emotion/styled';
import Checkbox from 'components/component/BasicCheckBox';

import SelectArrowDark from 'assets/images/icon/SelectArrowDarkIcon.svg';

const globalStyles = (
  <GlobalStyles
    styles={{
      '.MuiPaper-root': {
        '.MuiList-root': {
          '.MuiMenuItem-root': {
            '&>li:first-of-type': {
              display: 'none',
            },
            '&.Mui-focusVisible': {
              opacity: 1,
            },
          },
        },
      },
    }}
  />
);
// Styled
const StyledFormControl = styled(FormControl)`
  &.MuiFormControl-root {
    width: 100%;
    &.large {
      height: 40px;

      .MuiInputBase-root {
        height: 40px;
      }
      .MuiSelect-select {
        height: 40px;
        box-sizing: border-box;
        padding: 12px 30px 12px 12px;
        overflow: hidden;
        text-overflow: ellipsis;

        .MuiListItemText-root {
          margin: 0;
        }
      }
    }
    &.medium {
      height: 32px;

      .MuiSelect-select {
        height: 32px;
        box-sizing: border-box;
        padding: 10px 12px 4px;

        .MuiListItemText-root {
          margin: 0;
        }
      }
    }
    &.small {
      height: 28px;

      .MuiInputBase-root {
        height: 28px;
      }
      .MuiSelect-select {
        height: 28px;
        box-sizing: border-box;
        padding: 8px 12px;

        .MuiListItemText-root {
          margin: 0;
        }
      }
    }
    // Pagination 왼쪽 출력할 행의 수 선택 Select box
    .print-per-count {
      min-width: 100px;
      padding: 0;

      .MuiSelect-select {
        width: 100%;
        padding: 6px 12px;
        border-color: #a3a3a3;
        font-size: 14px;
        font-weight: 400;
        color: #525252;
        line-height: 20px;
      }
    }
  }
  .MuiFormLabel-root {
    top: 50%;
    transform: translate(14px, -50%);
    font-size: 12px;
    line-height: 20px;

    &.MuiInputLabel-sizeLarge {
      &.MuiFormLabel-filled {
        margin-top: 0;
      }
    }

    &.MuiInputLabel-sizeSmall {
      &.MuiFormLabel-filled {
        margin-top: 0;
      }
    }

    &.Mui-focused {
      font-size: 12px;
      background-color: #fff;
      margin-top: 0;
    }

    &.Mui-disabled {
      opacity: 0.4;
    }
  }

  .MuiInputBase-root {
    padding-top: 6px;
    padding-bottom: 6px;
    height: 32px;
    width: 100%;

    .MuiSelect-select {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 14px;

      .dark & {
        color: #fff;
      }

      .MuiTypography-root {
        font-size: 14px;
        line-height: 20px;
        color: #000;
      }
      .placeholder {
        color: #bbb;
      }
    }
    .MuiSvgIcon-root {
      .dark & {
        top: 50%;
        margin-top: -12px;
        width: 24px;
        height: 24px;
        background: url(${SelectArrowDark}) no-repeat 50%;
      }
    }
    &.MuiInputBase-sizeLarge {
      padding-top: 10px;
      padding-bottom: 10px;
      height: 40px;
    }

    &.MuiInputBase-sizeSmall {
      height: 28px;
    }
  }

  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #a3a3a3;

      .dark & {
        border-color: #484848;
      }
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: #2196f3;
    }

    &.Mui-error .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: #b91c1c;
    }

    &.Mui-disabled {
      background-color: #ececec;
      opacity: 0.4;
    }
  }
  legend {
    width: 0;
  }
`;

const CustomPaper = styled(Paper)({
  '&.MuiPaper-root': {
    maxHeight: '200px',
    overflowY: 'auto',
  },
  '.dark &': {
    background: '#121214',
  },
  '& .MuiMenuItem-root': {
    padding: '6px 12px',

    '&.Mui-selected': {
      backgroundColor: '#f7f7f7',

      '.dark &': {
        backgroundColor: '#303030',
      },
    },

    '&.check-all': {
      padding: 0,

      '& .check-all-area': {
        width: '100%',
        padding: '6px 12px',
        borderBottom: '1px solid #ddd',

        '.dark &': {
          borderBottom: '1px solid #484848',
        },
      },
    },
    '&.placeholder': {
      display: 'none',
    },
  },
  '& .MuiFormControlLabel-root': {
    width: '100%',
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  '& .MuiTypography-root': {
    fontSize: '14px',
    lineHeight: '20px',

    '.dark &': {
      color: '#fff',
    },
  },
  '& .MuiRadio-root': {
    padding: 0,
    marginRight: '12px',
  },
});

const StyledInputLabel = styled(InputLabel)`
  &.MuiFormLabel-root {
    &.MuiInputLabel-shrink {
      position: absolute;
      left: 0;
      padding: 0 3px;
      background: #fff;
      font-size: 12px;
      line-height: 1;
      z-index: 10;

      .dark & {
        background: #1c1c1c;
        color: #b1b1b1;
      }

      &.MuiInputLabel-sizeLarge,
      &.MuiInputLabel-sizeMedium,
      &.MuiInputLabel-sizeSmall {
        top: 0;
      }
    }
    .required {
      &:after {
        content: '*';
        color: #a50034;
        font-size: 14px;
        margin-left: 3px;
      }
    }
  }
`;
const PlaceholderMenuItem = styled(MenuItem)`
  color: #bbbbbb;
`;

// SelectBox Component
const BasicSelectCheckBox = ({
  id,
  selectOptions = [],
  size,
  label,
  error,
  disabled,
  width,
  maxWidth,
  shrink,
  required,
  onChange,
  placeholder,
  classes,
  multiple = true,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [userChange, setUserChange] = useState(false);

  useEffect(() => {
    if (selectOptions) {
      const hasAllChecked = selectOptions.some(option => option.value === 'all' && option.defaultChecked);

      if (hasAllChecked) {
        const allValues = selectOptions.map(option => option.value);
        setSelectedValues(allValues);
      } else {
        const hasDefaultCheckedItems = selectOptions.filter(option => option.value !== 'all' && option.defaultChecked).map(option => option.value);
        if (hasDefaultCheckedItems) {
          setSelectedValues([...hasDefaultCheckedItems]);
        } else {
          setSelectedValues([]);
        }
      }
    }
  }, [selectOptions]);

  useEffect(() => {
    if (userChange && selectedValues) {
      const checkedValues = selectedValues.filter(val => val !== 'all');
      onChange(checkedValues);
    }
    // eslint-disable-next-line
  }, [userChange, selectedValues]);

  const handleAllChange = (checked) => {
    if (checked) {
      const allValues = selectOptions.map(option => option.value);
      setSelectedValues(allValues);
    } else {
      setSelectedValues([]);
    }
    setUserChange(true);
  };

  const handleOptionChange = (id, checked) => {
    const optionValue = selectOptions.find(option => option.id === id).value;

    if (checked) {
      const updatedValues = [...selectedValues, optionValue];

      if (updatedValues.length === selectOptions.length - 1) {
        setSelectedValues(selectOptions.map(option => option.value));
      } else {
        setSelectedValues(updatedValues);
      }
    } else {
      const updatedValues = selectedValues.filter(item => item !== optionValue);
      setSelectedValues(updatedValues.filter(val => val !== 'all'));
    }
    setUserChange(true);
  };

  const renderCheckboxOption = (selectOption) => {
    return (
      <MenuItem
        key={selectOption.id}
        value={selectOption.value}
        className={`option ${selectOption.value === 'all' ? 'check-all' : ''}`}
      >
        <div className='check-all-area'>
          <Checkbox
            label={selectOption.option}
            value={selectOption.value}
            checked={selectedValues.includes(selectOption.value)}
            onChange={(e) => {
              if (selectOption.id === 'all') {
                handleAllChange(e.target.checked);
              } else {
                handleOptionChange(selectOption.id, e.target.checked);
              }
            }}
          />
        </div>
      </MenuItem>
    );
  };

  return (
    <StyledFormControl
      sx={{ width: width, maxWidth: maxWidth }}
      size={size}
      disabled={disabled}
      variant='outlined'
      className={size}
    >
      {globalStyles}

      {label && (
        <StyledInputLabel id={`${id}`} htmlFor={`${id}`} shrink={shrink}>
          <span className={required ? 'required' : ''}>{label}</span>
        </StyledInputLabel>
      )}
      <Select
        labelId={label ? `${id}` : undefined}
        id={id}
        value={selectedValues}
        label={label || ''}
        input={<OutlinedInput label={label || ''} />}
        displayEmpty
        multiple={multiple}
        error={error}
        className={classes}
        MenuProps={{
          disableScrollLock: true,
          PaperProps: {
            component: CustomPaper,
          },
        }}
        renderValue={(selected) => {
          if (!selected || (Array.isArray(selected) && selected.length === 0)) {
            return <span className='placeholder'>{placeholder}</span>;
          }

          if (Array.isArray(selected)) {
            const selectedLabels = selected
              .map((value) => selectOptions.find((option) => option.value === value)?.option)
              .filter(Boolean);

            if (selectedLabels.length > 1) {
              return selectedLabels.filter(item => item !== 'All').length + ' menus';
            } else {
              return selectedLabels;
            }
          }

          const selectedOption = selectOptions.find((option) => option.value === selected);
          return selectedOption ? selectedOption.option : '';
        }}
      >
        {placeholder && !selectedValues && (
          <PlaceholderMenuItem value='' disabled className='placeholder'>
            {placeholder}
          </PlaceholderMenuItem>
        )}
        {/* Checkbox 타입의 옵션만 렌더링 */}
        {selectOptions
          .filter((option) => option.type === 'checkbox') // checkbox 타입만 필터링
          .map((selectOption) => renderCheckboxOption(selectOption))}{' '}
        {/* 함수 호출로 렌더링 */}
      </Select>
    </StyledFormControl>
  );
};

export default BasicSelectCheckBox;