import React from 'react';
import styled from '@emotion/styled';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: 0,
  color: '#B7BFCA',
  padding: 0,
  borderWidth: '1px',

  '& .MuiCheckbox-root': {
    width: '18px',
    height: '18px',
    margin: '3px',
    flexShrink: 0,
    padding: 0,
    borderRadius: '4px',
    color: '#A3A3A3',

    '.dark &': {
      color: '#5d5d5d',
    },
    '& .MuiSvgIcon-root': {
      width: '24px',
      height: '24px',
      borderRadius: '4px',

      '& path': {
        strokeWidth: 1,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      },
    },

    '& + .MuiFormControlLabel-label': {
      marginLeft: '8px',
    },
  },

  '&:hover .MuiCheckbox-root': {
    color: '#838A97',
    backgroundColor: 'rgba(183, 191, 202, 0.3)',
    border: 0,
  },

  '&:hover .MuiCheckbox-root.Mui-checked': {
    color: '#D00D0A',
  },

  '& .MuiCheckbox-root.Mui-disabled': {
    color: '#B7BFCA',
    background: 'rgba(183, 191, 202, 0.15)',

    '.dark &': {
      color: '#B7BFCA',
    },
  },
  '&.Mui-disabled .MuiCheckbox-root': {
    color: '#E4E7EA',
    background: 'rgba(183, 191, 202, 0.15)',

    '.dark &': {
      opacity: 0.3,
    },
  },

  '& .MuiCheckbox-root.Mui-checked': {
    color: '#A50034',

    '.dark &': {
      background: '#fff',
    },
  },

  '& .MuiCheckbox-root.Mui-checked.Mui-disabled': {
    borderRadius: '4px',
    color: '#E4E7EA',
    backgroundColor: '#fff',
  },

  '&:focus': {
    color: '#FD312E',
  },

  '& .Mui-disabled': {
    color: '#C6C6C6',
    background: '',
  },

  '& .MuiTypography-root': {
    fontFamily: 'Roboto, Arial, sans-serif',
    color: '#000',
    fontSize: '14px',
    fontWeight: 400,
    '.dark &': {
      color: '#fff',
    },
    '&.Mui-disabled': {
      color: '#B7BFCA',

      '.dark &': {
        opacity: 0.3,
        color: '#B7BFCA',
      },
    },
  },
}));

function BasicCheckBox({ disabled, label, defaultChecked, checked, onChange, value, indeterminate }) {
  return (
    <StyledFormControlLabel
      control={<Checkbox indeterminate={indeterminate} defaultChecked={defaultChecked} checked={checked} name={`${value}`} />}
      label={label}
      value={value}
      disabled={disabled}
      onChange={onChange}
    />
  );
}

export default BasicCheckBox;
