import React from 'react';
import MuiButtonGroup from '@mui/material/ButtonGroup';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Button from 'components/component/BasicButton';
import Tooltip from 'components/component/BasicTooltip';

const StyledButtonGroup = styled(MuiButtonGroup)`
  &.MuiButtonGroup-root {
    gap: ${({ gap }) => gap || '8px'};
    flex-wrap: wrap;
  }
`;

const BasicButtonGroup = ({
  buttons,
  tooltip,
  tooltipText,
  tooltipPosition,
  justifyContent = 'center',
  alignItems,
  gap,
  onClick,
  ...props
}) => (
  <Box
    className='button-group'
    display='flex'
    alignItems={alignItems}
    justifyContent={justifyContent}
    sx={{ width: '100%' }}
  >
    <StyledButtonGroup {...props} gap={gap}>
      {buttons.map((button, index) =>
        button.tooltip ? (
          <Tooltip key={index} title={button.tooltipText} placement='top' className='tooltip-wrap'>
            <Button
              color={button.color}
              type={button.type}
              size={button.size}
              label={button.label}
              Icon={button.Icon}
              isIcon={button.isIcon === 'true' || button.isIcon === true}
              iconPos={button.isIcon ? button.iconPos || 'left' : undefined}
              className={`btn ${button.className || ''}`}
              autosize={button.autosize}
              sx={{ minWidth: button.autosize ? 'auto' : button.minWidth }}
              {...(button.restProps || {})}
              disabled={button.disabled}
              onClick={button.onClick}
            />
          </Tooltip>
        ) : (
          <Button
            key={index}
            color={button.color}
            type={button.type}
            size={button.size}
            label={button.label}
            Icon={button.Icon}
            isIcon={button.isIcon === 'true' || button.isIcon === true}
            iconPos={button.isIcon ? button.iconPos || 'left' : undefined}
            className={`btn ${button.className || ''}`}
            autosize={button.autosize}
            sx={{ minWidth: button.autosize ? 'auto' : button.minWidth }}
            {...(button.restProps || {})}
            disabled={button.disabled}
            onClick={button.onClick}
          />
        )
      )}
    </StyledButtonGroup>
  </Box>
);

export default BasicButtonGroup;
