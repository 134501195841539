import React from 'react';
import Main from 'pages/Main/Main';
import Login from 'pages/Login/Login';

function Entry(props) {
  const { isAuthenticated, message, currentUser } = props;

  if (isAuthenticated) {
    return <Main currentUser={currentUser} />;
  } else {
    return <Login message={message} currentUser={currentUser}/>;
  }
}

export default Entry;