import React, { useState } from 'react';
import { List, ListItem, ListItemText, ListItemButton, Typography, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import CustomRadio from 'components/component/BasicRadio';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&.MuiListItem-root': {
    position: 'relative',
    borderBottom: '1px solid #ddd',
    '.dark &': {
      borderBottom: '1px solid #1B1B1B',
    },
    '&.Mui-disabled': {
      '.title': {
        color: '#C6C6C6',
      },
    },

    '& .MuiTypography-root': {
      color: '#525252',

      '.dark &': {
        color: '#424242',
        '.MuiListItemText-primary': {
          color: '#ffffff',
        }
      },
    },
  },
}));

const StyledList = styled(List)(({ theme }) => ({
  // Cell Type
  '&[aria-label="cell-list"]': {
    '& .MuiListItem-root': {
      padding: 0,

      '.cell-type1': {
        background: 'none',
      },

      //SELECTED
      '&.Mui-selected': {
        background: 'none',
        '& .cell-type1': {
          backgroundColor: '#fff',

          '.dark &': {
            background: '#000',
          },
          '&:before': {
            content: "''",
            width: '1px',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            background: '#A50034',
          },
          '.title': {
            fontWeight: 700,
          },
        },
        '& .cell-type2': {
          backgroundColor: '#A50034',
          borderRadius: '4px',
          '.title': {
            fontWeight: 700,
          },
          '.MuiTypography-root': {
            color: '#fff',
          },
        },
      },
    },
  },

  // Radio Type
  '&[aria-label="radio-list"]': {
    '& .MuiListItem-root': {
      '&.Mui-selected': {
        backgroundColor: '#F7F7F7',

        '.dark &': {
          background: '#1B1B1B',
        },
      },
    },
  },
  '& .MuiListItem-root': {
    padding: '8px 12px',
  },
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  root: {
    '&:hover': {},
  },
  '& .MuiListItemText-root': {
    margin: 0,
  },
  '&.Mui-disabled': {
    '& .MuiTypography-root': {
      color: '#c6c6c6',

      '&.sub-text': {
        color: '#c6c6c6',
      },
    },
  },
  '& .title': {
    fontSize: '14px',
    color: '#525252',
  },
  '& .sub-text': {
    marginTop: '8px',
    fontSize: '12px',
    color: '#909090',
  },
}));
const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '&.MuiFormControlLabel-root': {
    width: '100%',
    margin: 0,
  },
  '& .MuiListItemText-root': {
    '&>.MuiTypography-body1': {
      fontSize: '14px',
      color: '#525252',
    },
    '&>.MuiTypography-body2': {
      fontSize: '12px',
      color: '#bbb',

      '.dark &': {
        color: '#424242',
      },
    },
  },
}));

// List Component
function BasicList({ items, type, cellType, name }) {
  const [selected, setSelected] = useState(items.find((item) => item.checked)?.value || items[0].value);

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  return (
    <StyledList aria-label={type === 'radio' ? 'radio-list' : 'cell-list'}>
      {items.map((item) => (
        <StyledListItem
          key={String(item.id)}
          button={type === 'cell'}
          onClick={type === 'cell' ? () => setSelected(item.id) : undefined}
          selected={selected === (type === 'radio' ? item.value : item.id)}
          divider
          disabled={item.disabled}
        >
          {type === 'radio' ? (
            <StyledFormControlLabel
              control={
                <CustomRadio
                  id={String(item.id)}
                  name={name}
                  checked={selected === item.value}
                  onChange={handleChange}
                  value={item.value}
                  disabled={item.disabled}
                />
              }
              label={<ListItemText primary={item.primary} secondary={item.secondary} />}
            />
          ) : (
            <StyledListItemButton
              onClick={() => setSelected(item.id)}
              disabled={item.disabled}
              className={`${cellType} ${item.disabled ? 'Mui-disabled' : ''}`}
            >
              <ListItemText
                className='list-text'
                primary={<Typography className='title'>{item.title}</Typography>}
                secondary={<Typography className='sub-text'>{item.time}</Typography>}
              />
            </StyledListItemButton>
          )}
        </StyledListItem>
      ))}
    </StyledList>
  );
}

export default BasicList;
