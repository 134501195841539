import React from 'react';
import { Link } from 'react-router-dom';

import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const GnbIcon = styled('span')({
  display: 'block',
  width: '32px',
  height: '32px',
});

const GnbItem = ({ item, drawerOpen, handleListItemClick, handleListSubItemClick, handleFavoriteMenuClick }) => {
  const { id, iconClass, primaryText, collapseOpen, subItems } = item;

  const handleDirection = () => {
    handleListItemClick(id);
  };

  const handleLnb = (subItemId) => {
    handleListSubItemClick(subItemId);
  };

  const handleFavoriteMenu = () => {
    handleFavoriteMenuClick();
  };

  return (
    <>
      <ListItemButton
        component='div'
        className={`dropdown-item ${collapseOpen ? 'selected' : ''}`}
      >
        <ListItemIcon>
          <GnbIcon className={`icon ${iconClass}`} onClick={handleDirection} />
        </ListItemIcon>
        <ListItemText
          primary={primaryText}
          sx={{ opacity: drawerOpen ? 1 : 0 }}
          onClick={handleDirection}
        />
        {primaryText === 'Favorite Menu' ? <span className='icon-setting' onClick={handleFavoriteMenu}/> : <></>}
        <span className='icon-arrow' onClick={handleDirection} />
      </ListItemButton>

      <Collapse in={collapseOpen} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {subItems.map((subItem) => (
            <ListItemButton className={subItem.selected} key={subItem.id} component={Link} to={subItem.path} onClick={() => handleLnb(subItem.id)}>
              <ListItemText
                primary={subItem.text}
                sx={{ display: drawerOpen ? 'block' : 'none' }}
              />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default GnbItem;
