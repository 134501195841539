import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import SelectBox from 'components/component/BasicSelectBox';
import SearchField from 'components/component/BasicSearchField';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import TextField from 'components/component/BasicTextField';
import CustomImage from 'components/component/BasicImage';
import TextLink from 'components/component/BasicTextLink';
import Button from 'components/component/BasicButton';
import CustomBasicMenu from 'components/component/BasicMenu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import CheckBox from 'components/component/BasicCheckBox';
import {
  Box,
  Typography,
  Grid,
  TableSortLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  TypeOptionData,
  cpNameOption,
  OrganizeTh,
  OrganizeTd,
  OrgScheduledTh,
  OrgScheduledTd,
} from './data/InteractiveDockData';
//image
import IconDraggableImg from 'assets/images/icon/ic_handle_24_outline.svg';
import IconDraggableImgDark from 'assets/images/icon/ic_handle_24_outline_darkmode.svg';

import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

//image
import { BtnArrowRight } from 'components/component/BasicIcon';

// Button Data
const ScheduledButtons = [
  {
    index: 1,
    color: 'tertiary',
    type: 'outline',
    size: 'small',
    label: 'Top',
    isIcon: false,
    autosize: true,
    disabled: false,
  },
  {
    index: 2,
    color: 'tertiary',
    type: 'outline',
    size: 'small',
    label: 'Bottom',
    isIcon: false,
    autosize: true,
    disabled: false,
  },

  {
    index: 3,
    color: 'tertiary',
    type: 'outline',
    size: 'small',
    label: 'Delete',
    isIcon: false,
    autosize: true,
    disabled: false,
  },
  {
    index: 4,
    color: 'primary',
    type: 'box',
    size: 'small',
    label: 'Auto Manual Save',
    isIcon: false,
    autosize: true,
    disabled: false,
  },
  {
    index: 5,
    color: 'primary',
    type: 'box',
    size: 'small',
    label: 'Save',
    autosize: true,
  },
];

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

const IconDraggable = styled('span')`
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(${IconDraggableImg});

  .dark & {
    background: url(${IconDraggableImgDark});
  }
`;

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// ************************** 메인 컨텐츠 ***********************************
function InteractiveDockOrganize({ name }) {
  const optionMenu = {
    gnbMenu: 'Promotion',
    lnbMenu: 'Interactive Dock - Recommendation',
    normalMenu: ['Organize'],
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // TableBody Row
  const sortedContentTdRows = sortRows([...OrganizeTd], orderBy, order);
  const [rows, setRows] = useState(() => [...OrgScheduledTd]);
  const selPageData = [{ text: 'Select This Page' }, { text: 'Select All Page' }];

  // Draggable Table
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(rows);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setRows(items);
  };

  return (
    <Box sx={{ display: 'flex' }} className='wrapper'>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Typography component='p' className='title'>
                Organize
              </Typography>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap' component='div'>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  {/* Type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='country-org1'
                      size='large'
                      shrink={true}
                      label='Country'
                      required={false}
                      selectOptions={TypeOptionData}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>

                  {/* CP Name */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='Type-1498'
                      size='large'
                      shrink={true}
                      label='Type'
                      required={false}
                      selectOptions={cpNameOption}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>

                  <Box component='div' className='col'>
                    <TextField
                      id='textfield-id-1'
                      type='outlined'
                      size='large'
                      required={false}
                      placeholder='ID'
                      defaultValue={null}
                      label=''
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                    />
                  </Box>

                  <Box component='div' className='col'>
                    <TextField
                      id='textfield-name-1'
                      type='outlined'
                      size='large'
                      required={false}
                      placeholder='Name'
                      defaultValue={null}
                      label=''
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Grid container columnSpacing={2.5} className='content-pack-area'>
            {/* Content List */}
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Result for Content</Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        20
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Box Content Area */}
                <Box className='box-content'>
                  <Box className='white-box-content-top-area'>
                    <Box className='left-area'>
                      <SearchField
                        id='search-1'
                        placeholder='Please enter a search term'
                        label=''
                        maxWidth='230px'
                        size='medium'
                        required={false}
                        shrink={false}
                      />
                    </Box>
                    <Box className='right-area'>
                      <Button
                        color='tertiary'
                        type='box'
                        size='small'
                        label='Select Ch Input'
                        isIcon={true}
                        autosize
                        Icon={BtnArrowRight}
                        iconPos='right'
                      />
                    </Box>
                  </Box>

                  <Box className='content-inner-wrap'>
                    {/* Table */}
                    <CustomTableContainer className='table-container'>
                      <Table className='custom-table auto' aria-label='table' stickyHeader>
                        {/* table Head */}
                        <TableHead className='thead'>
                          <TableRow className='tr'>
                            {OrganizeTh.map((column, index) => (
                              <TableCell
                                key={column.id}
                                className='th'
                                align={column.align}
                                sx={{ width: column.width }}
                              >
                                {column.checkbox ? (
                                  <Box component='div' sx={{ display: 'flex', gap: '8px' }}>
                                    <Box
                                      component='div'
                                      sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                    >
                                      <CheckBox
                                        label=''
                                        value='select-all'
                                        defaultChecked={false}
                                        inputProps={{
                                          'aria-label': 'select all',
                                        }}
                                        style={{ width: '18px' }}
                                      />
                                      {index === 0 && (
                                        <CustomBasicMenu
                                          type='icon'
                                          title={<ArrowDropDownIcon sx={{ fontSize: '18px', color: '#000' }} />}
                                          options={selPageData}
                                        />
                                      )}
                                    </Box>
                                  </Box>
                                ) : (
                                  <>
                                    {column.sortable ? (
                                      <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'desc'}
                                        onClick={() => handleRequestSort(column.id)}
                                      >
                                        {column.label}
                                      </TableSortLabel>
                                    ) : (
                                      column.label
                                    )}
                                  </>
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        {/* table Body */}
                        <TableBody className='tbody'>
                          {sortedContentTdRows.map((td, index) => (
                            <TableRow className='tr' key={td.id || index}>
                              {OrganizeTh.map((column, colIndex) => (
                                <TableCell key={column.id} className='td' align={column.align}>
                                  {colIndex === 0 ? (
                                    <CheckBox label='' value='value' defaultChecked={false} />
                                  ) : colIndex === 2 ? (
                                    <TextLink href='' text={td[`data0${colIndex + 1}`]} />
                                  ) : colIndex === 4 ? (
                                    <CustomImage
                                      src={td[`data0${colIndex + 1}`]}
                                      fit={false}
                                      alt=''
                                      wrapWidth='96px'
                                      wrapHeight='64px'
                                      rounded
                                    />
                                  ) : (
                                    td[`data0${colIndex + 1}`]
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CustomTableContainer>
                    {/* // Table */}
                  </Box>

                  {/* Pagination */}
                  <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
                </Box>
              </Box>
            </Grid>

            {/* Scheduled Content List */}
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Scheduled Content List</Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        20
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className='box-content'>
                  <Box className='white-box-content-top-area'>
                    <Box className='left-area'>
                      <SearchField
                        id='search-2'
                        label=''
                        size='medium'
                        maxWidth='200px'
                        placeholder='Please enter a search term'
                        required={false}
                        shrink={false}
                      />
                    </Box>
                    <Box className='right-area'>
                      <CustomButtonGroup buttons={ScheduledButtons} justifyContent='center' gap='4px' />
                    </Box>
                  </Box>

                  {/* no-page-number 클래스 추가 하였습니다.  */}
                  <Box className='content-inner-wrap no-page-number'>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId='droppable-table'>
                        {(provided) => (
                          <CustomTableContainer
                            className='table-container'
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            <Table className='custom-table auto' aria-label='table' stickyHeader>
                              {/* table Head */}
                              <TableHead className='thead'>
                                <TableRow className='tr'>
                                  {OrgScheduledTh.map((column, index) => (
                                    <TableCell
                                      key={column.id}
                                      className='th'
                                      align={column.align}
                                      sx={{ width: column.width }}
                                    >
                                      {column.checkbox ? (
                                        <Box component='div' sx={{ display: 'flex', gap: '8px' }}>
                                          <Box
                                            component='div'
                                            ml='29px'
                                            sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                          >
                                            <CheckBox
                                              label=''
                                              value='select-all'
                                              defaultChecked={false}
                                              inputProps={{
                                                'aria-label': 'select all',
                                              }}
                                              style={{ width: '18px' }}
                                            />
                                            {index === 0 && (
                                              <CustomBasicMenu
                                                type='icon'
                                                title={<ArrowDropDownIcon sx={{ fontSize: '18px', color: '#000' }} />}
                                                options={selPageData}
                                              />
                                            )}
                                          </Box>
                                        </Box>
                                      ) : (
                                        <>
                                          {column.sortable ? (
                                            <TableSortLabel
                                              active={orderBy === column.id}
                                              direction={orderBy === column.id ? order : 'desc'}
                                              onClick={() => handleRequestSort(column.id)}
                                            >
                                              {column.label}
                                            </TableSortLabel>
                                          ) : (
                                            column.label
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>

                              {/* table Body */}

                              <TableBody className='tbody'>
                                {rows.map((td, index) => (
                                  <Draggable key={td.id || index} draggableId={td.id || index.toString()} index={index}>
                                    {(provided) => (
                                      <TableRow
                                        className='tr'
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        {OrgScheduledTh.map((column, colIndex) => (
                                          <TableCell
                                            key={column.id}
                                            className='td'
                                            align={column.align}
                                            sx={{ height: '88px' }}
                                          >
                                            {colIndex === 0 ? (
                                              <Box display='flex' alignItems='center' {...provided.dragHandleProps}>
                                                <IconDraggable className='icon-draggable' />

                                                <CheckBox
                                                  label={td[`data0${colIndex + 1}`]}
                                                  value='value'
                                                  defaultChecked={false}
                                                />
                                              </Box>
                                            ) : colIndex === 3 ? (
                                              <TextLink href='' text={td[`data0${colIndex + 1}`]} />
                                            ) : colIndex === 5 ? (
                                              <CustomImage
                                                src={td[`data0${colIndex + 1}`]}
                                                fit={false}
                                                alt=''
                                                wrapWidth='64px'
                                                wrapHeight='64px'
                                                rounded
                                              />
                                            ) : (
                                              td[`data0${colIndex + 1}`]
                                            )}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    )}
                                  </Draggable>
                                ))}
                                {provided?.placeholder}
                              </TableBody>
                            </Table>
                          </CustomTableContainer>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
export default InteractiveDockOrganize;
