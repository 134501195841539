import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import BasicSwitches from 'components/component/BasicSwitch';
import TextLink from 'components/component/BasicTextLink';
import SearchField from 'components/component/BasicSearchField';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import Tooltip from 'components/component/BasicTooltip';
import TextField from 'components/component/BasicTextField';
import CustomImage from 'components/component/BasicImage';
import CustomBasicMenu from 'components/component/BasicMenu';
import DatePicker from 'components/component/BasicDatePicker';
import SelectBox from 'components/component/BasicSelectBox';
import CheckBox from 'components/component/BasicCheckBox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Link,
  Grid,
  ListItem,
  List,
  Divider,
  TableSortLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// ICON
import { BtnArrowRight } from 'components/component/BasicIcon';

// Data
import {
  CountryData,
  FreeOnTypeOption,
  FreeOnStatusOptioin,
  cpNameOption,
  FreeonResultTh,
  FreeonResultTd,
  SelectedContentTh,
  SelectedContentTd,
} from './data/FreeOnData';

//image
import IconDraggableImg from 'assets/images/icon/ic_handle_24_outline.svg';
import IconDraggableImgDark from 'assets/images/icon/ic_handle_24_outline_darkmode.svg';

// Button Data
const ScheduledButtons = [
  {
    index: 1,
    color: 'tertiary',
    type: 'outline',
    size: 'small',
    label: 'Top',
    isIcon: false,
    autosize: true,
    disabled: false,
  },
  {
    index: 2,
    color: 'tertiary',
    type: 'outline',
    size: 'small',
    label: 'Bottom',
    isIcon: false,
    autosize: true,
    disabled: false,
  },
  {
    index: 3,
    color: 'tertiary',
    type: 'outline',
    size: 'small',
    label: 'Download',
    isIcon: false,
    autosize: true,
    disabled: true,
  },
  {
    index: 4,
    color: 'tertiary',
    type: 'outline',
    size: 'small',
    label: 'Delete',
    isIcon: false,
    autosize: true,
    disabled: true,
  },
  {
    index: 5,
    color: 'tertiary',
    type: 'box',
    size: 'small',
    label: 'History',
    isIcon: false,
    autosize: true,
    disabled: false,
  },
  {
    index: 6,
    color: 'primary',
    type: 'box',
    size: 'small',
    label: 'Save',
    autosize: true,
  },
  {
    index: 7,
    color: 'primary',
    type: 'box',
    size: 'small',
    label: 'Testing',
    autosize: true,
  },
];

// Styled
const IconDraggable = styled('span')`
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(${IconDraggableImg});

  .dark & {
    background: url(${IconDraggableImgDark});
  }
`;

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// 메인 컨텐츠
function FreeOnLGChannelsShelf() {
  // BreadCrumb
  const optionMenu = {
    gnbMenu: 'Promotion',
    lnbMenu: 'Free On LG Channels Shelf',
  };

  const infos = [
    {
      index: 1,
      label: '#Current Time ',
      value: '2024-07-29 02:06:30',
      divide: true,
    },
    {
      index: 2,
      label: '#Contents Preparing Batch Time',
      value: '2024-07-29 09:00:00',
      divide: true,
    },
    {
      index: 3,
      label: '#Contents Displaying Batch Time',
      value: '2024-07-29 13:00:00',
      divide: false,
    },
  ];

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };
  // Table Body Data
  const sortedContentTdRows = sortRows([...FreeonResultTd], orderBy, order);
  const [rows, setRows] = useState([...SelectedContentTd]);

  // Draggable Table
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(rows);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setRows(items);
  };

  const [isSwitchOn, setIsSwitchOn] = useState(false);

  // Recommend Month Select box
  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const selPageData = [{ text: 'Select This Page' }, { text: 'Select All Page' }];
  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };

  // Scheduled Content List영역
  // eslint-disable-next-line no-unused-vars
  const [noData, setNoData] = useState(false);

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container category-organize-container' component='main' sx={{ flexGrow: 1 }}>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href=''>
                Free On LG Channels shelf
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap program-detail' component='div'>
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                {infos.map((item) => (
                  <React.Fragment key={item.index}>
                    <ListItem className='list-item'>
                      <Typography className='label'>{item.label}</Typography>
                      <Typography className='value'>{item.value}</Typography>
                    </ListItem>
                    {item.divide && <Divider className='divider' />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
            <Box className='right-area'></Box>
          </Box>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  {/* Country */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Country'
                      required={false}
                      selectOptions={CountryData}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>
                  {/* Type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='slt-Slider'
                      size='large'
                      shrink={true}
                      label='Type'
                      required={false}
                      selectOptions={FreeOnTypeOption}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>
                  {/* CP Name */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='slt-Name'
                      size='large'
                      shrink={true}
                      label='CP Name'
                      required={false}
                      selectOptions={cpNameOption}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>
                  {/* Status */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='slt-Content'
                      size='large'
                      shrink={true}
                      label='Status'
                      required={false}
                      selectOptions={FreeOnStatusOptioin}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>

                  {/* Month Picker */}
                  <Box component='div' className='col'>
                    <DatePicker
                      views={['year', 'month']}
                      className='input-date-picker'
                      format='YYYY/MM'
                      size='large'
                      shrink
                      label='Recommend Month'
                      value={selectedDate}
                      onChange={handleDateChange}
                      singleDate={true}
                      slots={{
                        TextField: (params) => <TextField {...params} size='large' />,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <div className='form-area right'></div>
            </Box>
          </Box>

          <Grid container columnSpacing={2.5} className='content-pack-area'>
            {/* Content List */}
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Result for Content</Typography>
                      <Typography component='p' className='select-count-wrap'>
                        Select <span className='set-color'>5</span> item
                      </Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        20
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Box Content Area */}
                <Box className='box-content'>
                  <Box className='white-box-content-top-area'>
                    <Box className='left-area'>
                      <SearchField
                        id='search-1'
                        placeholder='Please enter a search term'
                        label=''
                        maxWidth='230px'
                        size='medium'
                        required={false}
                        shrink={false}
                      />
                    </Box>
                    <Box className='right-area'>
                      <Button
                        color='tertiary'
                        type='box'
                        size='small'
                        label='Select Ch Input'
                        isIcon={true}
                        autosize
                        Icon={BtnArrowRight}
                        iconPos='right'
                      />
                    </Box>
                  </Box>

                  <Box className='content-inner-wrap'>
                    {/* Table */}
                    <CustomTableContainer className='table-container'>
                      <Table className='custom-table' aria-label='table' stickyHeader>
                        {/* table Head */}
                        <TableHead className='thead'>
                          <TableRow className='tr'>
                            {FreeonResultTh.map((column, index) => (
                              <TableCell
                                key={column.id}
                                className='th'
                                align={column.align}
                                sx={{ width: column.width }}
                              >
                                {column.checkbox ? (
                                  <Box component='div' sx={{ display: 'flex', gap: '8px' }}>
                                    <Box
                                      component='div'
                                      sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                    >
                                      <CheckBox
                                        label=''
                                        value='select-all'
                                        defaultChecked={false}
                                        inputProps={{
                                          'aria-label': 'select all',
                                        }}
                                        style={{ width: '24px' }}
                                      />
                                      {index === 0 && (
                                        <CustomBasicMenu
                                          type='icon'
                                          title={<ArrowDropDownIcon sx={{ fontSize: '18px', color: '#000' }} />}
                                          options={selPageData}
                                        />
                                      )}
                                    </Box>
                                    <TableSortLabel
                                      active={orderBy === column.id}
                                      direction={orderBy === column.id ? order : 'desc'}
                                      onClick={() => handleRequestSort(column.id)}
                                    >
                                      {column.label}
                                    </TableSortLabel>
                                  </Box>
                                ) : (
                                  <>
                                    {column.sortable ? (
                                      <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'desc'}
                                        onClick={() => handleRequestSort(column.id)}
                                      >
                                        {column.label}
                                      </TableSortLabel>
                                    ) : (
                                      column.label
                                    )}
                                  </>
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        {/* table Body */}
                        <TableBody className='tbody'>
                          {sortedContentTdRows.map((td, index) => (
                            <TableRow className='tr' key={td.id || index}>
                              {FreeonResultTh.map((column, colIndex) => (
                                <TableCell key={column.id} className='td' align={column.align}>
                                  {colIndex === 0 ? (
                                    <CheckBox label={td[`data0${colIndex + 1}`]} value='value' defaultChecked={false} />
                                  ) : colIndex === 1 ? (
                                    <TextLink href='' text={td[`data0${colIndex + 1}`]} />
                                  ) : colIndex === 3 ? (
                                    <CustomImage
                                      src={td[`data0${colIndex + 1}`]}
                                      fit={false}
                                      alt=''
                                      wrapWidth='55px'
                                      wrapHeight='64px'
                                      rounded
                                    />
                                  ) : colIndex === 4 ? (
                                    td.tooltip ? (
                                      <div className='td-tooltip-wrap'>
                                        <Tooltip
                                          title='Genre : Crime, Biography'
                                          placement='top'
                                          className='tooltip-wrap'
                                        >
                                          <Box display='flex'>
                                            <span className='tooltip-text'>{td[`data0${colIndex + 1}`]}</span>
                                            <span className='tooltip-icon'></span>
                                          </Box>
                                        </Tooltip>
                                      </div>
                                    ) : (
                                      <span>{td[`data0${colIndex + 1}`]}</span>
                                    )
                                  ) : (
                                    td[`data0${colIndex + 1}`]
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CustomTableContainer>
                    {/* // Table */}
                  </Box>

                  <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
                </Box>
              </Box>
            </Grid>

            {/* Scheduled Content List */}
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Scheduled Content List</Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        20
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className='box-content'>
                  <Box className='white-box-content-top-area'>
                    <Box className='left-area'>
                      <SearchField
                        id='search-2'
                        label=''
                        size='medium'
                        width='120px'
                        placeholder='Please enter a search term'
                        required={false}
                        shrink={false}
                      />
                    </Box>
                    <Box className='right-area'>
                      <CustomButtonGroup buttons={ScheduledButtons} justifyContent='center' style={{ gap: '4px' }} />
                    </Box>
                  </Box>

                  {/* Selected Content */}
                  {/* 241011 no-page-number 클래스 추가하였습니다. */}
                  <Box className='content-inner-wrap no-page-number'>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId='droppable-table'>
                        {(provided) => (
                          <CustomTableContainer
                            className='table-container'
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            <Table className='custom-table auto' aria-label='table' stickyHeader>
                              {/* table Head */}
                              <TableHead className='thead'>
                                <TableRow className='tr'>
                                  {SelectedContentTh.map((column, index) => (
                                    <TableCell
                                      key={column.id}
                                      className='th'
                                      align={column.align}
                                      sx={{ width: column.width }}
                                    >
                                      {column.checkbox ? (
                                        <Box component='div' sx={{ display: 'flex', gap: '8px' }}>
                                          <Box
                                            component='div'
                                            sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                          >
                                            <CheckBox
                                              label=''
                                              value='select-all'
                                              defaultChecked={false}
                                              inputProps={{
                                                'aria-label': 'select all',
                                              }}
                                              style={{ width: '24px' }}
                                            />
                                            {index === 0 && (
                                              <CustomBasicMenu
                                                type='icon'
                                                title={<ArrowDropDownIcon sx={{ fontSize: '18px', color: '#000' }} />}
                                                options={selPageData}
                                              />
                                            )}
                                          </Box>
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'desc'}
                                            onClick={() => handleRequestSort(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        </Box>
                                      ) : (
                                        <>
                                          {column.sortable ? (
                                            <TableSortLabel
                                              active={orderBy === column.id}
                                              direction={orderBy === column.id ? order : 'desc'}
                                              onClick={() => handleRequestSort(column.id)}
                                            >
                                              {column.label}
                                            </TableSortLabel>
                                          ) : (
                                            column.label
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>

                              {/* table Body */}

                              <TableBody className='tbody'>
                                {noData ? (
                                  <TableRow>
                                    <TableCell
                                      className={noData ? 'noData' : ''}
                                      colSpan={SelectedContentTh.length}
                                      align='center'
                                    >
                                      <Box className='no-data-area'>
                                        <Typography className='text'>
                                          Expose in order of popularity when not organized
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                ) : (
                                  rows.map((td, index) => (
                                    <Draggable
                                      key={td.id || index}
                                      draggableId={td.id || index.toString()}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <TableRow
                                          className='tr'
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          {SelectedContentTh.map((column, colIndex) => (
                                            <TableCell
                                              key={column.id}
                                              className='td'
                                              align={column.align}
                                              sx={{ height: '88px' }}
                                            >
                                              {colIndex === 0 ? (
                                                <Box display='flex' alignItems='center' {...provided.dragHandleProps}>
                                                  <IconDraggable className='icon-draggable' />
                                                  <CheckBox
                                                    label={td[`data0${colIndex + 1}`]}
                                                    value='value'
                                                    defaultChecked={false}
                                                  />
                                                </Box>
                                              ) : colIndex === 3 ? (
                                                <TextLink href='' text={td[`data0${colIndex + 1}`]} />
                                              ) : colIndex === 5 ? (
                                                <BasicSwitches
                                                  disabled={false}
                                                  onOffMode={isSwitchOn}
                                                  setSwitchOnOff={setIsSwitchOn}
                                                  isLabelText={false}
                                                  onText=''
                                                  offText=''
                                                />
                                              ) : colIndex === 6 ? (
                                                <CustomImage
                                                  src={td[`data0${colIndex + 1}`]}
                                                  fit={false}
                                                  alt=''
                                                  wrapWidth='64px'
                                                  wrapHeight='56px'
                                                  rounded
                                                />
                                              ) : colIndex === 7 ? (
                                                Array.isArray(td[`data0${colIndex + 1}`]) &&
                                                td[`data0${colIndex + 1}`][1]?.tooltip ? (
                                                  <div className='td-tooltip-wrap'>
                                                    <Tooltip
                                                      title={`Genre : ${td[`data0${colIndex + 1}`][0]?.text || ''}`}
                                                      placement='top'
                                                      className='tooltip-wrap'
                                                    >
                                                      <Box display='flex'>
                                                        <Typography
                                                          component='span'
                                                          className='tooltip-text'
                                                          sx={{ whiteSpace: 'nowrap' }}
                                                        >
                                                          {td[`data0${colIndex + 1}`][0]?.text || ''}
                                                        </Typography>
                                                        <span className='tooltip-icon'></span>
                                                      </Box>
                                                    </Tooltip>
                                                  </div>
                                                ) : (
                                                  <span>{td[`data0${colIndex + 1}`][0]?.text || ''}</span>
                                                )
                                              ) : (
                                                td[`data0${colIndex + 1}`]
                                              )}
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      )}
                                    </Draggable>
                                  ))
                                )}
                                {provided?.placeholder}
                              </TableBody>
                            </Table>
                          </CustomTableContainer>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default FreeOnLGChannelsShelf;
