import * as React from 'react';
import { Button as BaseButton, buttonClasses } from '@mui/base/Button';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';

// Button Component
function BasicButton({
  label,
  type = 'main',
  size = 'medium',
  color,
  iconPos,
  isIcon = false,
  Icon = null,
  autosize = false, //버튼 최소넓이 해제 (default: minWidth: 150px)
  disabled,
  fullsize,
  sx,
  onClick,
  href,
  ...restProps
}) {
  const btnType = [
    type,
    color,
    size,
    isIcon ? iconPos : false,
    fullsize ? 'fullsize' : '',
    ...Object.values(restProps),
  ].join(' ');

  return (
    <ButtonStyles
      className={`${btnType} ${autosize ? 'autosize' : ''}`}
      disabled={disabled}
      sx={{ ...sx }}
      onClick={onClick}
      href={href}
    >
      {isIcon && Icon && (
        <span className='icon-img'>
          <Icon />
        </span>
      )}
      <span className='text'>{label}</span>
    </ButtonStyles>
  );
}

BasicButton.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  iconPos: PropTypes.oneOf(['left', 'right', 'center']),
  isIcon: PropTypes.bool,
  Icon: PropTypes.elementType,
  disabled: PropTypes.bool,
  fullsize: PropTypes.bool,
  sx: PropTypes.object,
};

// Styled
const ButtonStyles = styled(BaseButton)(
  ({ theme, sx }) => `
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  border: 0;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  outline: none;
  padding:0 22px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);

  .text {
    font-size: 16px;
    font-weight: 400;
    color: #fff ;
    text-transform: capitalize;
    white-space: nowrap;
  }
  &.large {
    height: 52px;

    .text {
      font-size: 18px;
      font-weight: 700;
    }
  }
  &.medium {
    height: 40px;
    .text {
      font-size: 14px;
      font-weight: 700;
    }
  }
  &.small {
    height: 32px;
    .text {
      font-size: 12px;
      font-weight: 400;
    }
  }
  &.xsmall {
    height: 28px;

    .text {
      font-size: 12px;
      font-weight: 400;
    }
  }

  &.bold {
    .text {
      font-weight: 700
    }
  }
  &.fullsize {
    width: 100%;
  }
  &.autosize {
    min-width: auto;
  }
  &.base--disabled {
    img {
      filter: brightness(0.75);
    }
  }
  &.outline {
      &:hover{
      .icon-img {
        opacity:0.5
      }
    }
  }
  &.icon-text{
    background: none;
    &.left{
      .icon-img{
        margin-right: 4px;
      }
      .text {
        font-size: 12px;
        color: #525252;

        .dark & {
         color: #808080
        }
      }
    }
    &:hover {
      .text{
        color: #000
      }
    }
  }
  &.only-icon {
    min-width: auto;
    padding: 8px;

    &.small {
      padding: 4px;
    }

    .icon-img{
      margin-right: 0;
    }
  }
  .icon-img{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
  }
  &:disabled {
    background-color: #E2E2E2 !important;
    border: 0;
    .text {
      color: #C6C6C6 !important;

      .dark & {
        color: rgba(198, 198, 198, 0.2) !important;
      }
    }
    &.left {
      border-color: #D9D9D9 !important;
    }

    .icon-imgb {
      img {
        filter: brightness(0.8);
      }
    }

    .dark & {
      background-color: rgba(226, 226, 226, 0.2) !important;
      border-color: rgba(217, 217, 217, 0.1) !important;
    }
  }
  &.left {
    .icon-img{
      margin-right: 8px;
    }
      &.only-icon {
        .icon-img{
          margin-right: 0;
        }
      }
  }
  &.right {
    flex-direction: row-reverse;
    .icon-img{
      margin-left: 8px;
    }
    .material-icons {
      margin-left: 8px;
      margin-right: 0;
    }
  },

  &.primary {
    background-color: #A50034;
    box-shadow: 0px 2px 4px 0px rgba(165, 0, 52, 0.15);

    &:hover {
      background-color: #EF034E;
    }
    &:active {
      background-color: #86002A;
    }
    &.outline {
      background-color: #fff;
      border: 1px solid #A50034;

      .text {
        color: #A50034;
      }

      .dark &{
        display: none;
        background-color: transparent;
      }
      &:hover {
        background-color: #fff;
        border:1px solid #EF034E;
        .text {
          color: #EF034E;
        }

        .dark & {
          background-color: transparent;
        }
      }
      &:active {
        border:1px solid #86002A;
        .text {
          color: #86002A;
        }
      }
      &.left {
        &:active {
          border:1px solid #86002A;
          .text {
            color: #86002A;
          }
        }
      }
      &.right {
        &:active {
          border:1px solid #86002A;
          .text {
            color: #86002A;
          }
        }
      }
      &:disabled {
        border: 1px solid #D9D9D9;

        .text {
          color: #C6C6C6;
        }
      }
    }
  }
  &.secondary {
    background-color: #000;

    &:hover {
      background-color: #989898;
      .text {
        color: #fff;
      }
      .dark & {
        background: #989898;
      }
    }
    &:active {
      background-color: #000000;
    }
    &.outline {
      background-color: #fff;
      border: 1px solid #000;

      .text {
        color: #000;
      }
      .dark &{
        display: none;
        border: 1px solid #000;
        background-color: transparent;

        .text {
          color: #000;
        }
        &:hover {
          background-color: transparent;
        }
      }
      &:hover {
        background-color: #fff;
        border: 1px solid #989898;
        .text {
          color: #989898;
        }
      }
      &:active {
        border:1px solid #000;
        .text {
          color: #000;
        }
      }
      &.left {
        &:active {
          border:1px solid #000;
          .text {
            color: #000;
          }
        }
      }
      &.right {
        &:active {
          border:1px solid #000;
          .text {
            color: #000;
          }
        }
      }
      &:disabled {
        border: 1px solid #D9D9D9;

        .text {
          color: #C6C6C6;
        }
      }
    }
  }
  &.tertiary {
    background-color: #ABB6C2;
    .dark &{
      background: #424D58
    }
    &:hover {
      background-color: #CAD3DD;
      .text {
        color: #fff;
      }

      .dark & {
        background: #677787;
      }
    }
    &.outline {
      background-color: #fff;
      border: 1px solid #737F90;

      .dark & {
        background-color: transparent;
        border-color: rgba(115, 127, 144, 0.4)
      }
      .text {
        color: #737F90;

        .dark & {
          color: #B2BBC9;
        }
      }
      &:hover {
        background-color: #fff;
        border: 1px solid #A9B2BF;
        .text {
          color: #A9B2BF;
        }
        .dark & {
          background-color: transparent;
          border-color: #A9B2BF
        }
      }
      &:active {
        border:1px solid #88919D;
        .text {
          color: #88919D;
        }
      }
      &.left {
        &:active {
          border:1px solid #88919D;
          .text {
            color: #88919D;
          }
        }
      }
      &.right {
        &:active {
          border:1px solid #88919D;
          .text {
            color: #88919D;
          }
        }
      }
      &:disabled {
        border: 1px solid #D9D9D9;

        .text {
          color: #C6C6C6;
        }

        &:active {
          border:1px solid #D9D9D9;
          .text {
            color: #C6C6C6;
          }
        }
      }
    }
  }

  &.outline {
    &.icon {
      &.medium {
        height: 52px;
        padding: 12px;
      }

      &.medium {
        height: 40px;
        padding: 8px;
      }

      &.small {
        height: 32px;
        padding: 4px;
      }
    }
  }

  &.${buttonClasses.focusVisible} {
    outline: none;
  }

  `
);

export default BasicButton;
