import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import SelectBox from 'components/component/BasicSelectBox';
import TableSortLabel from '@mui/material/TableSortLabel';
import CustomButtonGroup from 'components/component/BasicButtonGroup';

import SearchField from 'components/component/BasicSearchField';
import TextLink from 'components/component/BasicTextLink';
import Button from 'components/component/BasicButton';
import Tooltip from 'components/component/BasicTooltip';

import CheckBox from 'components/component/BasicCheckBox';
import { Box, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { ToggleIcon } from 'components/component/BasicIcon';

import { styled } from '@mui/material/styles';

// data for filter
import {
  DeviceTypeData,
  Region,
  PlatformVersionData,
  statusData,
} from 'pages/Organization/WebService/data/OrganizationData';

// data for Table
import {
  TabCategoryListTh,
  TabCategoryListTd,
  selectOption,
} from 'pages/Organization/WebService/data/CategoryListData';

const Buttons = [
  {
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    label: 'Delete',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'box',
    size: 'medium',
    label: 'History',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'box',
    size: 'medium',
    label: 'Create Tab',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    color: 'primary',
    type: 'box',
    size: 'medium',
    label: 'Save',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'box',
    size: 'medium',
    label: 'Testing',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
];

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// 메인 컨텐츠
function TabCategoryList() {
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Tab/Category',
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // TableBody Row
  const sortedBnrTdRows = sortRows([...TabCategoryListTd], orderBy, order);

  return (
    <Box sx={{ display: 'flex' }} className='wrapper'>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href=''>
                Tab / Category
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap category-list' component='div'>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  {/* Device Type */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Device Type'
                      required={false}
                      selectOptions={DeviceTypeData}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>
                  {/* region */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Region'
                      required={false}
                      selectOptions={Region}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>
                  {/* Platform Version */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Platform Version'
                      required={false}
                      selectOptions={PlatformVersionData}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>
                  {/* status */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Status'
                      required={false}
                      selectOptions={statusData}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap'>
            <div className='box-content'>
              <Box className='white-box-content-top-area' alignItems='flex-end'>
                {/* left-area */}
                <Box className='left-area column'>
                  <Box className='total-count'>
                    <Box component='span' className='label'>
                      Total
                    </Box>
                    <Box component='span' className='value'>
                      6
                    </Box>
                  </Box>
                  <Box display='flex' gap={1}>
                    <SelectBox
                      id='select-search'
                      size='medium'
                      selectOptions={selectOption}
                      label=''
                      maxWidth='180px'
                      disabled={false}
                      placeholder=''
                    />

                    <SearchField placeholder='Please enter a search term' maxWidth='230px' />
                  </Box>
                </Box>
                <Box className='right-area'>
                  <CustomButtonGroup buttons={Buttons} />
                  <Tooltip title='Copy Home-App Data' placement='top' arrow className='btn-tooltip'>
                    <Box sx={{ marginLeft: '8px' }}>
                      <Button
                        color=''
                        type='only-icon'
                        size='medium'
                        label=''
                        isIcon={true}
                        Icon={ToggleIcon}
                        autosize
                      />
                    </Box>
                  </Tooltip>
                </Box>
                {/* right-area */}
              </Box>

              {/* 241011 no-page-number 클래스 추가하였습니다. */}
              <Box className='content-inner-wrap  no-page-number'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {TabCategoryListTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  defaultChecked={false}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedBnrTdRows.map((td, index) => (
                        <TableRow className='tr' key={td.id || index}>
                          {TabCategoryListTh.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {colIndex === 0 ? (
                                <CheckBox label={td[`data0${colIndex + 1}`]} value='value' defaultChecked={false} />
                              ) : colIndex === 2 ? (
                                <TextLink href='' text={td[`data0${colIndex + 1}`]} />
                              ) : colIndex === 8 ? (
                                <Button
                                  color='tertiary'
                                  type='outline'
                                  size='small'
                                  label='Category'
                                  isIcon={false}
                                  Icon=''
                                  iconPos={undefined}
                                  autosize
                                />
                              ) : (
                                td[`data0${colIndex + 1}`]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default TabCategoryList;
