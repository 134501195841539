// src/data.js
import imgDummy01 from 'assets/images/No-Image_378.png';

export const CategoryTableTh = [
  {
    id: 'th01',
    label: 'Category Order',
    align: 'left',
    width: '12%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'th02',
    label: 'Category Code',
    align: 'left',
    width: '13%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'th03',
    label: 'Name',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'th04',
    label: 'Content Type',
    align: 'left',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'th05',
    label: 'Enable',
    align: 'left',
    width: '12%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'th06',
    label: 'Action',
    align: 'left',
    width: '12%',
    checkbox: false,
    sortable: false,
  },
];

export const CategoryTableTd = [
  {
    id: 'td-1',
    data01: '1',
    data02: 'A',
    data03: 'Web Live_09',
    data04: 'Channel',
    data05: 'On',
    data06: '',
  },
  {
    id: 'td-2',
    data01: '2',
    data02: 'B',
    data03: 'Web Live',
    data04: 'Channel',
    data05: 'Off',
    data06: '',
  },
  {
    id: 'td-3',
    data01: '3',
    data02: 'A',
    data03: 'Web Live_21',
    data04: 'Channel',
    data05: 'On',
    data06: '',
  },
];

export const CategoryVodManualTd = [
  {
    id: 'td-1',
    data01: '1',
    data02: 'A',
    data03: '추천_VOD',
    data04: 'VOD',
    data05: 'On',
    data06: '',
  },
  {
    id: 'td-2',
    data01: '2',
    data02: 'B',
    data03: 'Web Live',
    data04: 'VOD',
    data05: 'Off',
    data06: '',
  },
];

export const CategoryListTableTh = [
  {
    id: 'CategoryList-1',
    label: 'Category Order',
    align: 'left',
    width: '15%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'CategoryList-2',
    label: 'Category Code',
    align: 'left',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryList-3',
    label: 'Name',
    align: 'left',
    width: '28%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryList-4',
    label: 'Preview',
    align: 'left',
    width: '32%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryList-5',
    label: 'Enable',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
];

export const CategoryListTableTd = [
  {
    id: 'CategoryListTd-1',
    data01: '1',
    data02: 'Branding A',
    data03: 'Section1_US',
    data04: 'Channel',
    data05: 'Off',
  },
  {
    id: 'CategoryListTd-2',
    data01: '1',
    data02: 'Branding A',
    data03: 'Section1_US_10',
    data04: 'Channel',
    data05: 'On',
  },
  {
    id: 'CategoryListTd-3',
    data01: '1',
    data02: 'Branding A',
    data03: 'Section1_US',
    data04: 'Channel',
    data05: 'On',
  },
];

export const CategoryListBannerTh = [
  {
    id: 'CategoryListBannerTh-1',
    label: 'Category Order',
    align: 'left',
    width: '14%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'CategoryListBannerTh-2',
    label: 'Category Code',
    align: 'left',
    width: '13%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryListBannerTh-3',
    label: 'Name',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryListBannerTh-4',
    label: 'Start Date',
    align: 'left',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryListBannerTh-5',
    label: 'End Date',
    align: 'left',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryListBannerTh-6',
    label: 'Enable',
    align: 'left',
    width: '11%',
    checkbox: false,
    sortable: false,
  },
];

export const CategoryListBannerTd = [
  {
    id: 'CategoryListBannerTd-1',
    data01: '1',
    data02: 'A',
    data03: '인사우스 배너1',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
    data06: 'On',
  },
  {
    id: 'CategoryListBannerTd-2',
    data01: '2',
    data02: 'B',
    data03: '인사우스 배너2',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
    data06: 'On',
  },
  {
    id: 'CategoryListBannerTd-3',
    data01: '3',
    data02: 'C',
    data03: '미국 배너1',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
    data06: 'On',
  },
  {
    id: 'CategoryListBannerTd-4',
    data01: '4',
    data02: 'D',
    data03: '미국 홍보용 배너1',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
    data06: 'On',
  },
  {
    id: 'CategoryListBannerTd-5',
    data01: '5',
    data02: 'E',
    data03: '미국 홍보용 배너2',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
    data06: 'On',
  },
  {
    id: 'CategoryListBannerTd-6',
    data01: '6',
    data02: 'C',
    data03: '미국 배너1',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
    data06: 'On',
  },
  {
    id: 'CategoryListBannerTd-7',
    data01: '7',
    data02: 'D',
    data03: '미국 홍보용 배너1',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
    data06: 'On',
  },
  {
    id: 'CategoryListBannerTd-8',
    data01: '8',
    data02: 'E',
    data03: '미국 홍보용 배너2',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
    data06: 'On',
  },
  {
    id: 'CategoryListBannerTd-9',
    data01: '9',
    data02: 'C',
    data03: '미국 배너1',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
    data06: 'On',
  },
  {
    id: 'CategoryListBannerTd-10',
    data01: '10',
    data02: 'D',
    data03: '미국 홍보용 배너1',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
    data06: 'On',
  },
  {
    id: 'CategoryListBannerTd-11',
    data01: '11',
    data02: 'E',
    data03: '미국 홍보용 배너2',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
    data06: 'On',
  },
  {
    id: 'CategoryListBannerTd-12',
    data01: '12',
    data02: 'C',
    data03: '미국 배너1',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
    data06: 'On',
  },
  {
    id: 'CategoryListBannerTd-13',
    data01: '13',
    data02: 'D',
    data03: '미국 홍보용 배너1',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
    data06: 'On',
  },
  {
    id: 'CategoryListBannerTd-14',
    data01: '14',
    data02: 'E',
    data03: '미국 홍보용 배너2',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
    data06: 'On',
  },
  {
    id: 'CategoryListBannerTd-15',
    data01: '15',
    data02: 'C',
    data03: '미국 배너1',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
    data06: 'On',
  },
  {
    id: 'CategoryListBannerTd-16',
    data01: '16',
    data02: 'D',
    data03: '미국 홍보용 배너1',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
    data06: 'On',
  },
  {
    id: 'CategoryListBannerTd-17',
    data01: '17',
    data02: 'E',
    data03: '미국 홍보용 배너2',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
    data06: 'On',
  },
];

export const CategoryListBannerOrderTh = [
  {
    id: 'CategoryListBannerTh-1',
    label: 'Category Order',
    align: 'left',
    width: '14%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'CategoryListBannerTh-2',
    label: 'Category Code',
    align: 'left',
    width: '13%',
    checkbox: false,
    sortable: true,
  },
];

export const BannerOrderTh = [
  {
    id: 'CategoryListBannerTh-1',
    label: 'Category Order',
    align: 'left',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryListBannerTh-2',
    label: 'Category Code',
    align: 'left',
    width: '13%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryListBannerTh-3',
    label: 'Name',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryListBannerTh-4',
    label: 'Start Date',
    align: 'left',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryListBannerTh-5',
    label: 'End Date',
    align: 'left',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
];
export const OrderBannerTd = [
  {
    id: 'OrderBannerTd-1',
    data01: '1',
    data02: 'A',
    data03: '인사우스 배너1',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
  },
  {
    id: 'OrderBannerTd-2',
    data01: '2',
    data02: 'B',
    data03: '인사우스 배너2',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
  },
  {
    id: 'OrderBannerTd-3',
    data01: '3',
    data02: 'C',
    data03: '미국 배너1',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
  },
  {
    id: 'OrderBannerTd-4',
    data01: '4',
    data02: 'D',
    data03: '미국 홍보용 배너1',
    data04: '2024. 01. 01',
    data05: '2024. 05. 01',
  },
];

export const selectOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const TabCategoryListTh = [
  {
    id: 'TabCategoryListTh-1',
    label: 'Tab Order',
    align: 'left',
    width: '10%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'TabCategoryListTh-2',
    label: 'Tab Code',
    align: 'left',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TabCategoryListTh-3',
    label: 'Name',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TabCategoryListTh-4',
    label: 'Location Type',
    align: 'left',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TabCategoryListTh-5',
    label: 'Skeleton Type',
    align: 'left',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TabCategoryListTh-6',
    label: 'Theme Apply',
    align: 'left',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TabCategoryListTh-7',
    label: 'Random',
    align: 'left',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TabCategoryListTh-8',
    label: 'Enable',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'TabCategoryListTh-9',
    label: 'Action',
    align: 'left',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
];

export const TabCategoryListTd = [
  {
    id: 'TabCategoryListTd-1',
    data01: '1',
    data02: 'T-MY',
    data03: 'MY',
    data04: 'Top',
    data05: 'My',
    data06: 'Off',
    data07: '-',
    data08: 'On',
    data09: '',
  },
  {
    id: 'TabCategoryListTd-2',
    data01: '2',
    data02: 'T-MY',
    data03: 'MY',
    data04: 'Top',
    data05: 'My',
    data06: 'Off',
    data07: '-',
    data08: 'On',
    data09: '',
  },
  {
    id: 'TabCategoryListTd-3',
    data01: '3',
    data02: 'T-MY',
    data03: 'MY',
    data04: 'Top',
    data05: 'My',
    data06: 'Off',
    data07: '-',
    data08: 'On',
    data09: '',
  },
  {
    id: 'TabCategoryListTd-4',
    data01: '4',
    data02: 'T-MY',
    data03: 'MY',
    data04: 'Top',
    data05: 'My',
    data06: 'Off',
    data07: '-',
    data08: 'On',
    data09: '',
  },
  {
    id: 'TabCategoryListTd-5',
    data01: '5',
    data02: 'T-MY',
    data03: 'MY',
    data04: 'Top',
    data05: 'My',
    data06: 'Off',
    data07: '-',
    data08: 'On',
    data09: '',
  },
  {
    id: 'TabCategoryListTd-6',
    data01: '6',
    data02: 'T-MY',
    data03: 'MY',
    data04: 'Top',
    data05: 'My',
    data06: 'Off',
    data07: '-',
    data08: 'On',
    data09: '',
  },
  {
    id: 'TabCategoryListTd-7',
    data01: '7',
    data02: 'T-MY',
    data03: 'MY',
    data04: 'Top',
    data05: 'My',
    data06: 'Off',
    data07: '-',
    data08: 'On',
    data09: '',
  },
];

export const CategoryListDragTh = [
  {
    id: 'CategoryOrder',
    label: 'Category Order',
    width: '15%',
    align: 'left',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'Category Code',
    label: 'Category Code',
    align: 'left',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryName',
    label: 'Name',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ContentType',
    label: 'Content Type',
    align: 'left',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Mapping Type',
    label: 'Mapping Type',
    align: 'left',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Category Type',
    label: 'CategoryType',
    align: 'left',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
];

export const CategoryListDragTd = [
  {
    id: 'CategoryListDragTd-1',
    data01: '1',
    data02: 'C-Favorite',
    data03: 'My Favorite',
    data04: 'TvShow, Movie, Tv Series,Live',
    data05: 'Slider',
    data06: 'Top',
  },
  {
    id: 'CategoryListDragTd-2',
    data01: '1',
    data02: 'C-Favorite',
    data03: 'My Favorite',
    data04: 'Live',
    data05: 'Auto',
    data06: 'Favorite',
  },
  {
    id: 'CategoryListDragTd-3',
    data01: '1',
    data02: 'C-Favorite',
    data03: 'My Favorite',
    data04: 'Live',
    data05: 'manual',
    data06: 'Favorite',
  },
  {
    id: 'CategoryListDragTd-4',
    data01: '1',
    data02: 'C-Favorite',
    data03: 'My Favorite',
    data04: 'Live',
    data05: 'manual',
    data06: 'Favorite',
  },
];

export const DeviceTypeData = [
  { id: 'device-1', value: 'TV', option: 'TV', type: 'text' },
  { id: 'device-2', value: 'device-2', option: 'device-2', type: 'text' },
  { id: 'device-3', value: 'device-3', option: 'device-3', type: 'text' },
];

export const Region = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'Eu', value: 'Eu', option: 'Eu', type: 'text' },
  { id: 'Asia', value: 'Asia', option: 'Asia', type: 'text' },
];

export const PlatformVersionData = [
  { id: 'platform-version-1', value: '3.4.0', option: '3.4.0', type: 'text' },
  { id: 'platform-version-2', value: '3.3.5', option: '3.3.5', type: 'text' },
  { id: 'platform-version-3', value: '3.3.0', option: '3.3.0', type: 'text' },
];

export const statusData = [
  { id: 'status-1', value: 'status-1', option: 'status-1', type: 'text' },
  { id: 'status-2', value: 'status-2', option: 'status-2', type: 'text' },
  { id: 'status-3', value: 'status-3', option: 'status-3', type: 'text' },
];

export const CategoryListTh = [
  {
    id: 'CategoryListTh-1',
    label: 'Category Order',
    align: 'left',
    width: '10%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'CategoryListTh-2',
    label: 'Category Code',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryListTh-3',
    label: 'Name',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryListTh-4',
    label: 'Centent Type',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryListTh-5',
    label: 'Mapping Type',
    align: 'left',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryListTh-6',
    label: 'Category Type',
    align: 'left',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryListTh-7',
    label: 'Fix Flag',
    align: 'left',
    width: '6%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryListTh-8',
    label: 'Schedule',
    align: 'left',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CategoryListTh-9',
    label: 'Action',
    align: 'left',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
];

export const CategoryListTd = [
  {
    data01: { text: '1', status: true },
    data02: 'C-favorite',
    data03: 'My Favorie',
    data04: 'tvshow, movie, tvshow-series, live',
    data05: 'slider',
    data06: 'top',
    data07: 'On',
    data08: 'Scheduled',
    data09: '',
  },
  {
    data01: { text: '2', status: false },
    data02: 'C-favorite',
    data03: 'My Favorie',
    data04: 'live',
    data05: 'Auto',
    data06: 'favorite',
    data07: 'On',
    data08: 'Scheduled',
    data09: '',
  },
  {
    data01: { text: '3', status: false },
    data02: 'C-favorite',
    data03: 'My Favorie',
    data04: 'live',
    data05: 'manual',
    data06: 'favorite',
    data07: 'On',
    data08: 'Scheduled',
    data09: '',
  },
  {
    data01: { text: '4', status: true },
    data02: 'C-favorite',
    data03: 'My Favorie',
    data04: 'live',
    data05: 'manual',
    data06: 'favorite',
    data07: 'Off',
    data08: false,
    data09: '',
  },
  {
    data01: { text: '5', status: false },
    data02: 'C-favorite',
    data03: 'My Favorie',
    data04: 'live',
    data05: 'manual',
    data06: 'favorite',
    data07: 'Off',
    data08: 'Ongoing',
    data09: '',
  },
  {
    data01: { text: '6', status: false },
    data02: 'C-favorite',
    data03: 'My Favorie',
    data04: 'live',
    data05: 'manual',
    data06: 'favorite',
    data07: 'Off',
    data08: 'Ongoing',
    data09: '',
  },
  {
    data01: { text: '7', status: false },
    data02: 'C-favorite',
    data03: 'My Favorie',
    data04: 'live',
    data05: 'manual',
    data06: 'favorite',
    data07: 'Off',
    data08: 'Ongoing',
    data09: '',
  },
  {
    data01: { text: '8', status: false },
    data02: 'C-favorite',
    data03: 'My Favorie',
    data04: 'live',
    data05: 'manual',
    data06: 'favorite',
    data07: 'Off',
    data08: 'Completed',
    data09: '',
  },
  {
    data01: { text: '9', status: false },
    data02: 'C-favorite',
    data03: 'My Favorie',
    data04: 'live',
    data05: 'manual',
    data06: 'favorite',
    data07: 'Off',
    data08: 'Completed',
    data09: '',
  },
];

export const ContentListTh = [
  {
    id: 'contentTh-01',
    align: 'left',
    label: 'CP Name',
    width: '24%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'contentTh-02',
    align: 'left',
    label: 'Content ID',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentTh-03',
    align: 'left',
    label: 'Content Name',
    width: '26%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentTh-04',
    align: 'left',
    label: 'Image',
    width: '18%',
    checkbox: false,
    sortable: false,
  },
];

export const ContentListTd = [
  {
    id: 'cl-1',
    data01: 'AMG1',
    data02: 'aliennationbydust_dustx_US',
    data03: 'Alien Nation by DUST',
    data04: imgDummy01,
  },
  {
    id: 'cl-2',
    data01: 'AMG1',
    data02: 'aliennationbydust_dustx_US',
    data03: 'Alien Nation by DUST',
    data04: imgDummy01,
  },
  {
    id: 'cl-3',
    data01: 'AMG1',
    data02: 'aliennationbydust_dustx_US',
    data03: 'Alien Nation by DUST',
    data04: imgDummy01,
  },
  {
    id: 'cl-4',
    data01: 'AMG1',
    data02: 'aliennationbydust_dustx_US',
    data03: 'Alien Nation by DUST',
    data04: imgDummy01,
  },
  {
    id: 'cl-5',
    data01: 'AMG1',
    data02: 'aliennationbydust_dustx_US',
    data03: 'Alien Nation by DUST',
    data04: imgDummy01,
  },
];

export const SelectedContentTh = [
  {
    id: 'SelectedTh-01',
    align: 'left',
    label: 'Order',
    width: '18%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'SelectedTh-02',
    align: 'left',
    label: 'Type',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'SelectedTh-03',
    align: 'left',
    label: 'CP Name',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'SelectedTh-04',
    align: 'left',
    label: 'Content ID',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'SelectedTh-05',
    align: 'left',
    label: 'Content Name',
    width: '23%',
    checkbox: false,
    sortable: false,
  },
];

export const SelectedContentTd = [
  {
    id: 'order-vbv',
    data01: '1',
    data02: 'Live',
    data03: 'AMG',
    data04: 'aliennationbydust_dustx_US',
    data05: 'Alien Nation by DUST',
  },
  {
    id: 'order-yi',
    data01: '2',
    data02: 'Live',
    data03: 'AMG',
    data04: 'aliennationbydust_dustx_US',
    data05: 'Alien Nation by DUST',
  },
  {
    id: 'order-nbn',
    data01: '3',
    data02: 'Live',
    data03: 'AMG',
    data04: 'aliennationbydust_dustx_US',
    data05: 'Alien Nation by DUST',
  },
  {
    id: 'order-zzwa',
    data01: '4',
    data02: 'Live',
    data03: 'AMG',
    data04: 'aliennationbydust_dustx_US',
    data05: 'Alien Nation by DUST',
  },
];
