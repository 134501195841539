import React from 'react';
import { styled } from '@mui/system';

import ArrowRightImage24 from 'assets/images/icon/ic_right_24_outline.svg';
import CloseIconImage32 from 'assets/images/icon/ic_close_16_outline.svg';
import PopCloseIconImage32 from 'assets/images/icon/ic_close_32_outline_909090.svg';
import MenuIconImage32 from 'assets/images/icon/ic_menu_open.svg';
import DarkMenuIconImage32 from 'assets/images/icon/ic_menu_open_darkmode.svg';
import HomeIconImage16 from 'assets/images/icon/ic_home_16_outline.svg';
import HomeIconImage16DarkImage from 'assets/images/icon/ic_home_16_outline_dark.svg';
import MenuArrowIconImage16 from 'assets/images/icon/ic_breadCurmb_arrow.svg';
import MenuArrowIconImage16DarkImage from 'assets/images/icon/ic_breadCurmb_arrow_dark.png';
import BreadCrumbArrowIconImage16 from 'assets/images/icon/ic_right_16_outline.svg';
import BreadCrumbArrowIconImage16DarkImage from 'assets/images/icon/ic_right_16_outline_dark.svg';
import SnackbarInfoImage from 'assets/images/icon/ic_info_24_outline.svg';
import DarkSnackbarInfoImage from 'assets/images/icon/ic_info_24_outline_dark.svg';
import SnackbarWarningImage from 'assets/images/icon/ic_info_24_outline.svg';
import SnackbarWarningDarkImage from 'assets/images/icon/ic_info_24_outline_dark.svg';
import CloseX24Image from 'assets/images/icon/ic_close_24_outline_gray.svg';
import CloseX24WhiteImage from 'assets/images/icon/ic_close_24_outline_white.svg';
import CloseX24BlackImage from 'assets/images/icon/ic_close_24_outline_black.svg';
import SnackbarSuccessImage from 'assets/images/icon/ic_success_16_outline.svg';
import SnackbarSuccessDarkImage from 'assets/images/icon/ic_success_16_outline_dark.svg';
import StarIconImage from 'assets/images/icon/ic_star_24.svg';
import TimeIconImage from 'assets/images/icon/ic_time_32_outline.svg';
import MenuIconImage from 'assets/images/icon/ic_down2_16_outline.svg';
import BtnLeftArrowImage from 'assets/images/icon/ic_btn_arrow_left.svg';
import BtnRightArrowImage from 'assets/images/icon/ic_btn_arrow_right.svg';
import BtnPrimaryLeftArrowImage from 'assets/images/icon/ic_primary_left_24_outline.svg';
import BtnPrimaryRightArrowImage from 'assets/images/icon/ic_primary_right_24_outline.svg';
import BtnSecondaryLeftArrowImage from 'assets/images/icon/ic_secondary_left_24_outline.svg';
import DarkBtnSecondaryLeftArrowImage from 'assets/images/icon/ic_secondary_left_24_outline_blackmode.svg';
import BtnSecondaryRightArrowImage from 'assets/images/icon/ic_secondary_right_24_outline.svg';
import DarkBtnSecondaryRightArrowImage from 'assets/images/icon/ic_secondary_right_24_outline_blackmode.svg';
import BtnTeritaryLeftArrowImage from 'assets/images/icon/ic_tertiary_left_24_outline.svg';
import BtnTeritaryRightArrowImage from 'assets/images/icon/ic_tertiary_right_24_outline.svg';
import refreshImage from 'assets/images/icon/ic_refresh_24_outline.svg';
import FileAddImage from 'assets/images/icon/ic_attatch_24_outline.svg';
import SearchImage from 'assets/images/icon/ic_search_24_outline.svg';
import DownloadGrayImage from 'assets/images/icon/ic_download_24_outline_gray.svg';
import DownloadGrayDarkImage from 'assets/images/icon/ic_download_24_outline_gray_dark.svg';
import CalendarImage from 'assets/images/icon/ic_calendar_24_outline.svg';
import ToggleBtnImage from 'assets/images/icon/ic_more_24_outline.svg';
import AddPlusImage from 'assets/images/icon/ic_add_32_outline.svg';
import DelMinusImage from 'assets/images/icon/ic_minus_24_outline.svg';
import PrimaryInfoImage from 'assets/images/icon/ic_info_24_outline_primary.svg';
import barThreeImage from 'assets/images/icon/ic_sort_24.svg';
import DarkbarThreeImage from 'assets/images/icon/ic_sort_24_dark.png';

const IconImage16 = styled('img')({
  width: '18px',
  height: '18px',
});

const IconImage24 = styled('img')({
  width: '24px',
  height: '24px',
});
const IconImage32 = styled('img')({
  width: '32px',
  height: '32px',
});

// Size 16
export const BreadcrumbMenuArrow16 = (props) => <IconImage16 src={MenuArrowIconImage16} alt='icon' {...props} />;
export const DarkBreadcrumbMenuArrow16 = (props) => (
  <IconImage16 src={MenuArrowIconImage16DarkImage} alt='icon' {...props} />
);

export const HomeIcon16 = (props) => <IconImage16 src={HomeIconImage16} alt='icon' {...props} />;
export const DarkHomeIcon16 = (props) => <IconImage16 src={HomeIconImage16DarkImage} alt='icon' {...props} />;
export const BreadCrumbArrow16 = (props) => <IconImage16 src={BreadCrumbArrowIconImage16} alt='' {...props} />;
export const DarkBreadCrumbArrow16 = (props) => (
  <IconImage16 src={BreadCrumbArrowIconImage16DarkImage} alt='' {...props} />
);

export const MenuIcon = (props) => <IconImage16 src={MenuIconImage} alt='' {...props} />;

export const BtnArrowLeft = (props) => <IconImage16 src={BtnLeftArrowImage} alt='' {...props} />;
export const BtnArrowRight = (props) => <IconImage16 src={BtnRightArrowImage} alt='' {...props} />;

export const BtnPrimaryOutlineLeft = (props) => <IconImage16 src={BtnPrimaryLeftArrowImage} alt='' {...props} />;
export const BtnPrimaryOutlineRight = (props) => <IconImage16 src={BtnPrimaryRightArrowImage} alt='' {...props} />;

export const BtnSecondaryOutlineLeft = (props) => <IconImage16 src={BtnSecondaryLeftArrowImage} alt='' {...props} />;
export const DarkBtnSecondaryOutlineLeft = (props) => (
  <IconImage16 src={DarkBtnSecondaryLeftArrowImage} alt='' {...props} />
);
export const BtnSecondaryOutlineRight = (props) => <IconImage16 src={BtnSecondaryRightArrowImage} alt='' {...props} />;
export const DarkBtnSecondaryOutlineRight = (props) => (
  <IconImage16 src={DarkBtnSecondaryRightArrowImage} alt='' {...props} />
);

export const BtnTeritaryOutlineLeft = (props) => <IconImage16 src={BtnTeritaryLeftArrowImage} alt='' {...props} />;
export const BtnTeritaryOutlineRight = (props) => <IconImage16 src={BtnTeritaryRightArrowImage} alt='' {...props} />;

// Size 24
export const ArrowRight24 = (props) => <IconImage24 src={ArrowRightImage24} alt='icon' {...props} />;
export const InfoIcon = (props) => <IconImage24 src={SnackbarInfoImage} alt='' {...props} />;
export const DarkInfoIcon = (props) => <IconImage24 src={DarkSnackbarInfoImage} alt='' {...props} />;
export const CloseIcon24 = (props) => <IconImage24 src={CloseX24Image} alt='' {...props} />;
export const CloseIcon24White = (props) => <IconImage24 src={CloseX24WhiteImage} alt='' {...props} />;
export const CloseIcon24Black = (props) => <IconImage24 src={CloseX24BlackImage} alt='' {...props} />;
export const SuccessIcon24 = (props) => <IconImage24 src={SnackbarSuccessImage} alt='' {...props} />;
export const DarkSuccessIcon24 = (props) => <IconImage24 src={SnackbarSuccessDarkImage} alt='' {...props} />;
export const WarningIcon24 = (props) => <IconImage24 src={SnackbarWarningImage} alt='' {...props} />;
export const DarkWarningIcon24 = (props) => <IconImage24 src={SnackbarWarningDarkImage} alt='' {...props} />;
export const DangerIcon24 = (props) => <IconImage24 src={SnackbarInfoImage} alt='' {...props} />;
export const DarkDangerIcon24 = (props) => <IconImage24 src={DarkSnackbarInfoImage} alt='' {...props} />;
export const StarIcon = (props) => <IconImage24 src={StarIconImage} alt='' {...props} />;
export const RefreshIcon = (props) => <IconImage24 src={refreshImage} alt='' {...props} />;
export const FileIcon = (props) => <IconImage24 src={FileAddImage} alt='' {...props} />;
export const SearchIcon = (props) => <IconImage24 src={SearchImage} alt='' {...props} />;
export const DownloadGrayIcon = (props) => <IconImage24 src={DownloadGrayImage} alt='' {...props} />;
export const DarkDownloadGrayIcon = (props) => <IconImage24 src={DownloadGrayDarkImage} alt='' {...props} />;
export const CalendarIcon = (props) => <IconImage24 src={CalendarImage} alt='' {...props} />;
export const ToggleIcon = (props) => <IconImage24 src={ToggleBtnImage} alt='' {...props} />;
export const AddPlus = (props) => <IconImage24 src={AddPlusImage} alt='' {...props} />;
export const DelMinus = (props) => <IconImage24 src={DelMinusImage} alt='' {...props} />;
export const PrimaryInfoIcon = (props) => <IconImage24 src={PrimaryInfoImage} alt='' {...props} />;
export const SortBar = (props) => <IconImage24 src={barThreeImage} alt='' {...props} />;
export const DarkSortBar = (props) => <IconImage24 src={DarkbarThreeImage} alt='' {...props} />;

// Size 32
export const TimeIcon = (props) => <IconImage32 src={TimeIconImage} alt='' {...props} />;
export const CloseIcon32 = (props) => <IconImage32 src={CloseIconImage32} alt='icon' {...props} />;
export const PopCloseIcon32 = (props) => <IconImage32 src={PopCloseIconImage32} alt='icon' {...props} />;
export const MenuOpenIcon = (props) => <IconImage32 src={MenuIconImage32} alt='icon' {...props} />;
export const DarkMenuOpenIcon = (props) => <IconImage32 src={DarkMenuIconImage32} alt='icon' {...props} />;
