import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import { styled } from '@mui/material/styles';
import { Box, Paper } from '@mui/material';
import 'dayjs/locale/ko';
import dayjs from 'dayjs';
import calendarIconLight from 'assets/images/icon/ic_calendar_24_outline.svg';
import calendarIconDark from 'assets/images/icon/ic_calendar_24_outline_dark.svg';
// import calendarLeftDark from 'assets/images/icon/ic_cal_left_16_outline.svg';

const theme = createTheme({
  components: {
    MuiMonthCalendar: {
      styleOverrides: {
        root: {
          '& .MuiPickersMonth-root': {
            flexBasis: '32%',

            '& .MuiPickersMonth-monthButton': {
              fontSize: '14px',
              color: '#000',
              borderRadius: '4px',
              '.dark &': {
                color: '#b1b1b1',
              },

              '&:hover': {
                backgroundColor: '#ABB6C2',
                color: '#fff',
              },
              '&.Mui-selected': {
                backgroundColor: '#004ba0',
                color: '#fff',
              },
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',

          '.MuiFormLabel-root': {
            '.MuiInputLabel-asterisk': {
              color: ' #a50034',
              fontSize: '16px',
            },
          },

          '& .MuiInputLabel-shrink': {
            fontSize: '14px',
            padding: '0 3px',
            color: '#6B6B6B',
            backgroundColor: '#fff',

            '.dark &': {
              background: '#1C1C1C',
              color: '#fff',
            },
          },

          '& .MuiInputBase-root': {
            '.dark &': {
              background: '#1b1b1b',
              color: '#fff',
            },

            //  property - Disabled
            '&.Mui-disabled': {
              backgroundColor: 'rgba(240, 236, 228, 0.10)',
              '&:not(.MuiIconButton-root)': {
                background: '#ECECEC',

                '.dark &': {
                  backgroundColor: 'rgba(240, 236, 228, 0.10)',
                },
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#DFDFDF',
              },
            },
            //  property - readOnly
            '&.Mui-readOnly': {
              backgroundColor: '#F0ECE4',
              '.dark &': {
                backgroundColor: 'rgba(240, 236, 228, 0.10)',
                color: '#fff',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 0,
              },
            },
          },

          //  Size - Large
          '& .MuiInputBase-sizeLarge ': {
            '& .MuiInputBase-input': {
              padding: '10px 12px',
              fontSize: '14px',
            },
          },
          //  Size - Small
          '& .MuiInputBase-sizeSmall ': {
            '& .MuiInputBase-input': {
              padding: '4px 12px',
              fontSize: '14px',
            },
          },
          //  Size - Medium
          '& .MuiInputBase-input': {
            padding: '6px 12px',
            fontSize: '14px',
          },

          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(107, 107, 107, 0.60)',

            '.dark &': {
              border: '1px solid #484848',
            },
          },
          '& .MuiOutlinedInput-root': {
            '&:hover:not(.Mui-disabled)': {
              '.dark &': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#fff',
                },
              },
            },
          },
          '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#b91c1c',
          },
        },
      },
    },
  },
});

function BasicDatePicker({
  size = 'medium',
  disabled = false,
  readOnly = false,
  error = false,
  id,
  label,
  width,
  maxWidth,
  required,
  singleDate,
  rangeDate,
  gap,
  wave,
  defaultValue = dayjs(),
  ...props
}) {
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [endError, setEndError] = React.useState(false);

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
    if (endDate && newValue.isAfter(endDate)) {
      setEndError(true);
    } else {
      setEndError(false);
    }
  };
  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
    if (startDate && newValue.isBefore(startDate)) {
      setEndError(true);
    } else {
      setEndError(false);
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {globalStyles}
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ko'>
          {singleDate && !rangeDate && (
            <DatePicker
              id={id}
              label={label}
              format='YYYY-MM-DD'
              slotProps={{
                calendarHeader: { format: 'YYYY/MM' },
                textField: { size, error: Boolean(error), required },
              }}
              width={width}
              sx={{ maxWidth: maxWidth }}
              disabled={disabled}
              readOnly={readOnly}
              defaultValue={defaultValue}
              slots={{ openPickerIcon: DateIcon }}
              PaperProps={{
                component: CustomPaper,
              }}
              {...props}
            />
          )}
          {rangeDate && (
            <Box className='rangePicker-container' sx={{ gap }}>
              <DatePicker
                label='Start Date'
                format='YYYY-MM-DD'
                value={startDate}
                width={width}
                sx={{ maxWidth: maxWidth }}
                slotProps={{
                  calendarHeader: { format: 'YYYY/MM' },
                  textField: { size, error: Boolean(error), required },
                }}
                disabled={disabled}
                readOnly={readOnly}
                onChange={handleStartDateChange}
                defaultValue={defaultValue}
                slots={{ openPickerIcon: DateIcon }}
                PaperProps={{
                  component: CustomPaper,
                }}
                {...props}
              />
              {wave && <span className='wave'>~</span>}
              <DatePicker
                label='End Date'
                format='YYYY-MM-DD'
                value={endDate}
                width={width}
                sx={{ maxWidth: maxWidth }}
                slotProps={{
                  calendarHeader: { format: 'YYYY/MM' },
                  textField: { size, error: endError, required },
                }}
                disabled={disabled}
                readOnly={readOnly}
                minDate={startDate}
                onChange={handleEndDateChange}
                defaultValue={defaultValue}
                slots={{ openPickerIcon: DateIcon }}
                PaperProps={{
                  component: CustomPaper,
                }}
                {...props}
              />
            </Box>
          )}
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const globalStyles = (
  <GlobalStyles
    styles={{
      '.MuiDateCalendar-root': {
        height: 'auto',
        maxHeight: 'none',
        '.dark &': {
          background: '#1C1C1C',
          border: '1px solid rgba(228, 231, 234, 0.10)',
        },
        '.MuiPickersCalendarHeader-root': {
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: '18px',
          color: '#333',
          marginBottom: '24px',
          padding: 0,

          '.dark &': {
            background: '#1B1B1B',
          },

          '.MuiPickersCalendarHeader-labelContainer': {
            marginRight: 'auto',
            width: '100%',
            justifyContent: 'center',

            '.dark &': {
              '& .MuiPickersCalendarHeader-switchViewButton': {
                'svg path': {
                  color: '#fff',
                  fill: '#fff',
                  stroke: '#fff',
                },
              },
            },

            '.MuiButtonBase-root': {
              display: 'inline-block',
              marginRight: 'initial',
            },
          },
          '.MuiPickersCalendarHeader-label': {
            fontSize: '16px',
            fontWeight: 700,
            color: '#000',

            '.dark &': {
              color: '#fff',
            },
          },
          '.MuiPickersArrowSwitcher-root': {
            width: 0,
            '& .MuiButtonBase-root': {
              position: 'absolute',
              borderRadius: '50%',
              width: '32px',
              height: '32px',
            },
            '& .MuiIconButton-edgeEnd': {
              left: '20%',
              top: '3px',
              '.dark &': {
                width: '32px',
                height: '32px',
                backgroundImage: '',
                backgroundPosition: '50%',
                'svg path': {
                  color: '#fff',
                },
              },
            },
            '& .MuiIconButton-edgeStart': {
              right: '22%',
              top: '3px',
              '.dark &': {
                width: '32px',
                height: '32px',
                backgroundImage: '',
                backgroundPosition: '50%',
                'svg path': {
                  color: '#fff',
                },
              },
            },
          },
          '& .MuiPickersCalendarHeader-switchViewButton': {},
        },
        '.MuiPickersDay-root': {
          fontSize: '14px',
          '&.Mui-disabled': {
            color: '#c6c6c6',
          },
        },
      },

      '.MuiDayCalendar-header': {
        '.MuiDayCalendar-weekDayLabel': {
          fontSize: '12px',
          color: '#6B6B6B',

          '.dark &': {
            color: '#fff',
          },

          '&:first-of-type': {
            color: '#B91C1C',
          },
          '&:last-child': {
            color: '#2196F3',
          },
        },
      },

      // 날짜
      '.MuiPickersDay-root': {
        fontSize: '14px',
        color: '#6B6B6B',
        borderRadius: '8px',

        '.dark &': {
          color: '#fff',
        },
        '&.Mui-disabled': {
          color: '#c6c6c6',
        },

        '&.MuiPickersDay-root[aria-colindex="1"]': {
          color: '#B91C1C',
        },
        '&.MuiPickersDay-root[aria-colindex="7"]': {
          color: '#2196F3',

          '&.Mui-selected': {
            color: '#fff',
          },
        },
        '&.MuiPickersDay-today': {
          background: '#F0ECE4',
          color: '#000',
          border: 0,
          fontWeight: 500,
        },
      },

      // Year
      '.MuiYearCalendar-root': {
        maxHeight: '230px',
        '.MuiPickersYear-root': {
          '.MuiPickersYear-yearButton': {
            fontSize: '12px',

            '.dark &': {
              color: '#B1B1B1',
            },
          },
        },
      },
    }}
  />
);

const DateIcon = styled('span')({
  display: 'block',
  width: '24px',
  height: '24px',
  backgroundImage: `url(${calendarIconLight})`,
  backgroundRepeat: 'no-repeat',

  '.dark &': {
    backgroundImage: `url(${calendarIconDark})`,
  },
});

const CustomPaper = styled(Paper)({
  backgroundColor: 'lightblue',
  padding: '10px',
});

export default BasicDatePicker;
