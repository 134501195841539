// src/data.js
export const CountryData = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'Us', value: 'Us', option: 'Us', type: 'text' },
  { id: 'Asia', value: 'Asia', option: 'Asia', type: 'text' },
];

export const FreeOnTypeOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'Slider-1', value: 'Slider-1', option: 'Slider-1', type: 'text' },
  { id: 'Slider-2', value: 'Slider-2', option: 'Slider-2', type: 'text' },
];

export const WatchOnStatusOptioin = [
  { id: 1, value: 'Saved for Testing', option: 'Saved for Testing', type: 'text' },
  { id: 2, value: 'testing', option: 'testing', type: 'text' },
];
export const cpNameOption = [
  { id: 'cpName-all', value: 'All', option: 'All', type: 'text' },
  { id: 'cpName-1', value: 'Slider-1', option: 'Slider-1', type: 'text' },
  { id: 'cpName-2', value: 'Slider-2', option: 'Slider-2', type: 'text' },
];

export const TypeOptionData = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'Slider-1', value: 'Slider-1', option: 'Slider-1', type: 'text' },
  { id: 'Slider-2', value: 'Slider-2', option: 'Slider-2', type: 'text' },
];
export const selectOption = [
  { id: 'All', value: 'All', option: 'All', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];
export const ManualAutoOption = [
  { id: 'Manual', value: 'Manual', option: 'Manual', type: 'text' },
  { id: 'Auto', value: 'Auto', option: 'Auto', type: 'text' },
];
export const WatchResultTh = [
  {
    id: 'CPName',
    align: 'left',
    label: 'CP Name',
    width: '18%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'ContentID',
    align: 'left',
    label: 'Ch. ID',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ContentName',
    align: 'left',
    label: 'Ch. Name',
    width: '30%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Image',
    align: 'left',
    label: 'Image',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Genre',
    align: 'left',
    label: 'Genre',
    width: '15%',
    checkbox: false,
    sortable: false,
  },
];

export const InteractiveData = [
  {
    id: 'count-01',
    label: '3.2',
    type: 'checkbox',
  },
  {
    id: 'count-02',
    label: '4.3',
    type: 'checkbox',
  },
  {
    id: 'count-03',
    label: 'WEE',
    type: 'checkbox',
  },
  {
    id: 'count-04',
    label: 'ME',
    type: 'checkbox',
  },
  {
    id: 'count-05',
    label: 'Auto',
    type: 'checkbox',
  },
  {
    id: 'count-06',
    label: 'Auto',
    type: 'checkbox',
  },
];

export const InteractiveTh = [
  {
    id: 'check',
    align: 'left',
    label: 'Service',
    width: '7%',
    checkbox: true,
    sortable: false,
  },
  {
    id: 'interative-Manual',
    align: 'left',
    label: 'Manual/Auto',
    width: '14%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'interative-Logo',
    align: 'left',
    label: 'Logo',
    width: '16%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'interative-No',
    align: 'left',
    label: 'Ch.No',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'interative-ChName',
    align: 'left',
    label: 'Ch.Name',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'interative-ChID',
    align: 'left',
    label: 'Ch.ID',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'interative-CPName',
    align: 'left',
    label: 'CP Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'interative-Genre',
    align: 'left',
    label: 'Genre',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'interative-Action',
    align: 'left',
    label: 'Action',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];
export const InteractiveTd = [
  {
    id: 'InteractiveTd-1',
    data01: '',
    data02: '',
    data03: '',
    data04: '605',
    data05: 'Super Wings',
    data06: 'us-jansonmedia_superwings_1',
    data07: 'Wurl',
    data08: 'Kids',
    data09: '',
  },
  {
    id: 'InteractiveTd-2',
    data01: '',
    data02: '',
    data03: '',
    data04: '605',
    data05: 'Super Wings',
    data06: 'us-jansonmedia_superwings_1',
    data07: 'Wurl',
    data08: 'Kids',
    data09: '',
  },
  {
    id: 'InteractiveTd-3',
    data01: '',
    data02: '',
    data03: '',
    data04: '605',
    data05: 'Super Wings',
    data06: 'us-jansonmedia_superwings_1',
    data07: 'Wurl',
    data08: 'Kids',
    data09: '',
  },
  {
    id: 'InteractiveTd-4',
    data01: '',
    data02: '',
    data03: '',
    data04: '605',
    data05: 'Super Wings',
    data06: 'us-jansonmedia_superwings_1',
    data07: 'Wurl',
    data08: 'Kids',
    data09: '',
  },
];

export const CategoryTitleTh = [
  {
    id: 'Classification',
    align: 'left',
    label: 'Classification',
    width: '18%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'Priority',
    align: 'left',
    label: 'Priority',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Description',
    align: 'left',
    label: 'Description',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'DefaultCategory',
    align: 'left',
    label: 'Default Category Name',
    width: '30%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Action',
    align: 'left',
    label: 'Action',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];
export const CategoryTitleTd = [
  {
    id: 'sgfs',
    data01: 'cp_normal',
    data02: '1',
    data03: 'Picks for You',
    data04: 'Alien Nation by DUST',
    data05: '',
  },
  {
    id: 'dhfdh',
    data01: 'cp_normal',
    data02: '2',
    data03: 'Picks for You',
    data04: 'Alien Nation by DUST',
    data05: '',
  },
  {
    id: 'fnf',
    data01: 'cp_normal',
    data02: '3',
    data03: 'Picks for You',
    data04: 'Alien Nation by DUST',
    data05: '',
  },
  {
    id: '34d',
    data01: 'cp_normal',
    data02: '4',
    data03: 'Picks for You',
    data04: 'Alien Nation by DUST',
    data05: '',
  },
];

export const OrganizeTh = [
  {
    id: 'sss',
    align: 'left',
    label: '',
    width: '5%',
    checkbox: true,
    sortable: false,
  },
  {
    id: 'wffe',
    align: 'left',
    label: 'CP Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'org-th-2',
    align: 'left',
    label: 'Ch.ID',
    width: '26%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'org-th-3',
    align: 'left',
    label: 'Ch.Name',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'org-th-4',
    align: 'left',
    label: 'Image',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'org-th-5',
    align: 'left',
    label: 'Genre',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const OrganizeTd = [
  {
    id: 'org-11',
    data01: '',
    data02: 'AMG',
    data03: 'wickedtunalgus_wctna_US',
    data04: 'Wicked Tuna',
    data05: '',
    data06: 'Reality TV',
  },
  {
    id: 'org-12',
    data01: '',
    data02: 'AMG',
    data03: 'wickedtunalgus_wctna_US',
    data04: 'Wicked Tuna',
    data05: '',
    data06: 'Reality TV',
  },
  {
    id: 'org-13',
    data01: '',
    data02: 'AMG',
    data03: 'wickedtunalgus_wctna_US',
    data04: 'Wicked Tuna',
    data05: '',
    data06: 'Reality TV',
  },
  {
    id: 'org-14',
    data01: '',
    data02: 'AMG',
    data03: 'wickedtunalgus_wctna_US',
    data04: 'Wicked Tuna',
    data05: '',
    data06: 'Reality TV',
  },
];

export const OrgScheduledTh = [
  {
    id: 'org-Order',
    align: 'left',
    label: 'Order',
    width: '8%',
    checkbox: true,
    sortable: true,
  },

  {
    id: 'org-CPName',
    align: 'left',
    label: 'Order',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'org-CPName',
    align: 'left',
    label: 'CP Name',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'org-ID',
    align: 'left',
    label: 'Ch.ID',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'org-ContentName',
    align: 'left',
    label: 'Ch. name',
    width: '16%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'org-Image',
    align: 'left',
    label: 'Image',
    width: '12%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'org-Genre',
    align: 'left',
    label: 'Genre',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const OrgScheduledTd = [
  {
    id: 'orgSch-1',
    data01: '',
    data02: '1',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'A Rainy Day in New York',
    data06: '',
    data07: 'Sport',
  },
  {
    id: 'orgSch-2',
    data01: '',
    data02: '1',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'A Rainy Day in New York',
    data06: '',
    data07: 'Sport',
  },
  {
    id: 'orgSch-2',
    data01: '',
    data02: '1',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'A Rainy Day in New York',
    data06: '',
    data07: 'Sport',
  },
  {
    id: 'orgSch-2',
    data01: '',
    data02: '1',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'A Rainy Day in New York',
    data06: '',
    data07: 'Sport',
  },
  {
    id: 'orgSch-2',
    data01: '',
    data02: '1',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'A Rainy Day in New York',
    data06: '',
    data07: 'Sport',
  },
  {
    id: 'orgSch-2',
    data01: '',
    data02: '1',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'A Rainy Day in New York',
    data06: '',
    data07: 'Sport',
  },
  {
    id: 'orgSch-2',
    data01: '',
    data02: '1',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'A Rainy Day in New York',
    data06: '',
    data07: 'Sport',
  },
  {
    id: 'orgSch-2',
    data01: '',
    data02: '1',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'A Rainy Day in New York',
    data06: '',
    data07: 'Sport',
  },
];
