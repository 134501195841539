import { BASE_URL } from 'constants';
import { getHeaders } from 'utils/Header';
import { COMMON_COUNTRY_INFO } from 'actions/actionTypes';

export const getCountry = () => async (dispatch) => {
  fetch(`${BASE_URL}/admin/common/country`, {
    method: 'GET',
    headers: getHeaders(),
  })
  .then(res => res.json())
  .then(body => {
    if (body.result === 'SUCCESS') {
      return dispatch({
        type: COMMON_COUNTRY_INFO,
        payload: body.data,
      });
    } else {
      return dispatch({
        type: COMMON_COUNTRY_INFO,
        payload: 'error',
      });
    }
  }).catch(ex => {
    return dispatch({
      type: COMMON_COUNTRY_INFO,
      payload: 'error',
    });
  });
};
