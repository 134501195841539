export const ChannelMapOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'Eu', value: 'Eu', option: 'Eu', type: 'text' },
  { id: 'Asia', value: 'Asia', option: 'Asia', type: 'text' },
];

export const CountryOption = [
  { id: 'country-1', value: 'Us', option: 'Us', type: 'text' },
  { id: 'country-2', value: 'Italy', option: 'Italy', type: 'text' },
  { id: 'country-3', value: 'Ger', option: 'Ger', type: 'text' },
];

export const statusData = [
  { id: 'status-1', value: 'status-1', option: 'status-1', type: 'text' },
  { id: 'status-2', value: 'status-2', option: 'status-2', type: 'text' },
  { id: 'status-3', value: 'status-3', option: 'status-3', type: 'text' },
];

export const selectOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const ContentType = [
  { id: 'Channel', value: 'Channel', option: 'Channel', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const LanguageOption = [
  { id: 'lang-en', value: 'En', option: 'En', type: 'text' },
  { id: 'lang-fr', value: 'fr', option: 'fr', type: 'text' },
  { id: 'lang-es', value: 'es', option: 'es', type: 'text' },
];

export const channelmapOption = [
  { id: 'map-t1', value: 'US-ME-6.0.0', option: 'US-ME-6.0.0', type: 'text' },
  { id: 'map-t2', value: 'US-ME-6.1.0', option: 'US-ME-6.1.0', type: 'text' },
  { id: 'map-t3', value: 'US-ME-6.2.0', option: 'US-ME-6.2.0', type: 'text' },
];

export const DeviceNotificationTh = [
  {
    id: 'Country',
    align: 'left',
    label: 'Country',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Version',
    align: 'left',
    label: 'Version',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Enable',
    align: 'left',
    label: 'Enable',
    width: '8%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'Title',
    align: 'left',
    label: 'Title',
    width: '24%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Start',
    align: 'left',
    label: 'Start Date',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'End',
    align: 'left',
    label: 'End Date',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Last',
    align: 'left',
    label: 'Last Chg Date',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'action',
    align: 'left',
    label: 'Action',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const DeviceNotificationTd = [
  {
    id: 'DeviceNotificationTd-1',
    data01: { label: 'US+11', tooltip: true },
    data02: '20240605-075535',
    data03: 'On',
    data04: 'Upcoming Changes to Your LG Channels Lineup',
    data05: '2024-06-05',
    data06: '2024-06-08',
    data07: '2024-06-05T07:55:35Z',
  },
  {
    id: 'DeviceNotificationTd-2',
    data01: { label: 'US', tooltip: false },
    data02: '20240605-075535',
    data03: 'On',
    data04: 'Upcoming Changes to Your LG Channels Lineup',
    data05: '2024-06-05',
    data06: '2024-06-08',
    data07: '2024-06-05T07:55:35Z',
  },
  {
    id: 'DeviceNotificationTd-3',
    data01: { label: 'US', tooltip: false },
    data02: '20240605-075535',
    data03: 'On',
    data04: 'Upcoming Changes to Your LG Channels Lineup',
    data05: '2024-06-05',
    data06: '2024-06-08',
    data07: '2024-06-05T07:55:35Z',
  },
];
