import React from 'react';
import { Pagination, PaginationItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SelectBox from 'components/component/BasicSelectBox';

// Image
import ArrowRight24 from 'assets/images/icon/ic_left_24_outline.svg';
import IcForward24 from 'assets/images/icon/ic_forward_24_outline.svg';

// Styled
const PaginationBottomContainer = styled('div')`
  width: 100%;
  padding: 20px 24px 24px;
  box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 0 0 8px 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  .dark & {
    background: #1b1b1b;
  }
`;
const PaginationContainer = styled('div')`
  display: flex;
  flex-shrink: 0;
  width: 100%;

  &.small {
    .MuiPagination-ul > li {
      width: 24px;
      height: 24px;

      .MuiPaginationItem-root {
        min-width: 24px;
        width: 24px;
        height: 24px;
        padding: 0;
      }
    }
  }
  .MuiPagination-root {
    display: flex;
  }

  .pagination-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .MuiFormControl-root,
    .MuiOutlinedInput-root {
      width: auto;
    }
    .MuiPagination-root {
      margin: 0 auto;
    }
  }

  .MuiFormControl-root {
    width: 110px;
  }
`;

const StyledPagination = styled(Pagination)({
  '& .MuiPagination-ul': {
    alignItems: 'center',
    flexShrink: 0,
    '&>li': {
      width: '32px',
      height: '32px',
      margin: '0 6px',

      '&:last-child': {
        '& .MuiPaginationItem-root': {
          transform: 'rotate(180deg)',
        },
      },
    },
  },
  '& .MuiPaginationItem-root': {
    borderRadius: '4px',
    fontSize: '12px',
    color: '#000',
    margin: 0,

    '&.Mui-selected': {
      background: '#F7F7F7',

      '.dark &': {
        color: '#fff',
        background: '#000',
      },
    },
    '.dark &': {
      color: '#fff',
    },
  },
  '& .MuiPaginationItem-previousNext': {
    width: '32px',
    height: '32px',

    padding: 0,
    backgroundImage: `url(${ArrowRight24})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',

    '& svg': {
      display: 'none',
    },

    '&.btn-next': {
      transform: 'rotate(180deg)',
    },
  },
  '& .MuiPaginationItem-firstLast': {
    width: '32px',
    height: '32px',
    backgroundImage: `url(${IcForward24})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',

    '& svg': {
      display: 'none',
    },
  },
});

const defaultOptionData = [
  { id: 1, value: 10, option: '10 rows', type: 'text' },
  { id: 2, value: 20, option: '20 rows', type: 'text' },
  { id: 3, value: 30, option: '30 rows', type: 'text' },
  { id: 4, value: 50, option: '50 rows', type: 'text' },
];

// Pagination Component
function BasicPagination({ id, selOptionData = defaultOptionData, count, onChangeHandle, selected, page }) {
  const onChange = (value) => {
    if (onChangeHandle) {
      onChangeHandle(value);
    }
  }

  return (
    <PaginationBottomContainer className='Pagination-bottom-container'>
      <PaginationContainer className={`pagination-container`}>
        <Box className='pagination-wrap'>
          <SelectBox
            id={id}
            width='100px'
            size='medium'
            selectOptions={selOptionData}
            selected={selected}
            disabled={count === 0 ? true : false}
            label=''
            onChange={(value) => onChange({ type: "size", value: value })}
            classes='print-per-count'
          />

          <StyledPagination
            count={count}
            page={page}
            showFirstButton
            showLastButton
            onChange={(event, value) => onChange({ type: "page", value: value })}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                className={
                  item.type === 'previous'
                    ? 'btn-previous'
                    : item.type === 'next'
                    ? 'btn-next'
                    : item.type === 'first'
                    ? 'btn-first'
                    : item.type === 'last'
                    ? 'btn-last'
                    : ''
                }
              />
            )}
          />
        </Box>
      </PaginationContainer>
    </PaginationBottomContainer>
  );
}
export default BasicPagination;
