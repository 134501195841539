import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import CheckBox from 'components/component/BasicCheckBox';
import CustomImage from 'components/component/BasicImage';
import SelectBox from 'components/component/BasicSelectBox';
import Pagination from 'components/component/BasicPagination';

import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tabs,
  Tab,
  Typography,
} from '@mui/material';

import { styled } from '@mui/material/styles';
// Icon

import {
  APIOption,
  APICPNameOption,
  StatusOption,
  TabCodeOption,
  EmulateTh,
  EmulateTd,
} from 'pages/Monitoring/Emulate/data/EmulateData';

// Icon
import iconBoardTable from 'assets/images/icon/ic_board.svg';
import iconBoardTableSelected from 'assets/images/icon/ic_board_on.svg';
import iconThing from 'assets/images/icon/ic_not_board.svg';
import iconThingSelected from 'assets/images/icon/ic_not_board_on.svg';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// 메인 컨텐츠
function Emulate() {
  const optionMenu = {
    gnbMenu: 'Monitoring',
    lnbMenu: 'Emulate',
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const sortedTdRows = sortRows([...EmulateTd], orderBy, order);

  const [value, setValue] = useState('tab-1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className='main-container category-tab-list' component='main' sx={{ flexGrow: 1 }}>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href=''>
                Emulate
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap' component='div'>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                {/* 필터영역 : 컴포넌트 개수에 따라 col-3,col-4, col-5, col-6  */}
                <Box className='component-wrap col-6'>
                  {/* Device Type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='API-CP'
                      size='large'
                      shrink={true}
                      label='Device Type'
                      selectOptions={APICPNameOption}
                      disabled={false}
                      placeholder=''
                      value=''
                    />
                  </Box>

                  {/* Region */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='API-API'
                      size='large'
                      shrink={true}
                      label='Region'
                      selectOptions={APIOption}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>

                  {/* Country */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='API-Status'
                      size='large'
                      shrink={true}
                      label='Country'
                      selectOptions={StatusOption}
                      disabled={false}
                      placeholder=''
                      value=''
                    />
                  </Box>

                  {/* Channel Map */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='API-Status'
                      size='large'
                      shrink={true}
                      label='Channel Map'
                      selectOptions={StatusOption}
                      disabled={false}
                      placeholder=''
                      value=''
                    />
                  </Box>

                  {/* Platform Version */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='API-Status'
                      size='large'
                      shrink={true}
                      label='Platform Version'
                      selectOptions={StatusOption}
                      disabled={false}
                      placeholder=''
                      value=''
                    />
                  </Box>

                  {/* Status */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='API-Status'
                      size='large'
                      shrink={true}
                      label='Status'
                      selectOptions={StatusOption}
                      disabled={false}
                      placeholder=''
                      value=''
                    />
                  </Box>
                </Box>
              </Box>
              <div className='form-area right'></div>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap single-box'>
            <div className='box-content'>
              <Box className='white-box-content-top-area'>
                <Box className='left-area' alignItems={'center'}>
                  <SelectBox
                    id='API-Status'
                    size='large'
                    shrink={true}
                    label='Tab Code'
                    maxWidth={'220px'}
                    selectOptions={TabCodeOption}
                    disabled={false}
                    placeholder=''
                    value=''
                  />
                  <Typography className='sm-sub-text'>*The actual screen and design style are different.</Typography>
                </Box>
                <Box className='right-area'>
                  <Box className='tabs-icon-container'>
                    <Tabs value={value} onChange={handleChange} aria-label='icon tabs'>
                      <Tab
                        icon={
                          <img
                            src={value === 'tab-1' ? iconBoardTableSelected : iconBoardTable}
                            className='tab-btn'
                            alt='Table'
                          />
                        }
                        value='tab-1'
                        aria-label='board'
                        selected
                      />
                      <Tab
                        icon={
                          <img
                            src={value === 'tab-2' ? iconThingSelected : iconThing}
                            className='tab-btn'
                            alt='Board'
                          />
                        }
                        value='tab-2'
                        aria-label='image'
                      />
                    </Tabs>
                  </Box>
                </Box>
              </Box>
              <Box className='content-inner-wrap'>
                {value === 'tab-1' && (
                  <>
                    <Box className='tab-icon-panel-container'>
                      <Box className='tab-icon-panel-wrap'>
                        <CustomTableContainer className='table-container'>
                          <Table className='custom-table auto-nowrap' aria-label='table' stickyHeader>
                            {/* table Head */}
                            <TableHead className='thead'>
                              <TableRow className='tr'>
                                {EmulateTh.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    className='th'
                                    align={column.align}
                                    sx={{ width: column.width }}
                                  >
                                    {column.checkbox ? (
                                      <Box display='flex' alignContent='center'>
                                        <CheckBox
                                          label=''
                                          value='select-all'
                                          defaultChecked={false}
                                          inputProps={{
                                            'aria-label': 'select all',
                                          }}
                                        />
                                        <TableSortLabel
                                          active={orderBy === column.id}
                                          direction={orderBy === column.id ? order : 'desc'}
                                          onClick={() => handleRequestSort(column.id)}
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      </Box>
                                    ) : (
                                      <>
                                        {column.sortable ? (
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'desc'}
                                            onClick={() => handleRequestSort(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        ) : (
                                          column.label
                                        )}
                                      </>
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            {/* table Body */}
                            <TableBody className='tbody'>
                              {sortedTdRows.map((td, index) => (
                                <TableRow className='tr' key={td.id || index}>
                                  {EmulateTh.map((column, colIndex) => (
                                    <TableCell key={column.id} className='td' align={column.align}>
                                      {colIndex === 7 ? (
                                        <CustomImage
                                          src={td[`data${colIndex + 1}`]}
                                          fit={false}
                                          alt=''
                                          wrapWidth='122px'
                                          wrapHeight='64px'
                                          rounded
                                        />
                                      ) : (
                                        td[`data${colIndex + 1}`]
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </CustomTableContainer>
                      </Box>
                    </Box>

                    {/* Pagination */}
                    <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
                  </>
                )}
                {value === 'tab-2' && (
                  <Box className='tab-icon-panel-container'>
                    <Box className='tab-icon-panel-wrap'>{/* Iframe 영역 */}</Box>
                  </Box>
                )}
              </Box>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default Emulate;
