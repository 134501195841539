import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import CheckBox from 'components/component/BasicCheckBox';
import Button from 'components/component/BasicButton';
import TextLink from 'components/component/BasicTextLink';
import SelectBox from 'components/component/BasicSelectBox';
import BasicSwitches from 'components/component/BasicSwitch';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import TextField from 'components/component/BasicTextField';
import List from 'components/component/BasicList';
import CustomImage from 'components/component/BasicImage';
import Tag from 'components/component/BasicTag';
import SearchField from 'components/component/BasicSearchField';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Box, Typography, Table, TableBody, TableContainer, TableCell, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

// ICON
import { BtnArrowRight } from 'components/component/BasicIcon';

// Select
import {
  selOptionCountryData,
  ChannelMapListOption,
  ChannelMapStatusOption,
  ChannelMapReservationOption,
  FeedTableTh,
  FeedTableTd,
  ChannelMapSelectedTh,
  ChannelMapSelectedTd,
} from 'pages/Organization/ChannelMapping/data/ChannelMappingData';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Button Data
const SelectedChannelButtons1 = [
  {
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    label: 'Bulk Upload',
    isIcon: false,
    fontWeight: 'bold',
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    label: 'Add',
    isIcon: false,
    fontWeight: 'bold',
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    label: 'Delete',
    isIcon: false,
    fontWeight: 'bold',
    autosize: true,
  },
];
// Feed Info
const feedInfoData = [
  {
    id: 'feed-1',
    value: 'info1',
    primary: '[AMG-AMG] Amagi',
    secondary: '2024-07-01 16:18:51',
    checked: false,
  },
  {
    id: 'feed-2',
    value: 'info2',
    primary: '[AMG-RBX] Redbox',
    secondary: '2024-07-01 16:18:51',
    disabled: false,
  },
  {
    id: 'feed-3',
    value: 'info3',
    primary: '[APS] Alphonso',
    secondary: '2024-07-01 16:18:51',
    disabled: false,
  },
  {
    id: 'feed-4',
    value: 'info4',
    primary: '[FREQCY] Frequency',
    secondary: '2024-07-01 16:18:51',
    disabled: false,
  },
  {
    id: 'feed-5',
    value: 'info5',
    primary: '[NEW] NEW ID',
    secondary: '2024-07-01 16:18:51',
    disabled: false,
  },
  {
    id: 'feed-6',
    value: 'info6',
    primary: '[[OTTR] Ottera',
    secondary: '2024-07-01 16:18:51',
    disabled: false,
  },
  {
    id: 'feed-7',
    value: 'info7',
    primary: '[[OTTR] Ottera',
    secondary: '2024-07-01 16:18:51',
    disabled: false,
  },
  {
    id: 'feed-8',
    value: 'info8',
    primary: '[[OTTR] Ottera',
    secondary: '2024-07-01 16:18:51',
    disabled: false,
  },
  {
    id: 'feed-9',
    value: 'info9',
    primary: '[[OTTR] Ottera',
    secondary: '2024-07-01 16:18:51',
    disabled: false,
  },
  { id: 'feed-10', value: 'info10', primary: '[[OTTR] Ottera', secondary: '2024-07-01 16:18:51', disabled: false },
];

const SelectedChannelButtons2 = [
  {
    color: 'tertiary',
    type: 'box',
    size: 'medium',
    label: 'History',
    isIcon: false,
    fontWeight: 'bold',
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'box',
    size: 'medium',
    label: 'Reservation List',
    isIcon: false,
    fontWeight: 'bold',
    autosize: true,
  },
  {
    color: 'primary',
    type: 'box',
    size: 'medium',
    label: 'Save',
    isIcon: false,
    fontWeight: 'bold',
    autosize: true,
    tooltip: true,
    tooltipText: 'Please save when modifying the channel name.',
  },
  {
    color: 'tertiary',
    type: 'box',
    size: 'medium',
    label: 'Testing',
    isIcon: false,
    fontWeight: 'bold',
    autosize: true,
  },
];

// 메인 컨텐츠
function ChannelMapping() {
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Channel Mapping',
  };

  const [isOpenArray, setIsOpenArray] = useState([true, true, true]);
  const togglePanel = (index) => {
    setIsOpenArray((prev) => prev.map((isOpen, i) => (i === index ? !isOpen : isOpen)));
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const sortedFeedRows = sortRows([...FeedTableTd], orderBy, order);
  const sortedContentRows = sortRows([...ChannelMapSelectedTd], orderBy, order);

  // useState
  const [isEnableSwitchOn, setIsEnableSwitchOn] = useState(false);

  // Select Feed 데이터 유무 컨트롤
  // eslint-disable-next-line no-unused-vars
  const [isSelectCP, setIsSelectCP] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [isSelected, setIsSelected] = useState(true);

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container' component='main' sx={{ flexGrow: 1 }}>
        {/* BreadCrumb Area */}
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Typography className='title'>Channel Mapping</Typography>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>

        <Box className='content-wrap channel-mapping' component='div'>
          <Box className='white-box-container align-hor'>
            {/* Feed Info */}
            <Box
              className={`white-box white-box-wrap feed-info ${isOpenArray[0] ? 'expanded' : 'collapsed'}`}
              sx={{ overflow: 'initial' }}
            >
              <Button className='btn-expand' onClick={() => togglePanel(0)} />
              <Box className='fold-content'>
                {/* Box Title Area */}
                <Box className='title-wrap'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2' className='box-title-text'>
                        Feed Info
                      </Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'></Box>
                </Box>
                <Box className='box-content'>
                  <div className='inner-wrap'>
                    <SelectBox
                      id='select-02'
                      fullWidth
                      size='large'
                      selectOptions={selOptionCountryData}
                      disabled={false}
                      label='Country'
                      shrink
                    />

                    {/* feed-list-container 위치수정 */}
                    <div className='feed-list-container '>
                      <div className='list-area'>
                        <div className='list-box'>
                          <List type='radio' items={feedInfoData} name='feed-info-group-2' />
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </Box>
            </Box>

            {/* Select Feed: */}
            <Box
              className={`white-box white-box-wrap  select-feed ${isOpenArray[1] ? 'expanded' : 'collapsed'}`}
              sx={{ overflow: 'initial' }}
            >
              <Button className='btn-expand' onClick={() => togglePanel(1)} />
              <Box className='fold-content'>
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography className='box-title-text title-label' variant='h2'>
                        Select Feed:
                        <Typography className='title-value' component='span'>
                          NEW ID
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box display='flex' className='count-all'>
                      <Typography variant='span' className='text label'>
                        Showing All
                      </Typography>
                      <Typography variant='span' className='text value'>
                        20
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className='box-content'>
                  <div className='inner-wrap'>
                    <Box className='view-filter'>
                      <Box className='left-area'>
                        <BasicSwitches
                          disabled={false}
                          onOffMode={isEnableSwitchOn}
                          setSwitchOnOff={setIsEnableSwitchOn}
                          isLabelText={true}
                          className='switch-wrap'
                          onText='Exclude Applied Channels'
                          offText='Exclude Applied Channels'
                        />
                      </Box>
                      <Box className='right-area' width={'50%'} display={'flex'} justifyContent={'flex-end'}>
                        <SearchField
                          id='search-1'
                          placeholder='Please enter a search term'
                          label='Ch.no or ch.Name'
                          maxWidth='230px'
                          size='medium'
                          required={false}
                          shrink={false}
                        />
                      </Box>
                    </Box>

                    {/* Table */}
                    <CustomTableContainer className='table-container'>
                      <Table className='custom-table' aria-label='table' stickyHeader>
                        {/* table Head */}
                        <TableHead className='thead'>
                          <TableRow className='tr'>
                            {FeedTableTh.map((column) => (
                              <TableCell
                                key={column.id}
                                className='th'
                                align={column.align}
                                sx={{ width: column.width }}
                              >
                                {column.checkbox ? (
                                  <Box display='flex' alignContent='center'>
                                    <CheckBox
                                      label=''
                                      value='select-all'
                                      defaultChecked={false}
                                      inputProps={{
                                        'aria-label': 'select all',
                                      }}
                                    />
                                    <TableSortLabel
                                      active={orderBy === column.id}
                                      direction={orderBy === column.id ? order : 'desc'}
                                      onClick={() => handleRequestSort(column.id)}
                                      sx={{ marginLeft: '8px' }}
                                    >
                                      {column.label}
                                    </TableSortLabel>
                                  </Box>
                                ) : (
                                  <>
                                    {column.sortable ? (
                                      <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'desc'}
                                        onClick={() => handleRequestSort(column.id)}
                                      >
                                        {column.label}
                                      </TableSortLabel>
                                    ) : (
                                      column.label
                                    )}
                                  </>
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        {/* table Body */}

                        <TableBody className='tbody'>
                          {isSelectCP ? (
                            sortedFeedRows.map((td, index) => (
                              <TableRow className='tr' key={td.id || index}>
                                {FeedTableTh.map((column, colIndex) => (
                                  <TableCell key={column.id} align={column.align} className='td'>
                                    {colIndex === 0 ? (
                                      <Box display='flex' gap='3px'>
                                        <CheckBox label='' value='value' defaultChecked={false} />
                                        <CustomImage
                                          src={td[`data0${colIndex + 1}`]} // 이미지 경로를 동적으로 가져옴
                                          sx={{ width: 50, height: 64 }}
                                          alt=''
                                          rounded
                                        />
                                      </Box>
                                    ) : colIndex === 2 && Array.isArray(td[`data0${colIndex + 1}`]) ? (
                                      td[`data0${colIndex + 1}`].map((item, idx) => (
                                        <React.Fragment key={idx}>
                                          {item.tag && <Tag type='testing' label='DMA' badgeType='' />}{' '}
                                          {item.text && <Typography component='span'>{item.text}</Typography>}{' '}
                                        </React.Fragment>
                                      ))
                                    ) : (
                                      td[`data0${colIndex + 1}`]
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))
                          ) : (
                            <TableRow className='tr'>
                              <TableCell
                                colSpan={FeedTableTh.length}
                                className={`td ${isSelectCP ? '' : 'noData'}`}
                                align='center'
                              >
                                <Box className='no-data-message' sx={{ height: '400px', padding: '50px 0' }}>
                                  <Typography>Please select a CP</Typography>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </CustomTableContainer>
                  </div>
                  {isSelectCP ? (
                    <div className='bottom-shadow-wrap'>
                      <Box display='flex' justifyContent='flex-end'>
                        <Button
                          color='primary'
                          type='box'
                          size='medium'
                          label='Select Ch Input'
                          isIcon={true}
                          iconPos={'right'}
                          Icon={BtnArrowRight}
                          disabled={false}
                        />
                      </Box>
                    </div>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
            </Box>

            {/* Selected Channel Map */}
            <Box className='white-box white-box-wrap select-channel' sx={{ overflow: 'initial' }}>
              {/* fold-content 클래스 추가하였습니다.  */}
              <Box className='fold-content'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between' style={{ padding: '0.8rem 2.4rem 1.2rem' }}>
                  <Box className='left-area'>
                    <Box>
                      <Typography className='box-title-text title-label' variant='h2'>
                        Selected Channel Map
                      </Typography>
                      <Typography component='p' className='box-title-bottom-text'>
                        This channel map will be applied to <span className='font-red'>[WEE_TV]</span> platform{' '}
                        <span className='font-red'>version (5.0.0 ~ )</span>
                      </Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box display='flex' className='count-all'>
                      <Typography variant='span' className='text label'>
                        Showing All
                      </Typography>
                      <Typography variant='span' className='text value'>
                        20
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <div className='box-content'>
                  {/* inner-wrap 클래스 추가하였습니다.  */}
                  <Box className='inner-wrap'>
                    <Box className='white-box-content-top-area'>
                      <Box className='component-align-wrap' justifyContent='flex-end'>
                        <Box className='field-item' sx={{ maxWidth: '200px' }}>
                          <SelectBox
                            id='select-country'
                            size='medium'
                            maxWidth='200px'
                            shrink={true}
                            label='Channel Map List'
                            required={false}
                            selectOptions={ChannelMapListOption}
                            disabled={false}
                            placeholder=''
                          />
                        </Box>
                        <Box className='field-item' sx={{ maxWidth: '200px' }}>
                          <SelectBox
                            id='select-country'
                            size='medium'
                            maxWidth='200px'
                            shrink={true}
                            label='Channel Map Status'
                            required={false}
                            selectOptions={ChannelMapStatusOption}
                            disabled={false}
                            placeholder=''
                          />
                        </Box>
                        <Box className='field-item' sx={{ maxWidth: '200px' }}>
                          <SelectBox
                            id='select-country'
                            size='medium'
                            shrink={true}
                            maxWidth='200px'
                            label='Select Existing Reservation'
                            required={false}
                            selectOptions={ChannelMapReservationOption}
                            disabled={false}
                            placeholder=''
                          />
                        </Box>
                        <Box className='field-item auto'>
                          <Button
                            color=''
                            type='only-icon'
                            size='small'
                            label=''
                            isIcon={false}
                            className='btn-layer-popup'
                          />
                        </Box>
                      </Box>
                    </Box>

                    {/* content-inner-wrap 삭제하였습니다. */}
                    {/* Table */}
                    <CustomTableContainer className='table-container'>
                      <Table className='custom-table auto' aria-label='table' stickyHeader>
                        {/* table Head */}
                        <TableHead className='thead'>
                          <TableRow className='tr'>
                            {ChannelMapSelectedTh.map((column, index) => (
                              <TableCell
                                key={column.id}
                                className='th'
                                align={column.align}
                                sx={{ width: column.width }}
                              >
                                {column.checkbox ? (
                                  <Box component='div' sx={{ display: 'flex', gap: '8px' }}>
                                    <Box
                                      component='div'
                                      sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                    >
                                      <CheckBox
                                        label=''
                                        value='select-all'
                                        defaultChecked={false}
                                        inputProps={{
                                          'aria-label': 'select all',
                                        }}
                                        style={{ width: '24px' }}
                                      />
                                    </Box>
                                    <TableSortLabel
                                      active={orderBy === column.id}
                                      direction={orderBy === column.id ? order : 'desc'}
                                      onClick={() => handleRequestSort(column.id)}
                                    >
                                      {column.label}
                                    </TableSortLabel>
                                  </Box>
                                ) : (
                                  <>
                                    {column.sortable ? (
                                      <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'desc'}
                                        onClick={() => handleRequestSort(column.id)}
                                      >
                                        {column.label}
                                      </TableSortLabel>
                                    ) : (
                                      column.label
                                    )}
                                  </>
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        {/* table Body */}
                        <TableBody className='tbody'>
                          {isSelected ? (
                            sortedContentRows.map((td, index) => (
                              <TableRow className={`tr ${td.unsaved ? 'unsaved' : ''}`} key={td.id || index}>
                                {ChannelMapSelectedTh.map((column, colIndex) => (
                                  <TableCell
                                    key={column.id}
                                    className='td'
                                    align={column.align}
                                    sx={{ height: '88px' }}
                                  >
                                    {colIndex === 0 ? (
                                      <Box display='flex' gap='3px'>
                                        <CheckBox label='' value='value' defaultChecked={false} />
                                        <CustomImage
                                          src={td[`data0${colIndex + 1}`]} // 이미지 경로를 동적으로 가져옴
                                          sx={{ width: 50, height: 64 }}
                                          alt=''
                                          rounded
                                        />
                                      </Box>
                                    ) : colIndex === 1 ? (
                                      <TextField
                                        type='outlined'
                                        size='medium'
                                        placeholder=''
                                        defaultValue=''
                                        label=''
                                        autoComplete='off'
                                        InputLabelProps={{ shrink: false }}
                                      />
                                    ) : colIndex === 2 ? (
                                      <TextField
                                        type='outlined'
                                        size='medium'
                                        placeholder=''
                                        defaultValue=''
                                        label=''
                                        autoComplete='off'
                                        InputLabelProps={{ shrink: false }}
                                      />
                                    ) : colIndex === 3 ? (
                                      <TextLink href='' text={td[`data0${colIndex + 1}`]} />
                                    ) : colIndex === 7 ? (
                                      <Button
                                        color={td.data08}
                                        type='outline'
                                        size='small'
                                        label='DMA'
                                        isIcon={false}
                                        Icon=''
                                        iconPos={undefined}
                                        autosize
                                      />
                                    ) : (
                                      td[`data0${colIndex + 1}`]
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))
                          ) : (
                            <TableRow className='tr'>
                              <TableCell
                                colSpan={ChannelMapSelectedTh.length}
                                className={`td ${isSelected ? '' : 'noData'}`}
                                align='center'
                              >
                                <Box className='no-data-message' sx={{ height: '400px', padding: '50px 0' }}>
                                  <Typography>Please set up the Channel Map</Typography>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </CustomTableContainer>
                    {/* // Table */}
                  </Box>

                  <div className='bottom-shadow-wrap'>
                    <Box display='flex' justifyContent={'space-between'}>
                      <CustomButtonGroup buttons={SelectedChannelButtons1} justifyContent='flex-start' gap='4px' />
                      <CustomButtonGroup buttons={SelectedChannelButtons2} justifyContent='flex-end' gap='4px' />
                    </Box>
                  </div>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ChannelMapping;
