import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import CheckBox from 'components/component/BasicCheckBox';
import CustomTabs from 'components/component/BasicTabs';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import DatePicker from 'components/component/BasicDatePicker';
import Pagination from 'components/component/BasicPagination';
import SearchField from 'components/component/BasicSearchField';
import SelectBox from 'components/component/BasicSelectBox';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from 'components/component/BasicTooltip';
import TextLink from 'components/component/BasicTextLink';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  ListItemText,
} from '@mui/material';
import { PrimaryInfoIcon } from 'components/component/BasicIcon';

import { styled } from '@mui/material/styles';

// data
import {
  CountryOption,
  statusData,
  ChannelMapOption,
  selectOption,
  DeviceNotificationTh,
  DeviceNotificationTd,
} from 'pages/Organization/DeviceNotification/data/DeviceNotificationData';

// Tab Name
const tabs = [{ label: 'TV' }, { label: 'Mobile' }];

// Button
const crudButtons = [
  {
    color: 'primary',
    type: 'box',
    size: 'small',
    label: 'Add',
    isIcon: false,
    autosize: false,
  },
  {
    color: 'tertiary',
    type: 'box',
    size: 'small',
    label: 'Testing',
    isIcon: false,
    autosize: true,
  },
];

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// Tooltip 내부에 출력될 자식 컴포넌트 //
const InfoTooltip = (props, ref) => {
  return (
    <Box className='tooltip-info-box' sx={{ padding: '0 12px' }}>
      {props.list.map((infoField, i) => (
        <Box key={i} className='info'>
          <ListItemText className='label'>{`${infoField.country} `}</ListItemText>
        </Box>
      ))}
    </Box>
  );
};

// 메인 컨텐츠
function DeviceNotification() {
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Device Notification',
  };

  // Tab state
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // Mapping Type - Tooltip 내용
  const countryInfo = [
    {
      id: '',
      country: 'country1',
    },
    {
      country: 'country2',
    },
    {
      country: 'country3',
    },
    {
      country: 'country4',
    },
  ];

  // TableBody Row
  const sortedPrTdRows = sortRows([...DeviceNotificationTd], orderBy, order);

  return (
    <Box sx={{ display: 'flex' }} className='wrapper'>
      <Box className='main-container pr-page-container' component='main'>
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Typography component='p' className='title'>
                Device Notification
              </Typography>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>
        {/*  //BreadCrumb Area */}

        <Box className='content-wrap ' component='div'>
          <div className='tab-container'>
            <CustomTabs tabStyle='type-01' value={tabValue} handleChange={handleTabChange} tabs={tabs} />

            {tabValue === 0 && (
              // Tab 1
              <div className='tab-panel pr-page'>
                <div className='panel-wrap'>
                  {/* 필터영역 */}
                  <Box component='div' className='white-box filter-box'>
                    <Box className='form-wrap-box'>
                      <Box className='form-area left'>
                        <Box className='component-wrap col-5'>
                          {/* Country */}
                          <Box component='div' className='col'>
                            <SelectBox
                              id='deviceNoti-country'
                              size='large'
                              shrink={true}
                              label='Country'
                              required={false}
                              selectOptions={CountryOption}
                              disabled={false}
                              placeholder=''
                            />
                          </Box>
                          {/* Channel Map */}
                          <Box component='div' className='col'>
                            <SelectBox
                              id='deviceNoti-ChannelMap'
                              size='large'
                              shrink={true}
                              label='Channel Map'
                              required={true}
                              selectOptions={ChannelMapOption}
                              disabled={false}
                              placeholder=''
                            />
                          </Box>
                          {/* status */}
                          <Box component='div' className='col'>
                            <SelectBox
                              id='deviceNoti-statusData'
                              size='large'
                              shrink={true}
                              label='Status'
                              required={false}
                              selectOptions={statusData}
                              disabled={false}
                              placeholder=''
                            />
                          </Box>

                          {/* Date */}
                          {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}

                          <Box component='div' className='col auto'>
                            <DatePicker
                              format='MM/DD/YYYY'
                              size='large'
                              singleDate={true}
                              rangeDate={false}
                              wave={true}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <div className='form-area right'></div>
                    </Box>
                  </Box>

                  <Box className='white-box white-box-wrap'>
                    <div className='box-content'>
                      <Box className='white-box-content-top-area'>
                        <Box className='left-area'>
                          <SelectBox
                            id='select-search'
                            size='medium'
                            selectOptions={selectOption}
                            label=''
                            maxWidth='180px'
                            disabled={false}
                            placeholder=''
                          />

                          <SearchField placeholder='Please enter a search term' maxWidth='230px' />
                        </Box>

                        <Box className='right-area'>
                          <CustomButtonGroup buttons={crudButtons} justifyContent='flex-end' />
                        </Box>
                      </Box>
                      <Box className='content-inner-wrap'>
                        {/* Table */}
                        <CustomTableContainer className='table-container'>
                          <Table className='custom-table' aria-label='table' stickyHeader>
                            {/* table Head */}
                            <TableHead className='thead'>
                              <TableRow className='tr'>
                                {DeviceNotificationTh.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    className='th'
                                    align={column.align}
                                    sx={{ width: column.width }}
                                  >
                                    {column.checkbox ? (
                                      <>
                                        <CheckBox
                                          label=''
                                          value='select-all'
                                          defaultChecked={false}
                                          inputProps={{
                                            'aria-label': 'select all',
                                          }}
                                        />
                                        <TableSortLabel
                                          active={orderBy === column.id}
                                          direction={orderBy === column.id ? order : 'desc'}
                                          onClick={() => handleRequestSort(column.id)}
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      </>
                                    ) : (
                                      <>
                                        {column.sortable ? (
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'desc'}
                                            onClick={() => handleRequestSort(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        ) : (
                                          column.label
                                        )}
                                      </>
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            {/* table Body */}
                            <TableBody className='tbody'>
                              {sortedPrTdRows.map((td, index) => (
                                <TableRow className='tr' key={td.id || index}>
                                  {DeviceNotificationTh.map((column, colIndex) => (
                                    <TableCell key={column.id} className='td' align={column.align}>
                                      {colIndex === 0 ? (
                                        <Box display='flex'>
                                          <CheckBox label='' value='value' defaultChecked={false} />
                                          <Typography>{td.data01.label}</Typography>
                                          {td.data01.tooltip ? (
                                            <div className='td-tooltip-wrap'>
                                              <Tooltip title={<InfoTooltip list={countryInfo} />} placement='bottom'>
                                                <IconButton
                                                  size='small'
                                                  sx={{ width: '24px', height: '24px', marginLeft: '4px' }}
                                                >
                                                  <PrimaryInfoIcon />
                                                </IconButton>
                                              </Tooltip>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </Box>
                                      ) : colIndex === 1 ? (
                                        <TextLink href='' text={td[`data0${colIndex + 1}`]} />
                                      ) : colIndex === 7 ? (
                                        <Button
                                          color='tertiary'
                                          type='outline'
                                          size='small'
                                          label='HISTORY'
                                          isIcon={false}
                                          autosize
                                        />
                                      ) : (
                                        <span>{td[`data0${colIndex + 1}`]}</span>
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </CustomTableContainer>
                        {/* // Table */}
                      </Box>

                      {/* Pagination */}
                      <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
                    </div>
                  </Box>
                </div>
              </div>
            )}
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default DeviceNotification;
