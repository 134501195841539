import React, { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';

import Tag from 'components/component/BasicTag';
import Tooltip from 'components/component/BasicTooltip';
import CustomTabs from 'components/component/BasicTabs';
import Snackbar from 'components/component/BasicSnackbar';

import {
  Button,
  Box,
  Checkbox,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';

import { styled } from '@mui/system';

// Icon
import { ArrowRight24, FileIcon } from 'components/component/BasicIcon';
// css
import 'assets/scss/page/main.scss';

// image
import FavoriteCheckOff from 'assets/images/icon/ic_favorite_off_32_outline.svg';
import FavoriteCheckOn from 'assets/images/icon/ic_favorite_on_32_outline.svg';
import FavoriteCheckOn_dark from 'assets/images/icon/ic_favorite_on_24_outline_darkmode.svg';

// data
import { activityData, recentlyData, tabs, NotiDatas, NotiDatas2, NotiDatas3, NotiDatas4 } from './data/MainData';

// Recently Used Box Contents
const FavoriteCheck = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin: 0;

    & .MuiButtonBase-root {
      &:hover {
        background: none;
      }
    }

    & input[type='checkbox'] {
      & + .MuiSvgIcon-root {
        width: 32px;
        height: 32px;
        background: url(${FavoriteCheckOff});

        & path {
          display: none;
        }
      }

      &:checked {
        & + .MuiSvgIcon-root {
          width: 32px;
          height: 32px;
          background: url(${FavoriteCheckOn});

          .dark & {
            background: url(${FavoriteCheckOn_dark});
          }
        }
      }
    }

    & .Mui-checked {
      & + .MuiTypography-root {
        & .MuiTypography-root {
          color: #000;

          .dark & {
            color: #fff;
          }
        }
      }
    }
  }
`;

const CardContainer = styled('div')({
  display: 'block',
});
const BoxContainer = styled('div')({
  paddingRight: '3px',
});
const StyledLink = styled('a')`
  width: 24px;
  height: 24px;
  display: inline-block;
`;

function Main(props) {
  // Notification
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container' component='main' sx={{ flexGrow: 1 }}>
        <Box className='main-wrap' component='div'>
          <Grid container spacing={2.5} columnSpacing={{ md: 2.5 }} className='activity-box left-container'>
            {/* Activity */}
            <Grid item xs={11.7}>
              <Box className='white-box white-box-wrap activity'>
                <div className='title-wrap'>
                  <div className='box-title-area'>
                    <Typography variant='h2' className='box-title'>
                      Activity
                    </Typography>
                  </div>
                </div>

                <CardContainer className='card-container'>
                  <BoxContainer className='box-container'>
                    <Box className='box-wrap'>
                      {activityData.map((data, index) => (
                        <React.Fragment key={data.id}>
                          {/* List */}
                          <Card className='card-box'>
                            <div className='card-header'>
                              <Typography variant='body2' className='user-name'>
                                <Link href='#' underline='hover'>
                                  {data.user}
                                </Link>
                              </Typography>
                              <Link href='#' className='link-item' underline='none'>
                                <Tooltip placement='top' title='Click to navigate to the Channel Mapping screen.'>
                                  <Typography variant='body2' className='channel'>
                                    updated the
                                    <span className='channel-name'>{data.Ch_name}</span>
                                  </Typography>
                                </Tooltip>
                              </Link>
                              <Link href='#' className='link-item' underline='none'>
                                <Typography variant='body2' className='location'>
                                  ({data.location}
                                </Typography>

                                <Typography variant='body2' className='current'>
                                  {data.current_location})
                                </Typography>
                              </Link>
                              <Tag type={data.chip_type} label={data.chip_label} badgeType='' className='chip' />
                            </div>

                            <CardContent className='card-content'>
                              <Typography variant='body2' className='text-label'>
                                Update Content
                              </Typography>
                              <Typography variant='string' className='update-content'>
                                {data.updateContent}
                              </Typography>
                              <Typography variant='caption' className='update-date'>
                                {data.date}
                              </Typography>
                            </CardContent>
                          </Card>
                          {/* //List */}
                          <Divider sx={{ marginY: 1 }} className='divider' />
                        </React.Fragment>
                      ))}
                    </Box>
                  </BoxContainer>
                </CardContainer>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2.5} direction='row' className='right-container'>
            {/* Notification */}
            <Grid item sm={12}>
              {/* 하얀박스 : white-box white-box-wrap */}
              <Box className='white-box white-box-wrap notification'>
                {/* Box Title */}
                <div className='title-wrap'>
                  <div className='box-title-area'>
                    <Typography variant='h2' className='bot-title-text'>
                      Notification
                    </Typography>
                  </div>

                  <Link href='#' underline='none' className='title-link'>
                    <ArrowRight24 />
                  </Link>
                </div>
                <div className='notification-list-container'>
                  <CustomTabs value={value} handleChange={handleChange} tabs={tabs} />

                  {[NotiDatas, NotiDatas2, NotiDatas3, NotiDatas4].map(
                    (dataList, tabIndex) =>
                      value === tabIndex && (
                        <div className='tab-panel' key={tabIndex}>
                          <div className='panel-wrap'>
                            <List>
                              {dataList.map((data) => (
                                <ListItem key={data.id}>
                                  <ListItemText
                                    primary={
                                      <div className={`row ${data.new}`}>
                                        <div className='col col-01'>
                                          <Typography component='span' variant='body1' sx={{ fontSize: '14px' }}>
                                            {data.id}
                                          </Typography>
                                        </div>
                                        <div className='col col-02'>
                                          {data.new && <Tag type='new' label='new' badgeType='new' className='chip' />}
                                        </div>
                                        <div className='col col-03'>
                                          <Typography component='p' variant='body1' sx={{ fontSize: '14px' }}>
                                            <Link href='#' underline='hover'>
                                              {data.type}
                                              {data.content}
                                            </Link>
                                          </Typography>
                                        </div>
                                        <div className='col col-04'>
                                          {data.addFile && (
                                            <StyledLink href='' className='file-icon'>
                                              <FileIcon />
                                            </StyledLink>
                                          )}
                                          <Typography component='span' variant='body1' className='notifi-date'>
                                            {data.date}
                                          </Typography>
                                        </div>
                                      </div>
                                    }
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </Box>
            </Grid>

            {/* Recently Used Menu */}
            <Grid item sm={12}>
              {/* 하얀박스 : white-box white-box-wrap */}
              <Box className='white-box white-box-wrap recently'>
                {/* Box Title */}
                <div className='title-wrap'>
                  <div className='box-title-area'>
                    <Typography variant='h2' className='box-title'>
                      Recently Used Menu
                    </Typography>
                  </div>
                </div>
                <CardContainer className='card-container'>
                  <BoxContainer className='box-container'>
                    <List className='recently-used'>
                      {recentlyData.map((recentData) => (
                        <ListItem key={recentData.id} className='list-item'>
                          <FavoriteCheck
                            control={<Checkbox />}
                            className='menu-list-left'
                            label={
                              <Box component='div'>
                                <Typography className='channelTitle' component='div' variant='body1'>
                                  {recentData.channelTitle}
                                </Typography>
                                <Typography className='channelBreadCrumb' component='div' variant='body1'>
                                  {recentData.channelBreadCrumb}
                                </Typography>
                              </Box>
                            }
                          />

                          {/* 즐겨찾기 메뉴 바로가기 */}
                          <Button href='/' variant='' className='link-arrow'>
                            <ArrowRight24 />
                          </Button>
                        </ListItem>
                      ))}
                    </List>
                  </BoxContainer>
                </CardContainer>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* severity: default, error, warning, info, success */}
      <Snackbar severity='success' message='success' open={false} duration={1000} bottom={50}/>
    </Box>
  );
}

export default Main;
