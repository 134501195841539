import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import SelectBox from 'components/component/BasicSelectBox';
import TableSortLabel from '@mui/material/TableSortLabel';
import CustomTabs from 'components/component/BasicTabs';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import CheckBox from 'components/component/BasicCheckBox';
import Button from 'components/component/BasicButton';
import TextField from 'components/component/BasicTextField';
import SearchField from 'components/component/BasicSearchField';
import CustomImage from 'components/component/BasicImage';
import TextLink from 'components/component/BasicTextLink';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// data
import {
  Region,
  CountryData,
  DeviceSelectData,
  ChannelMaptData,
  CPNameData,
  ChannelNameSelectData,
  selectOption,
  MetaProgramTh,
  MetaProgramTd,
  MetaChannelTh,
  MetaChannelTd,
  MetaVodTh,
  MetaVodTd,
  MetaSeriesTh,
  MetaSeriesTd,
} from 'pages/Inspect/Metadata/data/MetaData';

// Icon
import { RefreshIcon } from 'components/component/BasicIcon';

// Tab Name
const tabs = [{ label: 'Channel' }, { label: 'Program' }, { label: 'VOD' }, { label: 'Series' }];

// Button Data
const buttons = [
  {
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    label: 'Reset',
    isIcon: true,
    Icon: RefreshIcon,
    iconPos: 'left',
    className: 'custom-save-icon',
    autosize: true,
  },
  {
    minWidth: '120px',
    color: 'primary',
    type: 'box',
    size: 'medium',
    label: 'Search',
    isIcon: false,
  },
];

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Table line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// 메인 컨텐츠
function Metadata() {

  // BreadCrumb Data
  const optionMenu = {
    gnbMenu: 'Inspection',
    lnbMenu: 'Metadata',
  };

  // Tab
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const sortedMetaChannelTdRows = sortRows([...MetaChannelTd], orderBy, order);
  const sortedMetaProgramTdRows = sortRows([...MetaProgramTd], orderBy, order);
  const sortedMetaSeriesTdRows = sortRows([...MetaSeriesTd], orderBy, order);
  const sortedVodTdRows = sortRows([...MetaVodTd], orderBy, order);

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container inspect' component='main'>
        {/* BreadCrumb Area */}
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Typography className='title'>Metadata</Typography>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>

        <Box className='content-wrap' component='div'>
          <div className='tab-container'>
            <CustomTabs tabStyle='type-01' value={tabValue} handleChange={handleTabChange} tabs={tabs} />

            {/* Program Schedule */}
            {tabValue === 0 && (
              // Tab 1
              <div className='tab-panel'>
                <div className='panel-wrap'>
                  <Box component='div' className='white-box filter-box'>
                    <Box className='form-wrap-box'>
                      <div className='form-area left'>
                        <Box component='div' className='component-wrap col-5'>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='tab1-select-region'
                              size='large'
                              shrink={true}
                              label='Region'
                              required={false}
                              selectOptions={Region}
                              disabled={false}
                              placeholder=''
                            />
                          </Box>

                          <Box component='div' className='col'>
                            <SelectBox
                              id='tab1-country'
                              size='large'
                              shrink={true}
                              label='Country'
                              selectOptions={CountryData}
                              disabled={false}
                              placeholder=''
                            />
                          </Box>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='select-Device'
                              fullWidth
                              size='large'
                              width='100%'
                              selectOptions={DeviceSelectData}
                              disabled={false}
                              required={false}
                              label='Device Type'
                              placeholder=''
                              shrink
                            />
                          </Box>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='Channel-a93'
                              fullWidth
                              size='large'
                              width='100%'
                              selectOptions={ChannelMaptData}
                              disabled={false}
                              required={false}
                              label='Channel Map ID'
                              placeholder='Select'
                              shrink
                            />
                          </Box>
                        </Box>

                        <Box component='div' className='component-wrap col-5'>
                          <Box component='div' className='col'>
                            <TextField
                              type='outlined'
                              size='large'
                              placeholder='Channel No'
                              defaultValue=''
                              label=''
                              autoComplete='off'
                              InputLabelProps={{ shrink: false }}
                            />
                          </Box>

                          <Box component='div' className='col'>
                            <TextField
                              type='outlined'
                              size='large'
                              placeholder='Channel ID'
                              defaultValue=''
                              label=''
                              autoComplete='off'
                              InputLabelProps={{ shrink: false }}
                            />
                          </Box>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='select-Channel'
                              fullWidth
                              size='large'
                              width='100%'
                              selectOptions={ChannelNameSelectData}
                              disabled={false}
                              required={false}
                              label='Channel Name'
                              placeholder='Select'
                              shrink
                            />
                          </Box>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='Cp014'
                              fullWidth
                              size='large'
                              width='100%'
                              selectOptions={CPNameData}
                              disabled={false}
                              required={false}
                              label='CP Name'
                              placeholder='Select'
                              shrink
                            />
                          </Box>
                        </Box>
                      </div>
                      <div className='form-area right'>
                        <CustomButtonGroup buttons={buttons} />
                      </div>
                    </Box>
                  </Box>

                  <Box className='white-box white-box-wrap'>
                    <div className='box-content'>
                      <Box className='white-box-content-top-area'>
                        <Box className='left-area'>
                          <Box className='total-count'>
                            <Box component='span' className='label'>
                              Total
                            </Box>
                            <Box component='span' className='value'>
                              127
                            </Box>
                          </Box>
                        </Box>
                        <Box className='right-area'>
                          <Button
                            color='tertiary'
                            type='outline'
                            size='small'
                            label='Excel Dounload'
                            isIcon={false}
                            autosize
                          />
                          <SelectBox
                            id='select-search'
                            size='medium'
                            selectOptions={selectOption}
                            label=''
                            maxWidth='180px'
                            disabled={false}
                            placeholder=''
                          />

                          <SearchField
                            id='search-2'
                            size='medium'
                            maxWidth='230px'
                            placeholder='Please enter a search term'
                          />
                        </Box>
                      </Box>
                      <Box className='content-inner-wrap'>
                        {/* Table */}
                        <CustomTableContainer className='table-container'>
                          <Table className='custom-table' aria-label='table' stickyHeader>
                            {/* table Head */}
                            <TableHead className='thead'>
                              <TableRow className='tr'>
                                {MetaChannelTh.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    className='th'
                                    align={column.align}
                                    sx={{ width: column.width }}
                                  >
                                    {column.checkbox ? (
                                      <>
                                        <CheckBox
                                          label=''
                                          value='select-all'
                                          defaultChecked={false}
                                          inputProps={{
                                            'aria-label': 'select all',
                                          }}
                                        />
                                        <TableSortLabel
                                          active={orderBy === column.id}
                                          direction={orderBy === column.id ? order : 'desc'}
                                          onClick={() => handleRequestSort(column.id)}
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      </>
                                    ) : (
                                      <>
                                        {column.sortable ? (
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'desc'}
                                            onClick={() => handleRequestSort(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        ) : (
                                          column.label
                                        )}
                                      </>
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            {/* table Body */}
                            <TableBody className='tbody'>
                              {sortedMetaChannelTdRows.map((td, index) => (
                                <TableRow className='tr' key={td.id || index}>
                                  {MetaChannelTh.map((column, colIndex) => (
                                    <TableCell key={column.id} className='td' align={column.align}>
                                      {colIndex === 0 ? (
                                        <CustomImage
                                          src={td[`data0${colIndex + 1}`]}
                                          fit={false}
                                          alt=''
                                          wrapWidth='122px'
                                          wrapHeight='64px'
                                          rounded
                                        />
                                      ) : colIndex === 1 ? (
                                        <TextLink href='' text={td[`data0${colIndex + 1}`]} />
                                      ) : colIndex === 6 ? (
                                        <Button
                                          color='tertiary'
                                          type='outline'
                                          size='small'
                                          label='DETAIL'
                                          isIcon={false}
                                          Icon=''
                                          iconPos={undefined}
                                          sx={{ minWidth: 'auto' }}
                                        />
                                      ) : (
                                        td[`data0${colIndex + 1}`]
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </CustomTableContainer>
                        {/* // Table */}
                      </Box>

                      <Pagination id='pagination-01' count={5} selOptionData={selOptionData} />
                    </div>
                  </Box>
                </div>
              </div>
            )}

            {/* Program Schedule(List) */}
            {tabValue === 1 && (
              <div className='tab-panel'>
                <div className='panel-wrap'>
                  {/* 필터영역 */}
                  <Box component='div' className='white-box filter-box'>
                    <Box className='form-wrap-box'>
                      <div className='form-area left'>
                        {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
                        <Box className='component-wrap col-5'>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='t2-Region'
                              size='large'
                              shrink={true}
                              label='Region'
                              required={false}
                              selectOptions={Region}
                              disabled={false}
                              placeholder=''
                            />
                          </Box>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='tab2-country'
                              size='large'
                              shrink={true}
                              label='Country'
                              selectOptions={CountryData}
                              disabled={false}
                              placeholder='CP Name'
                            />
                          </Box>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='select-cp'
                              size='large'
                              shrink={true}
                              label='CP Name'
                              selectOptions={CPNameData}
                              disabled={false}
                              placeholder='Genre'
                            />
                          </Box>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='tab2-channel-name'
                              size='large'
                              shrink={true}
                              label='Channel Name'
                              selectOptions={ChannelNameSelectData}
                              disabled={false}
                              placeholder='Genre'
                            />
                          </Box>
                          <Box component='div' className='col'>
                            <TextField
                              id='p-id'
                              type='outlined'
                              size='large'
                              placeholder='Program ID'
                              defaultValue=''
                              label=''
                              autoComplete='off'
                              InputLabelProps={{ shrink: false }}
                            />
                          </Box>
                        </Box>
                      </div>
                      <div className='form-area right'>
                        <CustomButtonGroup buttons={buttons} />
                      </div>
                    </Box>
                  </Box>

                  <Box className='white-box white-box-wrap'>
                    <div className='box-content'>
                      <Box className='white-box-content-top-area'>
                        <Box className='left-area'>
                          <Box className='total-count'>
                            <Box component='span' className='label'>
                              Total
                            </Box>
                            <Box component='span' className='value'>
                              127
                            </Box>
                          </Box>
                        </Box>
                        <Box className='right-area'>
                          <Button
                            color='tertiary'
                            type='outline'
                            size='small'
                            label='Excel Dounload'
                            isIcon={false}
                            autosize
                          />
                          <SelectBox
                            id='select-search'
                            size='medium'
                            selectOptions={selectOption}
                            label=''
                            maxWidth='180px'
                            disabled={false}
                            placeholder=''
                          />

                          <SearchField
                            id='search-2'
                            size='medium'
                            maxWidth='230px'
                            placeholder='Please enter a search term'
                          />
                        </Box>
                      </Box>
                      <Box className='content-inner-wrap'>
                        {/* Table */}
                        <CustomTableContainer className='table-container'>
                          <Table className='custom-table' aria-label='table' stickyHeader>
                            {/* table Head */}
                            <TableHead className='thead'>
                              <TableRow className='tr'>
                                {MetaProgramTh.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    className='th'
                                    align={column.align}
                                    sx={{ width: column.width }}
                                  >
                                    {column.checkbox ? (
                                      <>
                                        <CheckBox
                                          label=''
                                          value='select-all'
                                          defaultChecked={false}
                                          inputProps={{
                                            'aria-label': 'select all',
                                          }}
                                        />
                                        <TableSortLabel
                                          active={orderBy === column.id}
                                          direction={orderBy === column.id ? order : 'desc'}
                                          onClick={() => handleRequestSort(column.id)}
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      </>
                                    ) : (
                                      <>
                                        {column.sortable ? (
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'desc'}
                                            onClick={() => handleRequestSort(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        ) : (
                                          column.label
                                        )}
                                      </>
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            {/* table Body */}
                            <TableBody className='tbody'>
                              {sortedMetaProgramTdRows.map((td, index) => (
                                <TableRow className='tr' key={td.id || index}>
                                  {MetaProgramTh.map((column, colIndex) => (
                                    <TableCell key={column.id} className='td' align={column.align}>
                                      {colIndex === 2 ? (
                                        <CustomImage
                                          src={td[`data0${colIndex + 1}`]}
                                          fit={false}
                                          alt=''
                                          wrapWidth='122px'
                                          wrapHeight='64px'
                                          rounded
                                        />
                                      ) : colIndex === 3 ? (
                                        <TextLink href='' text={td[`data0${colIndex + 1}`]} />
                                      ) : colIndex === 8 ? (
                                        <Button
                                          color='tertiary'
                                          type='outline'
                                          size='small'
                                          label='DETAIL'
                                          isIcon={false}
                                          Icon=''
                                          iconPos={undefined}
                                          sx={{ minWidth: 'auto' }}
                                        />
                                      ) : (
                                        td[`data0${colIndex + 1}`]
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </CustomTableContainer>
                        {/* // Table */}
                      </Box>

                      <Pagination id='pagination-01' count={5} selOptionData={selOptionData} />
                    </div>
                  </Box>
                </div>
              </div>
            )}

            {/* Vod */}
            {tabValue === 2 && (
              <div className='tab-panel'>
                <div className='panel-wrap'>
                  {/* 필터영역 */}
                  <Box component='div' className='white-box filter-box'>
                    <Box className='form-wrap-box'>
                      <div className='form-area left'>
                        {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
                        <Box className='component-wrap col-6'>
                          {/* Region */}
                          <Box component='div' className='col' sx={{ position: 'relative' }}>
                            <SelectBox
                              id='tab3-Region'
                              size='large'
                              shrink={true}
                              label='Region'
                              required={false}
                              selectOptions={Region}
                              disabled={false}
                              placeholder=''
                            />
                          </Box>

                          {/* Country */}
                          <Box component='div' className='col'>
                            <SelectBox
                              id='tab3-country'
                              size='large'
                              shrink={true}
                              required={false}
                              label='Country'
                              selectOptions={CountryData}
                              disabled={false}
                              placeholder='CP Name'
                            />
                          </Box>

                          {/* CP Name */}
                          <Box component='div' className='col'>
                            <SelectBox
                              id='tab3-CPName'
                              fullWidth
                              size='large'
                              width='100%'
                              required={false}
                              selectOptions={CPNameData}
                              disabled={false}
                              label='CP Name'
                              placeholder='Select'
                              shrink
                            />
                          </Box>

                          {/* Type */}
                          <Box component='div' className='col'>
                            <SelectBox
                              id='tab3-Type'
                              fullWidth
                              size='large'
                              width='100%'
                              required={false}
                              selectOptions={CPNameData}
                              disabled={false}
                              label='Type'
                              placeholder='Select'
                              shrink
                            />
                          </Box>

                          {/* VOD ID */}
                          <Box component='div' className='col'>
                            <TextField
                              id='tab3-VODID'
                              type='outlined'
                              size='large'
                              placeholder='VOD ID'
                              defaultValue=''
                              label=''
                              autoComplete='off'
                              InputLabelProps={{ shrink: false }}
                            />
                          </Box>

                          {/* VOD Title */}
                          <Box component='div' className='col'>
                            <TextField
                              id='tab3-VODTitle'
                              type='outlined'
                              size='large'
                              placeholder='VOD Title'
                              defaultValue=''
                              label=''
                              autoComplete='off'
                              InputLabelProps={{ shrink: false }}
                            />
                          </Box>
                        </Box>
                      </div>
                      <div className='form-area right'>
                        <CustomButtonGroup buttons={buttons} />
                      </div>
                    </Box>
                  </Box>

                  <Box className='white-box white-box-wrap'>
                    <div className='box-content'>
                      <Box className='white-box-content-top-area'>
                        <Box className='left-area'>
                          <Box className='total-count'>
                            <Box component='span' className='label'>
                              Total
                            </Box>
                            <Box component='span' className='value'>
                              127
                            </Box>
                          </Box>
                        </Box>
                        <Box className='right-area'>
                          <Button
                            color='tertiary'
                            type='outline'
                            size='small'
                            label='Excel Dounload'
                            isIcon={false}
                            autosize
                          />
                          <SelectBox
                            id='select-search'
                            size='medium'
                            selectOptions={selectOption}
                            label=''
                            maxWidth='180px'
                            disabled={false}
                            placeholder=''
                          />

                          <SearchField
                            id='search-2'
                            size='medium'
                            maxWidth='230px'
                            placeholder='Please enter a search term'
                          />
                        </Box>
                      </Box>
                      <Box className='content-inner-wrap'>
                        {/* Table */}
                        <CustomTableContainer className='table-container'>
                          <Table className='custom-table' aria-label='table' stickyHeader>
                            {/* table Head */}
                            <TableHead className='thead'>
                              <TableRow className='tr'>
                                {MetaVodTh.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    className='th'
                                    align={column.align}
                                    sx={{ width: column.width }}
                                  >
                                    {column.checkbox ? (
                                      <>
                                        <CheckBox
                                          label=''
                                          value='select-all'
                                          defaultChecked={false}
                                          inputProps={{
                                            'aria-label': 'select all',
                                          }}
                                        />
                                        <TableSortLabel
                                          active={orderBy === column.id}
                                          direction={orderBy === column.id ? order : 'desc'}
                                          onClick={() => handleRequestSort(column.id)}
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      </>
                                    ) : (
                                      <>
                                        {column.sortable ? (
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'desc'}
                                            onClick={() => handleRequestSort(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        ) : (
                                          column.label
                                        )}
                                      </>
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            {/* table Body */}
                            <TableBody className='tbody'>
                              {sortedVodTdRows.map((td, index) => (
                                <TableRow className='tr' key={td.id || index}>
                                  {MetaVodTh.map((column, colIndex) => (
                                    <TableCell key={column.id} className='td' align={column.align}>
                                      {colIndex === 2 ? (
                                        <TextLink href='' text={td[`data0${colIndex + 1}`]} />
                                      ) : colIndex === 7 ? (
                                        <Button
                                          color='tertiary'
                                          type='outline'
                                          size='small'
                                          label='DETAIL'
                                          isIcon={false}
                                          Icon=''
                                          iconPos={undefined}
                                          sx={{ minWidth: 'auto' }}
                                        />
                                      ) : (
                                        td[`data0${colIndex + 1}`]
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </CustomTableContainer>
                        {/* // Table */}
                      </Box>

                      <Pagination id='pagination-01' count={5} selOptionData={selOptionData} />
                    </div>
                  </Box>
                </div>
              </div>
            )}

            {/* Series */}
            {tabValue === 3 && (
              // Tab 4
              <div className='tab-panel'>
                <div className='panel-wrap'>
                  <Box component='div' className='white-box filter-box'>
                    <Box className='form-wrap-box'>
                      <div className='form-area left'>
                        <Box component='div' className='component-wrap col-5'>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='tab4-select-Region'
                              size='large'
                              shrink={true}
                              label='Region'
                              required={false}
                              selectOptions={Region}
                              disabled={false}
                              placeholder=''
                            />
                          </Box>

                          <Box component='div' className='col'>
                            <SelectBox
                              id='tab4-country'
                              size='large'
                              shrink={true}
                              label='Country'
                              selectOptions={CountryData}
                              disabled={false}
                              placeholder=''
                            />
                          </Box>

                          <Box component='div' className='col'>
                            <SelectBox
                              id='tab4-select'
                              fullWidth
                              size='large'
                              width='100%'
                              selectOptions={CPNameData}
                              disabled={false}
                              required={false}
                              label='CP Name'
                              placeholder='Select'
                              shrink
                            />
                          </Box>
                        </Box>

                        <Box component='div' className='component-wrap col-5'>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='tab4-select-id'
                              fullWidth
                              size='large'
                              width='100%'
                              selectOptions={selectOption}
                              disabled={false}
                              required={false}
                              label=''
                              placeholder='Series ID'
                            />
                          </Box>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='tab4-select-title'
                              fullWidth
                              size='large'
                              width='100%'
                              selectOptions={selectOption}
                              disabled={false}
                              required={false}
                              label=''
                              placeholder='Series Title'
                            />
                          </Box>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='tab4-season-id'
                              fullWidth
                              size='large'
                              width='100%'
                              selectOptions={selectOption}
                              disabled={false}
                              required={false}
                              label=''
                              placeholder='Season ID'
                            />
                          </Box>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='tab4-season-title'
                              fullWidth
                              size='large'
                              width='100%'
                              selectOptions={selectOption}
                              disabled={false}
                              required={false}
                              label=''
                              placeholder='Season Title'
                            />
                          </Box>
                        </Box>
                      </div>
                      <div className='form-area right'>
                        <CustomButtonGroup buttons={buttons} />
                      </div>
                    </Box>
                  </Box>

                  <Box className='white-box white-box-wrap'>
                    <div className='box-content'>
                      <Box className='white-box-content-top-area'>
                        <Box className='left-area'>
                          <Box className='total-count'>
                            <Box component='span' className='label'>
                              Total
                            </Box>
                            <Box component='span' className='value'>
                              127
                            </Box>
                          </Box>
                        </Box>
                        <Box className='right-area'>
                          <Button
                            color='tertiary'
                            type='outline'
                            size='small'
                            label='Excel Dounload'
                            isIcon={false}
                            autosize
                          />
                          <SelectBox
                            id='select-search'
                            size='medium'
                            selectOptions={selectOption}
                            label=''
                            maxWidth='180px'
                            disabled={false}
                            placeholder=''
                          />

                          <SearchField
                            id='search-2'
                            size='medium'
                            maxWidth='230px'
                            placeholder='Please enter a search term'
                          />
                        </Box>
                      </Box>
                      <Box className='content-inner-wrap'>
                        {/* Table */}
                        <CustomTableContainer className='table-container'>
                          <Table className='custom-table' aria-label='table' stickyHeader>
                            {/* table Head */}
                            <TableHead className='thead'>
                              <TableRow className='tr'>
                                {MetaSeriesTh.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    className='th'
                                    align={column.align}
                                    sx={{ width: column.width }}
                                  >
                                    {column.checkbox ? (
                                      <>
                                        <CheckBox
                                          label=''
                                          value='select-all'
                                          defaultChecked={false}
                                          inputProps={{
                                            'aria-label': 'select all',
                                          }}
                                        />
                                        <TableSortLabel
                                          active={orderBy === column.id}
                                          direction={orderBy === column.id ? order : 'desc'}
                                          onClick={() => handleRequestSort(column.id)}
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      </>
                                    ) : (
                                      <>
                                        {column.sortable ? (
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'desc'}
                                            onClick={() => handleRequestSort(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        ) : (
                                          column.label
                                        )}
                                      </>
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            {/* table Body */}
                            <TableBody className='tbody'>
                              {sortedMetaSeriesTdRows.map((td, index) => (
                                <TableRow className='tr' key={td.id || index}>
                                  {MetaSeriesTh.map((column, colIndex) => (
                                    <TableCell key={column.id} className='td' align={column.align}>
                                      {colIndex === 3 ? (
                                        <TextLink href='' text={td[`data0${colIndex + 1}`]} />
                                      ) : (
                                        td[`data0${colIndex + 1}`]
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </CustomTableContainer>
                        {/* // Table */}
                      </Box>

                      <Pagination id='pagination-01' count={5} selOptionData={selOptionData} />
                    </div>
                  </Box>
                </div>
              </div>
            )}
          </div>
        </Box>
      </Box>
    </Box>
  );
}
export default Metadata;
