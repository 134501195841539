import * as actions from 'actions/actionTypes';

const initialState = [];

export function country(state = initialState, action) {
  switch (action.type) {
    case actions.COMMON_COUNTRY_INFO:
      return action.payload;
    default:
      return state;
  }
}