// src/data.js
import imgDummy01 from 'assets/images/img_dummy_04.png';

export const CountryData = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'Us', value: 'Us', option: 'Us', type: 'text' },
  { id: 'Asia', value: 'Asia', option: 'Asia', type: 'text' },
];

export const FreeOnTypeOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'Slider-1', value: 'Slider-1', option: 'Slider-1', type: 'text' },
  { id: 'Slider-2', value: 'Slider-2', option: 'Slider-2', type: 'text' },
];

export const WatchOnStatusOptioin = [
  { id: 1, value: 'Saved for Testing', option: 'Saved for Testing', type: 'text' },
  { id: 2, value: 'testing', option: 'testing', type: 'text' },
];
export const cpNameOption = [
  { id: 'cpName-all', value: 'All', option: 'All', type: 'text' },
  { id: 'cpName-1', value: 'Slider-1', option: 'Slider-1', type: 'text' },
  { id: 'cpName-2', value: 'Slider-2', option: 'Slider-2', type: 'text' },
];

export const TypeOptionData = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'Slider-1', value: 'Slider-1', option: 'Slider-1', type: 'text' },
  { id: 'Slider-2', value: 'Slider-2', option: 'Slider-2', type: 'text' },
];

export const ManualAutoOption = [
  { id: 'Manual', value: 'Manual', option: 'Manual', type: 'text' },
  { id: 'Auto', value: 'Auto', option: 'Auto', type: 'text' },
];
export const WatchResultTh = [
  {
    id: 'CPName',
    align: 'left',
    label: 'CP Name',
    width: '18%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'ContentID',
    align: 'left',
    label: 'Ch. ID',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ContentName',
    align: 'left',
    label: 'Ch. Name',
    width: '30%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Image',
    align: 'left',
    label: 'Image',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Genre',
    align: 'left',
    label: 'Genre',
    width: '15%',
    checkbox: false,
    sortable: false,
  },
];

export const WatchResultTd = [
  {
    id: 'cResult-1',
    data01: 'AMG',
    data02: 'wickedtunalgus_wctna_US',
    data03: 'Wicked Tuna',
    data04: imgDummy01,
    data05: 'realityTv',
  },
  {
    id: 'Watch-2',
    data01: 'AMG',
    data02: 'wickedtunalgus_wctna_US',
    data03: 'Dog The Bounty Hunter',
    data04: imgDummy01,
    data05: 'realityTv',
  },
  {
    id: 'Watch-3',
    data01: 'AMG',
    data02: 'wickedtunalgus_wctna_US',
    data03: 'Dog The Bounty Hunter',
    data04: imgDummy01,
    data05: 'realityTv',
  },
];
export const ScheduledContentTh = [
  {
    id: 'Free-Order',
    align: 'left',
    label: 'Order',
    width: '15%',
    checkbox: true,
    sortable: true,
  },

  {
    id: 'Free-CPName',
    align: 'left',
    label: 'CP Name',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Free-Content',
    align: 'left',
    label: 'Ch. ID',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Free-ContentName',
    align: 'left',
    label: 'Ch. name',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Free-Fix',
    align: 'left',
    label: 'Fix',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Free-Image',
    align: 'left',
    label: 'Image',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Free-Genre',
    align: 'left',
    label: 'Genre',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
];

export const ScheduledContentTd = [
  {
    id: 'FreeTd-1',
    data01: '1',
    data02: 'AMG',
    data03: 'Xumo Free MoviesLG DRM Test Channel',
    data04: 'A Rainy Day in New York',
    data05: '',
    data06: imgDummy01,
    data07: 'sport',
  },
];

export const ShelfManualTh = [
  {
    id: 'check',
    align: 'left',
    label: '',
    width: '6%',
    checkbox: true,
    sortable: false,
  },
  {
    id: 'CPName',
    align: 'left',
    label: 'CP Name',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ContentID',
    align: 'left',
    label: 'Ch. ID',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ContentName',
    align: 'left',
    label: 'Ch. Name',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Image',
    align: 'left',
    label: 'Image',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'Genre',
    align: 'left',
    label: 'Genre',
    width: '16%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'LisenceDate',
    align: 'left',
    label: 'Lisence Date',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];
export const ShelfManualTd = [
  {
    id: 'ShelfManualTd-1',
    data01: '',
    data02: 'AMG',
    data03: 'wickedtunalgus_wctna_US',
    data04: 'A Rainy Day in New York',
    data05: imgDummy01,
    data06: { text: 'Sport+1', tooltip: true },
    data07: '2024-08-20 ~ 2040-08-20',
  },
  {
    id: 'ShelfManualTd-2',
    data01: '',
    data02: 'AMG',
    data03: 'wickedtunalgus_wctna_US',
    data04: 'A Rainy Day in New York',
    data05: '',
    data06: { text: 'Sport', tooltip: true },
    data07: '2024-08-20 ~ 2040-08-20',
  },
  {
    id: 'ShelfManualTd-3',
    data01: '',
    data02: 'AMG',
    data03: 'wickedtunalgus_wctna_US',
    data04: 'A Rainy Day in New York',
    data05: '',
    data06: { text: 'Sport+1', tooltip: false },
    data07: '2024-08-20 ~ 2040-08-20',
  },
];

export const ScheduledManualTh = [
  {
    id: 'order-sdgesg',
    align: 'left',
    label: '',
    width: '2%',
    checkbox: true,
    sortable: false,
  },
  {
    id: 'order-3453',
    align: 'left',
    label: 'Order',
    width: '5%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Type-2',
    align: 'left',
    label: 'Type',
    width: '5%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'CPName-252',
    align: 'left',
    label: 'CP Name',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Content-sdg',
    align: 'left',
    label: 'Content ID',
    width: '30%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ContentName-5',
    align: 'left',
    label: 'Content Name',
    width: '22%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Fix-6',
    align: 'left',
    label: 'Fix',
    width: '8%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'Image-7',
    align: 'left',
    label: 'Image',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const ScheduledManualTd = [
  {
    id: 'ScheduledManualTd-1',
    data01: '',
    data02: '1',
    data03: 'live',
    data04: 'AMG',
    data05: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data06: 'A Rainy Day in New York',
    data07: '',
    data08: '',
  },
  {
    id: 'ScheduledManualTd-2',
    data01: '',
    data02: '1',
    data03: 'live',
    data04: 'AMG',
    data05: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data06: 'A Rainy Day in New York',
    data07: '',
    data08: '',
  },
];

export const InteractiveData = [
  {
    id: 'count-01',
    label: '3.2',
    type: 'checkbox',
  },
  {
    id: 'count-02',
    label: '4.3',
    type: 'checkbox',
  },
  {
    id: 'count-03',
    label: 'WEE',
    type: 'checkbox',
  },
  {
    id: 'count-04',
    label: 'ME',
    type: 'checkbox',
  },
  {
    id: 'count-05',
    label: 'Auto',
    type: 'checkbox',
  },
  {
    id: 'count-06',
    label: 'Auto',
    type: 'checkbox',
  },
];

export const InteractiveTh = [
  {
    id: 'check',
    align: 'left',
    label: 'Service',
    width: '7%',
    checkbox: true,
    sortable: false,
  },
  {
    id: 'interative-Manual',
    align: 'left',
    label: 'Manual/Auto',
    width: '14%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'interative-Logo',
    align: 'left',
    label: 'Logo',
    width: '16%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'interative-No',
    align: 'left',
    label: 'Ch.No',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'interative-ChName',
    align: 'left',
    label: 'Ch.Name',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'interative-ChID',
    align: 'left',
    label: 'Ch.ID',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'interative-CPName',
    align: 'left',
    label: 'CP Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'interative-Genre',
    align: 'left',
    label: 'Genre',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'interative-Action',
    align: 'left',
    label: 'Action',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];
export const InteractiveTd = [
  {
    id: 'InteractiveTd-1',
    data01: '',
    data02: '',
    data03: '',
    data04: '605',
    data05: 'Super Wings',
    data06: 'us-jansonmedia_superwings_1',
    data07: 'Wurl',
    data08: 'Kids',
    data09: '',
  },
  {
    id: 'InteractiveTd-2',
    data01: '',
    data02: '',
    data03: '',
    data04: '605',
    data05: 'Super Wings',
    data06: 'us-jansonmedia_superwings_1',
    data07: 'Wurl',
    data08: 'Kids',
    data09: '',
  },
  {
    id: 'InteractiveTd-3',
    data01: '',
    data02: '',
    data03: '',
    data04: '605',
    data05: 'Super Wings',
    data06: 'us-jansonmedia_superwings_1',
    data07: 'Wurl',
    data08: 'Kids',
    data09: '',
  },
  {
    id: 'InteractiveTd-4',
    data01: '',
    data02: '',
    data03: '',
    data04: '605',
    data05: 'Super Wings',
    data06: 'us-jansonmedia_superwings_1',
    data07: 'Wurl',
    data08: 'Kids',
    data09: '',
  },
];

export const CategoryTitleTh = [
  {
    id: 'Classification',
    align: 'left',
    label: 'Classification',
    width: '18%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'Priority',
    align: 'left',
    label: 'Priority',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Description',
    align: 'left',
    label: 'Description',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'DefaultCategory',
    align: 'left',
    label: 'Default Category Name',
    width: '30%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Action',
    align: 'left',
    label: 'Action',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];
export const CategoryTitleTd = [
  {
    id: 'sgfs',
    data01: 'cp_normal',
    data02: '1',
    data03: 'Picks for You',
    data04: 'Alien Nation by DUST',
    data05: '',
  },
  {
    id: 'dhfdh',
    data01: 'cp_normal',
    data02: '2',
    data03: 'Picks for You',
    data04: 'Alien Nation by DUST',
    data05: '',
  },
  {
    id: 'fnf',
    data01: 'cp_normal',
    data02: '3',
    data03: 'Picks for You',
    data04: 'Alien Nation by DUST',
    data05: '',
  },
  {
    id: '34d',
    data01: 'cp_normal',
    data02: '4',
    data03: 'Picks for You',
    data04: 'Alien Nation by DUST',
    data05: '',
  },
];

export const OrganizeTh = [
  {
    id: 'sss',
    align: 'left',
    label: '',
    width: '5%',
    checkbox: true,
    sortable: false,
  },
  {
    id: 'wffe',
    align: 'left',
    label: 'CP Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'org-th-2',
    align: 'left',
    label: 'Ch.ID',
    width: '26%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'org-th-3',
    align: 'left',
    label: 'Ch.Name',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'org-th-4',
    align: 'left',
    label: 'Image',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'org-th-5',
    align: 'left',
    label: 'Genre',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const OrganizeTd = [
  {
    id: 'org-11',
    data01: '',
    data02: 'AMG',
    data03: 'wickedtunalgus_wctna_US',
    data04: 'Wicked Tuna',
    data05: '',
    data06: 'Reality TV',
  },
  {
    id: 'org-12',
    data01: '',
    data02: 'AMG',
    data03: 'wickedtunalgus_wctna_US',
    data04: 'Wicked Tuna',
    data05: '',
    data06: 'Reality TV',
  },
  {
    id: 'org-13',
    data01: '',
    data02: 'AMG',
    data03: 'wickedtunalgus_wctna_US',
    data04: 'Wicked Tuna',
    data05: '',
    data06: 'Reality TV',
  },
  {
    id: 'org-14',
    data01: '',
    data02: 'AMG',
    data03: 'wickedtunalgus_wctna_US',
    data04: 'Wicked Tuna',
    data05: '',
    data06: 'Reality TV',
  },
];

export const OrgScheduledTh = [
  {
    id: 'OrgScheduled-chk',
    align: 'left',
    label: '',
    width: '8%',
    checkbox: true,
    sortable: true,
  },

  {
    id: 'OrgScheduled-Order',
    align: 'left',
    label: 'Order',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'OrgScheduled-CPName',
    align: 'left',
    label: 'CP Name',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'OrgScheduled-ID',
    align: 'left',
    label: 'Ch.ID',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'OrgScheduled-ContentName',
    align: 'left',
    label: 'Ch. name',
    width: '16%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'OrgScheduled-Image',
    align: 'left',
    label: 'Image',
    width: '12%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'OrgScheduled-Genre',
    align: 'left',
    label: 'Genre',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const OrgScheduledTd = [
  {
    id: 'orgSch-1',
    data01: '',
    data02: '1',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'A Rainy Day in New York',
    data06: '',
    data07: 'Sport',
  },
  {
    id: 'orgSch-2',
    data01: '',
    data02: '2',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'A Rainy Day in New York',
    data06: '',
    data07: 'Sport',
  },
  {
    id: 'orgSch-3',
    data01: '',
    data02: '3',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'A Rainy Day in New York',
    data06: '',
    data07: 'Sport',
  },
  {
    id: 'orgSch-4',
    data01: '',
    data02: '4',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'A Rainy Day in New York',
    data06: '',
    data07: 'Sport',
  },
  {
    id: 'orgSch-5',
    data01: '',
    data02: '5',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'A Rainy Day in New York',
    data06: '',
    data07: 'Sport',
  },
  {
    id: 'orgSch-6',
    data01: '',
    data02: '6',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'A Rainy Day in New York',
    data06: '',
    data07: 'Sport',
  },
  {
    id: 'orgSch-7',
    data01: '',
    data02: '7',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'A Rainy Day in New York',
    data06: '',
    data07: 'Sport',
  },
  {
    id: 'orgSch-8',
    data02: '8',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'A Rainy Day in New York',
    data06: '',
    data07: 'Sport',
  },
  {
    id: 'orgSch-9',
    data01: '',
    data02: '9',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'A Rainy Day in New York',
    data06: '',
    data07: 'Sport',
  },
  {
    id: 'orgSch-10',
    data02: '10',
    data03: 'AMG',
    data04: 'ncaa_rivalries_ncaa_rivalries_s1_ep1',
    data05: 'A Rainy Day in New York',
    data06: '',
    data07: 'Sport',
  },
];

export const shelfDeviceOption = [
  { id: 'tv', value: 'tv', option: 'tv', type: 'text' },
  { id: 'phone', value: 'phone', option: 'phone', type: 'text' },
];

export const RegionData = [
  { id: 'US', value: 'US', option: 'US', type: 'text' },
  { id: 'Asia', value: 'Asia', option: 'Asia', type: 'text' },
  { id: 'country3', value: 'GR', option: 'GR', type: 'text' },
];
export const PlatformVersionOption = [
  { id: 'list1', value: '3.4.0', option: '3.4.0', type: 'text' },
  { id: 'list2', value: '3.4.1', option: '3.4.1', type: 'text' },
  { id: 'list3', value: '3.4.2', option: '3.4.2', type: 'text' },
];

export const EnableOption = [
  { id: 'All', value: 'All', option: 'All', type: 'text' },
  { id: 'op1', value: 'op1', option: 'op1', type: 'text' },
  { id: 'op2', value: 'op2', option: 'op2', type: 'text' },
];

export const StatusOption = [
  { id: 'all', value: 'all', option: 'All', type: 'text' },
  { id: 'Status1', value: 'Status1', option: 'Status1', type: 'text' },
  { id: 'Status2', value: 'Status2', option: 'Status2', type: 'text' },
];

export const CPNameOption = [
  { id: 'All', value: 'All', option: 'All', type: 'text' },
  { id: 'Name2', value: 'Name2', option: 'Name2', type: 'text' },
];
export const selectOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const HistoryCategoryTh = [
  {
    id: 'shcate-1',
    align: 'left',
    label: 'Change Date',
    width: '12%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'shcate-2',
    align: 'left',
    label: 'Tab Code',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shcate-3',
    align: 'left',
    label: 'Tab Name',
    width: '8%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'shcate-4',
    align: 'left',
    label: 'Category code',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shcate-5',
    align: 'left',
    label: 'Category Name',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shcate-6',
    align: 'left',
    label: 'Content Type',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shcate-7',
    align: 'left',
    label: 'Mapping Type',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shcate-8',
    align: 'left',
    label: 'SDK Version',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shcate-9',
    align: 'left',
    label: 'Platform Version',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shcate-10',
    align: 'left',
    label: 'Event',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shcate-11',
    align: 'left',
    label: 'Last Modifier',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];
export const HistoryCategoryTd = [
  {
    id: 'sh-row-1',
    data1: '2024-08-23 18:43:27',
    data2: 'T-My',
    data3: 'My',
    data4: 'C-Favorite',
    data5: 'My Favorite',
    data6: 'live',
    data7: 'manual',
    data8: '1 > 3',
    data9: 'On',
    data10: 'Updated',
    data11: 'taeil.lim',
  },
  {
    id: 'sh-row-2',
    data1: '2024-08-23 18:43:27',
    data2: 'T-My',
    data3: 'My',
    data4: 'C-Favorite',
    data5: 'My Favorite',
    data6: 'live',
    data7: 'manual',
    data8: '1 > 3',
    data9: 'On',
    data10: 'Updated',
    data11: 'taeil.lim',
  },
  {
    id: 'sh-row-3',
    data1: '2024-08-23 18:43:27',
    data2: 'T-My',
    data3: 'My',
    data4: 'C-Favorite',
    data5: 'My Favorite',
    data6: 'live',
    data7: 'manual',
    data8: '1 > 3',
    data9: 'On',
    data10: 'Updated',
    data11: 'taeil.lim',
  },
  {
    id: 'sh-row-4',
    data1: '2024-08-23 18:43:27',
    data2: 'T-My',
    data3: 'My',
    data4: 'C-Favorite',
    data5: 'My Favorite',
    data6: 'live',
    data7: 'manual',
    data8: '1 > 3',
    data9: 'On',
    data10: 'Updated',
    data11: 'taeil.lim',
  },
];

export const HistoryContentTh = [
  {
    id: 'sh-1',
    align: 'left',
    label: 'Change Date',
    width: '12%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'sh-2',
    align: 'left',
    label: 'Tab Code',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'sh-3',
    align: 'left',
    label: 'Tab Name',
    width: '8%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'sh-4',
    align: 'left',
    label: 'Category code',
    width: '11%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'sh-5',
    align: 'left',
    label: 'Category Name',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'sh-6',
    align: 'left',
    label: 'Mapping Type',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'sh-7',
    align: 'left',
    label: 'Status',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'sh-8',
    align: 'left',
    label: 'Result',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'sh-9',
    align: 'left',
    label: 'Last Modifier',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];
export const HistoryContentTd = [
  {
    id: 'sh-row-1',
    data01: '2024-08-23 18:43:27',
    data02: 'T-My',
    data03: 'My',
    data04: 'talkShow&C-Favorite',
    data05: 'My Favorite',
    data06: 'manual',
    data07: 'Saved for Testing',
    data08: { deleted: 10, created: 55, updated: 77 },
    data09: 'taeil.lim',
  },
  {
    id: 'sh-row-2',
    data01: '2024-08-23 18:43:27',
    data02: 'T-My',
    data03: 'My',
    data04: 'talkShow&C-Favorite',
    data05: 'My Favorite',
    data06: 'manual',
    data07: 'Saved for Testing',
    data08: { deleted: 54, created: 4, updated: 77 },
    data09: 'taeil.lim',
  },
  {
    id: 'sh-row-3',
    data01: '2024-08-23 18:43:27',
    data02: 'T-My',
    data03: 'My',
    data04: 'talkShow&C-Favorite',
    data05: 'My Favorite',
    data06: 'manual',
    data07: 'Saved for Testing',
    data08: { deleted: 65, created: 2, updated: 77 },
    data09: 'taeil.lim',
  },
  {
    id: 'sh-row-4',
    data01: '2024-08-23 18:43:27',
    data02: 'T-My',
    data03: 'My',
    data04: 'talkShow&C-Favorite',
    data05: 'My Favorite',
    data06: 'manual',
    data07: 'Saved for Testing',
    data08: { deleted: 1, created: 99, updated: 32 },
    data09: 'taeil.lim',
  },
  {
    id: 'sh-row-5',
    data01: '2024-08-23 18:43:27',
    data02: 'T-My',
    data03: 'My',
    data04: 'talkShow&C-Favorite',
    data05: 'My Favorite',
    data06: 'manual',
    data07: 'Saved for Testing',
    data08: { deleted: 10, created: 55, updated: 23 },
    data09: 'taeil.lim',
  },
  {
    id: 'sh-row-6',
    data01: '2024-08-23 18:43:27',
    data02: 'T-My',
    data03: 'My',
    data04: 'talkShow&C-Favorite',
    data05: 'My Favorite',
    data06: 'manual',
    data07: 'Saved for Testing',
    data08: { deleted: 33, created: 24, updated: 11 },
    data09: 'taeil.lim',
  },
];

export const HistoryTabTh = [
  {
    id: 'htab-1',
    align: 'left',
    label: 'Change Date',
    width: '18%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'htab-2',
    align: 'left',
    label: 'Tab Code',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'htab-3',
    align: 'left',
    label: 'Tab Name',
    width: '18%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'htab-4',
    align: 'left',
    label: 'Event',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'htab-5',
    align: 'left',
    label: 'Enable',
    width: '16%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'htab-6',
    align: 'left',
    label: 'Last Modifier',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];
export const HistoryTabTd = [
  {
    id: 'htab-row-1',
    data01: '2024-08-23 18:43:27',
    data02: 'T-My',
    data03: 'My',
    data04: 'Saved-Testing',
    data05: 'On',
    data06: 'taeil.lim',
  },
  {
    id: 'htab-row-2',
    data01: '2024-08-23 18:43:27',
    data02: 'T-My',
    data03: 'My',
    data04: 'Saved-Testing',
    data05: 'On',
    data06: 'taeil.lim',
  },
  {
    id: 'htab-row-3',
    data01: '2024-08-23 18:43:27',
    data02: 'T-My',
    data03: 'My',
    data04: 'Saved-Testing',
    data05: 'On',
    data06: 'taeil.lim',
  },
  {
    id: 'htab-row-4',
    data01: '2024-08-23 18:43:27',
    data02: 'T-My',
    data03: 'My',
    data04: 'Saved-Testing',
    data05: 'On',
    data06: 'taeil.lim',
  },
  {
    id: 'htab-row-5',
    data01: '2024-08-23 18:43:27',
    data02: 'T-My',
    data03: 'My',
    data04: 'Saved-Testing',
    data05: 'On',
    data06: 'taeil.lim',
  },
  {
    id: 'htab-row-6',
    data01: '2024-08-23 18:43:27',
    data02: 'T-My',
    data03: 'My',
    data04: 'Saved-Testing',
    data05: 'On',
    data06: 'taeil.lim',
  },
  {
    id: 'htab-row-7',
    data01: '2024-08-23 18:43:27',
    data02: 'T-My',
    data03: 'My',
    data04: 'Saved-Testing',
    data05: 'On',
    data06: 'taeil.lim',
  },
  {
    id: 'htab-row-8',
    data01: '2024-08-23 18:43:27',
    data02: 'T-My',
    data03: 'My',
    data04: 'Saved-Testing',
    data05: 'On',
    data06: 'taeil.lim',
  },
];

export const HistoryDetailTh = [
  {
    id: 'shd-1',
    align: 'left',
    label: 'CP Name',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-2',
    align: 'left',
    label: 'ID',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-3',
    align: 'left',
    label: 'Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-4',
    align: 'left',
    label: 'Type',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-5',
    align: 'Event',
    label: 'Carousel',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-6',
    align: 'left',
    label: 'Order',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-7',
    align: 'left',
    label: 'Fix',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-8',
    align: 'left',
    label: 'Event',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-9',
    align: 'left',
    label: 'Last Modifier',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const HistoryDetailTd = [
  {
    id: 'HistoryDetailTd-1',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '4',
    data7: 'On > Off',
    data8: 'Updated',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-2',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '5',
    data7: 'On > Off',
    data8: 'Create',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-3',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '2',
    data7: 'On > Off',
    data8: 'Updated',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-4',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '10',
    data7: 'On ',
    data8: '-',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-5',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '1 > 3',
    data7: 'On > Off',
    data8: 'Deleted',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-6',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '3 > 1',
    data7: 'On',
    data8: 'Deleted',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-7',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '10',
    data7: 'On > Off',
    data8: 'Create',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-8',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '10',
    data7: 'On ',
    data8: '-',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-9',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '10',
    data7: 'On ',
    data8: '-',
    data9: 'taeil.lim',
  },
];

export const ShelfHistoryDetailTh = [
  {
    id: 'shd-1',
    align: 'left',
    label: 'CP Name',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-2',
    align: 'left',
    label: 'ID',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-3',
    align: 'left',
    label: 'Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-4',
    align: 'left',
    label: 'Type',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-5',
    align: 'Event',
    label: 'Carousel',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-6',
    align: 'left',
    label: 'Order',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-7',
    align: 'left',
    label: 'Fix',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-8',
    align: 'left',
    label: 'Event',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'shd-9',
    align: 'left',
    label: 'Last Modifier',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const ShelfHistoryDetailTd = [
  {
    id: 'HistoryDetailTd-1',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '4',
    data7: 'On > Off',
    data8: 'Updated',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-2',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '5',
    data7: 'On > Off',
    data8: 'Create',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-3',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '2',
    data7: 'On > Off',
    data8: 'Updated',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-4',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '10',
    data7: 'On ',
    data8: '-',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-5',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '1 > 3',
    data7: 'On > Off',
    data8: 'Deleted',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-6',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '3 > 1',
    data7: 'On',
    data8: 'Deleted',
    data9: 'taeil.lim',
  },
  {
    id: 'HistoryDetailTd-7',
    data1: 'AMG',
    data2: 'us_lg_ncaachannel_1',
    data3: 'Monthly Picks : NCAA',
    data4: 'live',
    data5: 'channel',
    data6: '10',
    data7: 'On > Off',
    data8: 'Create',
    data9: 'taeil.lim',
  },
];
