import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import SelectBox from 'components/component/BasicSelectBox';
import TableSortLabel from '@mui/material/TableSortLabel';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import RadioGroup from '@mui/material/RadioGroup';
import CustomRadio from 'components/component/BasicRadio';
import CustomImage from 'components/component/BasicImage';
import TextLink from 'components/component/BasicTextLink';
import Button from 'components/component/BasicButton';
import CustomBasicMenu from 'components/component/BasicMenu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import CheckBox from 'components/component/BasicCheckBox';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { styled } from '@mui/material/styles';

// data for filter

import {
  RegionOptionData,
  CountryOptionData,
  PlatformData,
  sdkOptionData,
  ShelfTh,
  ShelfTd,
  PreviewTh,
  PreviewTd,
} from './data/LGChannelsShelfData';

// data for Table

const buttonGroup = [
  {
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    label: 'Delete',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    color: 'primary',
    type: 'box',
    size: 'medium',
    label: 'Add',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    color: 'primary',
    type: 'box',
    size: 'medium',
    label: 'Save',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
];

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Radio Data
const radioOptions = [
  { id: 'Link', checked: true, value: 'Link', label: 'Link', name: 'radio' },
  { id: 'Manual', checked: false, value: 'Manual', label: 'Manual', name: 'radio' },
];

// 메인 컨텐츠
function LGChannelsShelf({ name }) {
  const optionMenu = {
    gnbMenu: 'Promotion',
    lnbMenu: 'LG Channels Shelf',
  };

  // radio
  const initialCheckedValue = radioOptions.find((option) => option.checked)?.value || 'option1';
  const [selectedValue, setSelectedValue] = useState(initialCheckedValue);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // TableBody Row
  const sortedShelfTdRows = sortRows([...ShelfTd], orderBy, order);
  const sortedPreviewTdRows = sortRows([...PreviewTd], orderBy, order);
  const selPageData = [{ text: 'Select This Page' }, { text: 'Select All Page' }];

  return (
    <Box sx={{ display: 'flex' }} className='wrapper'>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Typography component='p' className='title'>
                LG Channels shelf
              </Typography>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap category-list' component='div'>
          {/* panel-wrap 삭제하였습니다. */}
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box mb={2}>
              <RadioGroup value={selectedValue} onChange={handleRadioChange} className='align-h'>
                {radioOptions.map((option) => (
                  <Box key={option.value} sx={{ mr: 7 }}>
                    <CustomRadio
                      value={option.value}
                      label={option.label}
                      onChange={handleRadioChange}
                      disabled={option.disabled}
                      name={name}
                    />
                  </Box>
                ))}
              </RadioGroup>
            </Box>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  {/* Region */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='shelf-region'
                      size='large'
                      shrink={true}
                      label='Region'
                      required={false}
                      selectOptions={RegionOptionData}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>
                  {/* Country */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='country-slt1'
                      size='large'
                      shrink={true}
                      label='Country'
                      required={false}
                      selectOptions={CountryOptionData}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>
                  {/* Platform */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Platform'
                      required={false}
                      selectOptions={PlatformData}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* 2nd Depth / Home Shelf Setting */}
          <Box className='white-box white-box-wrap single-box' pb={3}>
            <Box className='title-wrap space-between'>
              <Box className='left-area'>
                {/* flexDirection={'column'}추가하였습니다 */}
                <Box className='box-title-area' flexDirection={'column'}>
                  <Typography variant='h2'>2nd Depth / Home Shelf Setting</Typography>
                  <Typography variant='p' component='p' className='sm-sub-text'>
                    The selected Tab and Category Detail will be mapped with the 2nd Depth / Home Shelf data, and will
                    be displayed in TV
                  </Typography>
                </Box>
              </Box>
              <Box className='right-area'>
                <CustomButtonGroup buttons={buttonGroup} justifyContent='flex-end' />
              </Box>
            </Box>
            <div className='box-content'>
              {/* no-page-number 클래스 추가하였습니다. */}
              <Box className='content-inner-wrap no-page-number'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {ShelfTh.map((column, index) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <Box component='div' sx={{ display: 'flex', gap: '8px' }}>
                                <Box
                                  component='div'
                                  sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                >
                                  <CheckBox
                                    label=''
                                    value='select-all'
                                    defaultChecked={false}
                                    inputProps={{
                                      'aria-label': 'select all',
                                    }}
                                    style={{ width: '24px' }}
                                  />
                                  {index === 0 && (
                                    <CustomBasicMenu
                                      type='icon'
                                      title={<ArrowDropDownIcon sx={{ fontSize: '18px', color: '#000' }} />}
                                      options={selPageData}
                                    />
                                  )}
                                </Box>
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </Box>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedShelfTdRows.map((td, index) => (
                        <TableRow className='tr' key={td.id || index}>
                          {ShelfTh.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {colIndex === 0 ? (
                                <CheckBox label={td[`data0${colIndex + 1}`]} value='value' defaultChecked={false} />
                              ) : colIndex === 2 ? (
                                <TextLink href='' text={td[`data0${colIndex + 1}`]} />
                              ) : colIndex === 4 ? (
                                <Button
                                  color='tertiary'
                                  type='outline'
                                  size='small'
                                  label='END'
                                  isIcon={false}
                                  Icon=''
                                  iconPos={undefined}
                                  autosize
                                />
                              ) : (
                                td[`data0${colIndex + 1}`]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>
            </div>
          </Box>

          {/* Preview */}
          <Box className='white-box white-box-wrap single-box' pb={3}>
            <Box className='title-wrap space-between'>
              <Box className='left-area'>
                <Box className='box-title-area' alignItems='center'>
                  <Typography variant='h2'>Preview</Typography>
                  <Typography variant='p' component='p' className='sm-sub-text'>
                    The following items are displayed by the current RSS feeds, and may be updated by batch process
                  </Typography>
                </Box>
              </Box>
              <Box className='right-area'>
                <SelectBox
                  id='slt-country'
                  size='large'
                  maxWidth='230px'
                  shrink={true}
                  label='SDK Version'
                  required={false}
                  selectOptions={sdkOptionData}
                  disabled={false}
                  placeholder=''
                />
              </Box>
            </Box>
            <div className='box-content'>
              {/* no-page-number 클래스 추가하였습니다. */}
              <Box className='content-inner-wrap no-page-number'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {PreviewTh.map((column, index) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <Box component='div' sx={{ display: 'flex', gap: '8px' }}>
                                <Box
                                  component='div'
                                  sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                >
                                  <CheckBox
                                    label=''
                                    value='select-all'
                                    defaultChecked={false}
                                    inputProps={{
                                      'aria-label': 'select all',
                                    }}
                                    style={{ width: '24px' }}
                                  />
                                  {index === 0 && (
                                    <CustomBasicMenu
                                      type='icon'
                                      title={<ArrowDropDownIcon sx={{ fontSize: '18px', color: '#000' }} />}
                                      options={selPageData}
                                    />
                                  )}
                                </Box>
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </Box>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedPreviewTdRows.map((td, index) => (
                        <TableRow className='tr' key={td.id || index}>
                          {PreviewTh.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {colIndex === 2 ? (
                                <CustomImage
                                  src={td[`data0${colIndex + 1}`]}
                                  fit={false}
                                  alt=''
                                  wrapWidth='238px'
                                  wrapHeight='64px'
                                  rounded
                                />
                              ) : (
                                td[`data0${colIndex + 1}`]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default LGChannelsShelf;
