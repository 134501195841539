import React from 'react';
import { InputBase, IconButton, Paper, FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/system';

// Icon
import { SearchIcon } from 'components/component/BasicIcon';

const StyledFormControl = styled(FormControl)`
  width: 100%;
  &.large {
    height: 4rem;
    input {
      height: 100%;
      padding: 1rem 1.2rem;
    }
  }

  &.medium {
    height: 3.2rem;
    & .Mui-focused {
      &.MuiInputLabel-root {
        margin-top: 0;
      }
    }
    input {
      height: 100%;
    }
  }

  &.MuiFormControl-root {
    & .MuiPaper-root {
      padding: 0 12px;
      border-radius: 4px;
      box-shadow: none;
      width: 100%;
    }
    .MuiInputBase-input {
      box-sizing: border-box;
      padding: 0.6rem 0;

      &::placeholder {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .MuiPaper-root {
    height: 100%;
  }
`;

const SearchContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  border: '1px solid #A3A3A3',

  '.dark &': {
    border: '1px solid #484848',

    background: '#1B1B1B',
  },

  '& .MuiButtonBase-root': {
    padding: 0,
    marginLeft: '4px',
  },
  '& .MuiInputBase-root': {
    marginLeft: 0,
    padding: 0,
    height: '100%',
    fontSize: '14px',
  },
  '& .MuiInputBase-input ': {
    height: '100%',
    borderRadius: '4px',

    '&::placeholder': {
      color: '#8d8d8d',
    },
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  flex: 1,
  padding: '3px 12px',
  '.dark &': {
    color: '#fff',
  },
  '&::placeholder': {
    color: '#bbb',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

const StyledInputLabel = styled(InputLabel)`
  &.MuiFormLabel-root {
    &.MuiInputLabel-shrink {
      position: absolute;
      left: 0;
      padding: 0 3px;
      background: #fff;
      font-size: 12px;
      line-height: 20px;
      z-index: 10;

      .dark & {
        background: #20202766;
        color: #fff;
      }

      &.MuiInputLabel-sizeLarge,
      &.MuiInputLabel-sizeMedium,
      &.MuiInputLabel-sizeSmall {
        top: 0;
      }
    }
    .require {
      &:after {
        content: '*';
        color: #a50034;
        font-size: 14px;
        margin-left: 3px;
      }
    }
  }
`;
function BasicSearchField({ id, required, placeholder, width, maxWidth, size, label, shrink, ...props }) {
  return (
    <StyledFormControl className={`search-field ${size}`} sx={{ width: width, maxWidth: maxWidth }}>
      {shrink && (
        <StyledInputLabel id={`${id}`} htmlFor={`${id}`} shrink={shrink}>
          <span className={required ? 'require' : ''}>{label}</span>
        </StyledInputLabel>
      )}
      <SearchContainer>
        <StyledInputBase
          id={`${id}`}
          placeholder={placeholder}
          title={placeholder}
          inputProps={{ 'aria-label': 'search' }}
          {...props}
        />
        <IconButton type='submit' aria-label='search'>
          <SearchIcon />
        </IconButton>
      </SearchContainer>
    </StyledFormControl>
  );
}

export default BasicSearchField;
