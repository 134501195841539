export const APICPNameOption = [
  { id: 'cp-All', value: 'All', option: 'All', type: 'text' },
  { id: 'cp-op1', value: 'op1', option: 'op1', type: 'text' },
  { id: 'cp-op2', value: 'op2', option: 'op2', type: 'text' },
];
export const APIOption = [
  { id: 'All-2', value: 'All', option: 'All', type: 'text' },
  { id: 'api-op-2', value: 'op1', option: 'op1', type: 'text' },
];

export const TypeOption = [
  { id: 'Type-All', value: 'All', option: 'All', type: 'text' },
  { id: 'Type-op1', value: 'op1', option: 'op1', type: 'text' },
  { id: 'Type-op2', value: 'op2', option: 'op2', type: 'text' },
];

export const StatusOption = [
  { id: 'Status-All', value: 'All', option: 'All', type: 'text' },
  { id: 'Status-1', value: 'Status1', option: 'Status1', type: 'text' },
  { id: 'Status-2', value: 'Status2', option: 'Status2', type: 'text' },
];

export const selectOption = [
  { id: 'sel-all', value: 'All', option: 'All', type: 'text' },
  { id: 'sel-option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'sel-option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const TabCodeOption = [
  { id: 'sel-all', value: 'All', option: 'All', type: 'text' },
  { id: 'sel-option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'sel-option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const EmulateTh = [
  {
    id: 'EmulateTh-1',
    align: 'left',
    label: 'Order',
    width: '6%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'EmulateTh-2',
    align: 'left',
    label: 'CP Name',
    width: '10%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'EmulateTh-3',
    align: 'left',
    label: 'Type',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'EmulateTh-4',
    align: 'left',
    label: 'Content ID',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'EmulateTh-5',
    align: 'left',
    label: 'Name',
    width: '11%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'EmulateTh-6',
    align: 'left',
    label: 'Season',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'EmulateTh-7',
    align: 'left',
    label: 'Episode',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'EmulateTh-8',
    align: 'left',
    label: 'Image',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'EmulateTh-9',
    align: 'left',
    label: 'Genre',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'EmulateTh-10',
    align: 'left',
    label: 'Keyword',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'EmulateTh-11',
    align: 'left',
    label: 'Last Chg Date',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'EmulateTh-12',
    align: 'left',
    label: 'Exclusive License',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'EmulateTh-13',
    align: 'left',
    label: 'Source ID',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];
export const EmulateTd = [
  {
    id: 'EmulateTd-row-1',
    data1: '1',
    data2: 'XUMO',
    data3: 'live',
    data4: '99992124',
    data5: 'Tribeca Channel',
    data6: '-',
    data7: '-',
    data8: '', //image
    data9: 'movies',
    data10: '-',
    data11: '2023-09-07T13:30',
    data12: 'N',
    data13: '-',
  },
  {
    id: 'EmulateTd-row-2',
    data1: '2',
    data2: 'XUMO',
    data3: 'live',
    data4: '99992124',
    data5: 'Tribeca Channel',
    data6: '-',
    data7: '-',
    data8: '', //image
    data9: 'movies',
    data10: '-',
    data11: '2023-09-07T13:30',
    data12: 'N',
    data13: '-',
  },
  {
    id: 'EmulateTd-row-3',
    data1: '3',
    data2: 'XUMO',
    data3: 'live',
    data4: '99992124',
    data5: 'Tribeca Channel',
    data6: '-',
    data7: '-',
    data8: '', //image
    data9: 'movies',
    data10: '-',
    data11: '2023-09-07T13:30',
    data12: 'N',
    data13: '-',
  },
];
