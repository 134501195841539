// src/data.js

export const RicCodeData = [
  { id: 'ric-141', value: 'AIC', option: 'AIC', type: 'text' },
  { id: 'ric-142', value: 'option2', option: 'Option 2', type: 'text' },
  { id: 'ric-143', value: 'option3', option: 'Option 3', type: 'text' },
];

export const Region = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'Eu', value: 'Eu', option: 'Eu', type: 'text' },
  { id: 'Asia', value: 'Asia', option: 'Asia', type: 'text' },
];

export const TypeData = [
  { id: 'TvShow', value: 'TvShow', option: 'TvShow', type: 'text' },
  { id: 'TvShow-2', value: 'TvShow2', option: 'TvShow2', type: 'text' },
  { id: 'TvShow-3', value: 'TvShow2', option: 'TvShow3', type: 'text' },
];
export const GenreData = [
  { id: 'GenreData-1', value: 'All', option: 'All', type: 'text' },
  { id: 'GenreData-2', value: 'Genre-1', option: 'Genre-1', type: 'text' },
  { id: 'GenreData-3', value: 'Genre-2', option: 'Genre-2', type: 'text' },
];

export const CountryData = [
  { id: 'country-1', value: 'Us', option: 'Us', type: 'text' },
  { id: 'country-2', value: 'Italy', option: 'Italy', type: 'text' },
  { id: 'country-3', value: 'Ger', option: 'Ger', type: 'text' },
];
export const CPData = [
  { id: 'cp-1', value: 'US-ME-6.0.0', option: 'All', type: 'text' },
  { id: 'cp-2', value: 'US-ME-6.0.1', option: 'US-ME-6.0.1', type: 'text' },
  { id: 'cp-3', value: 'US-ME-6.0.2', option: 'US-ME-6.0.2', type: 'text' },
];
export const CPMapData = [
  { id: 'cp-map-1', value: 'cp-map-1', option: 'cp-map-1', type: 'text' },
  { id: 'cp-map-2', value: 'cp-map-2', option: 'cp-map-2', type: 'text' },
  { id: 'cp-map-3', value: 'cp-map-3', option: 'cp-map-3', type: 'text' },
];
export const statusData = [
  { id: 'status-1', value: 'status-1', option: 'status-1', type: 'text' },
  { id: 'status-2', value: 'status-2', option: 'status-2', type: 'text' },
  { id: 'status-3', value: 'status-3', option: 'status-3', type: 'text' },
];
export const selectOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const CategoryTypeData = [
  { id: 'ct-1', value: 'Monthly Picks: NCAA Ch', option: 'Monthly Picks: NCAA ChNCAA Ch', type: 'text' },
  { id: 'ct-2', value: 'item 01', option: 'item 01', type: 'text' },
  { id: 'ct-3', value: 'item 02', option: 'item 02', type: 'text' },
];
export const businessAreaData = [
  { id: 'ba-1', value: 'Channel Map', option: 'Channel Map', type: 'text' },
  { id: 'ba-2', value: 'Channel Map2', option: 'Channel Map2', type: 'text' },
  { id: 'ba-3', value: 'Channel Map3', option: 'Channel Map3', type: 'text' },
];

export const CountTh = [
  {
    id: 'data01',
    label: 'Ric',
    align: 'left',
    width: '10%',
    checkbox: true,
    sortable: false,
  },
  {
    id: 'data02',
    label: 'Country',
    align: 'left',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'data03',
    label: 'Channel Map ID',
    align: 'left',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'data04',
    label: 'Provider',
    align: 'left',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'data05',
    label: 'Test Channel Count',
    align: 'right',
    width: '17%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'data06',
    label: 'Publish Channel Count',
    align: 'right',
    width: 'auto',
    sortable: true,
  },
];

export const CountTd = [
  {
    id: 'td-1',
    data01: 'AIC',
    data02: 'AR',
    data03: 'AR-ME-6.0.0',
    data04: 'WRL',
    data05: '13',
    data06: '12',
  },
  {
    id: 'td-2',
    data01: 'AIC',
    data02: 'AR',
    data03: 'AR-ME-6.0.0',
    data04: 'WRL',
    data05: '44',
    data06: '11',
  },
  {
    id: 'td-3',
    data01: 'AIC',
    data02: 'AR',
    data03: 'AR-ME-6.0.0',
    data04: 'WRL',
    data05: '46',
    data06: '23',
  },
  {
    id: 'td-4',
    data01: 'AIC',
    data02: 'AR',
    data03: 'AR-ME-6.0.0',
    data04: 'WRL',
    data05: '86',
    data06: '34',
  },
  {
    id: 'td-5',
    data01: 'AIC',
    data02: 'AR',
    data03: 'AR-ME-6.0.0',
    data04: 'WRL',
    data05: '11',
    data06: '5',
  },
  {
    id: 'td-6',
    data01: 'AIC',
    data02: 'AR',
    data03: 'AR-ME-6.0.0',
    data04: 'WRL',
    data05: '11',
    data06: '4',
  },
  {
    id: 'td-7',
    data01: 'AIC',
    data02: 'AR',
    data03: 'AR-ME-6.0.0',
    data04: 'WRL',
    data05: '15',
    data06: '2',
  },
  {
    id: 'td-8',
    data01: 'AIC',
    data02: 'AR',
    data03: 'AR-ME-6.0.0',
    data04: 'WRL',
    data05: '74',
    data06: '1',
  },
  {
    id: 'td-9',
    data01: 'AIC',
    data02: 'AR',
    data03: 'AR-ME-6.0.0',
    data04: 'WRL',
    data05: '63',
    data06: '0',
  },
  {
    id: 'td-10',
    data01: 'AIC',
    data02: 'AR',
    data03: 'AR-ME-6.0.0',
    data04: 'WRL',
    data05: '10',
    data06: '0',
  },
];

export const SchedulesTh = [
  {
    id: 'time',
    align: 'left',
    label: 'Time',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'proID',
    align: 'left',
    label: 'Program ID',
    width: '40%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Title',
    align: 'left',
    label: 'Title',
    width: '40%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Action',
    align: 'left',
    label: 'Action',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
];

export const SchedulesTd = [
  {
    id: 'checkbox',
    data01: '20:11',
    data02: 'US-LGELECTRONICS-53034186-6645167-6633435',
    data03: '2023 FCS Football Championship: South Dakota State vs. Montana',
  },
  {
    id: 'row-1',
    data01: '20:10',
    data02: 'US-LGELECTRONICS-53034186-6645167-6633430',
    data03: '2023 FCS Football Championship: South Dakota State vs. Montana',
  },
  {
    id: 'row-2',
    data01: '20:07',
    data02: 'US-LGELECTRONICS-53034186-6645167-6633434',
    data03: '2023 FCS Football Championship: South Dakota State vs. Montana',
  },
  {
    id: 'row-3',
    data01: '20:10',
    data02: 'US-LGELECTRONICS-53034186-6645167-6633430',
    data03: '2023 FCS Football Championship: South Dakota State vs. Montana',
  },
  {
    id: 'row-4',
    data01: '20:07',
    data02: 'US-LGELECTRONICS-53034186-6645167-6633434',
    data03: '2023 FCS Football Championship: South Dakota State vs. Montana',
  },
  {
    id: 'row-5',
    data01: '20:10',
    data02: 'US-LGELECTRONICS-53034186-6645167-6633430',
    data03: '2023 FCS Football Championship: South Dakota State vs. Montana',
  },
  {
    id: 'row-6',
    data01: '20:07',
    data02: 'US-LGELECTRONICS-53034186-6645167-6633434',
    data03: '2023 FCS Football Championship: South Dakota State vs. Montana',
  },
  {
    id: 'row-7',
    data01: '20:10',
    data02: 'US-LGELECTRONICS-53034186-6645167-6633430',
    data03: '2023 FCS Football Championship: South Dakota State vs. Montana',
  },
  {
    id: 'row-8',
    data01: '20:07',
    data02: 'US-LGELECTRONICS-53034186-6645167-6633434',
    data03: '2023 FCS Football Championship: South Dakota State vs. Montana',
  },
];

export const ChannelMapTh = [
  {
    id: 'country',
    label: 'Ch.No',
    align: 'left',
    width: '5%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'cpName',
    label: 'CP Name',
    align: 'left',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'chname',
    label: 'Channel Name',
    align: 'left',
    width: '17%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Genre',
    label: 'Channel Genre',
    align: 'left',
    width: '9%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ch-id',
    label: 'Channel ID',
    align: 'left',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'id-3',
    label: '3.4 ',
    align: 'left',
    width: '6%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'id-4',
    label: '4.3',
    align: 'left',
    width: '6%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'WEE ',
    label: 'WEE',
    align: 'left',
    width: '6%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Auto',
    label: 'Auto',
    align: 'left',
    width: '6%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'dma',
    label: 'DMA',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'date',
    label: 'Last Update',
    align: 'left',
    width: '10 %',
    checkbox: false,
    sortable: true,
  },
];
export const ChannelMapTd = [
  {
    data1: '100',
    data2: 'Wurl',
    data3: 'Monthly Picks: NCAA Channel 100',
    data4: 'Sport',
    data5: 'us-lg_ncaachannel_1',
    data6: 'O',
    data7: 'O',
    data8: 'O',
    data9: 'O',
    data10: 'Local Now Yakima',
    data11: '2024.08.07 14:00:00',
  },
  {
    data1: '101',
    data2: 'Wurl',
    data3: 'Monthly Picks: NCAA Channel 100',
    data4: 'Sport',
    data5: 'us-lg_ncaachannel_1',
    data6: 'O',
    data7: 'O',
    data8: 'O',
    data9: 'O',
    data10: 'Local Now Yakima',
    data11: '2024.08.07 14:00:00',
  },
  {
    data1: '111',
    data2: 'Wurl',
    data3: 'Monthly Picks: NCAA Channel 100',
    data4: 'Sport',
    data5: 'us-lg_ncaachannel_1',
    data6: 'O',
    data7: 'O',
    data8: 'O',
    data9: 'O',
    data10: 'Local Now Yakima',
    data11: '2024.08.07 14:00:00',
  },
  {
    data1: '123',
    data2: 'Wurl',
    data3: 'Monthly Picks: NCAA Channel 100',
    data4: 'Sport',
    data5: 'us-lg_ncaachannel_1',
    data6: 'O',
    data7: 'O',
    data8: 'O',
    data9: 'O',
    data10: 'Local Now Yakima',
    data11: '2024.08.07 14:00:00',
  },
  {
    data1: '100',
    data2: 'Wurl',
    data3: 'Monthly Picks: NCAA Channel 100',
    data4: 'Sport',
    data5: 'us-lg_ncaachannel_1',
    data6: 'O',
    data7: 'O',
    data8: 'O',
    data9: 'O',
    data10: 'Local Now Yakima',
    data11: '2024.08.07 14:00:00',
  },
  {
    data1: '100',
    data2: 'Wurl',
    data3: 'Monthly Picks: NCAA Channel 100',
    data4: 'Sport',
    data5: 'us-lg_ncaachannel_1',
    data6: 'O',
    data7: 'O',
    data8: 'O',
    data9: 'O',
    data10: 'Local Now Yakima',
    data11: '2024.08.07 14:00:00',
  },
  {
    data1: '100',
    data2: 'Wurl',
    data3: 'Monthly Picks: NCAA Channel 100',
    data4: 'Sport',
    data5: 'us-lg_ncaachannel_1',
    data6: 'O',
    data7: 'O',
    data8: 'O',
    data9: 'O',
    data10: 'Local Now Yakima',
    data11: '2024.08.07 14:00:00',
  },
];

export const VodTh = [
  {
    id: 'vod-No',
    label: 'No',
    align: 'left',
    width: '5%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vod-Type',
    label: 'Type',
    align: 'left',
    width: '7%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vod-ID',
    label: 'VOD ID',
    align: 'left',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vod-Title',
    label: 'VOD Title',
    align: 'left',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vod-Duration',
    label: 'Duration',
    align: 'left',
    width: '6%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vod-EpisodeNo',
    label: 'Episode No.',
    align: 'left',
    width: '5%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vod-viewflag',
    label: 'Exclusive view flag',
    align: 'left',
    width: '5%',
    sortable: true,
  },
  {
    id: 'vod-GenreCode',
    label: 'Genre Code',
    align: 'left',
    width: 'auto',
    sortable: true,
  },
  {
    id: 'vod-Genre',
    label: 'Genre',
    align: 'left',
    width: '6%',
    sortable: true,
  },
  {
    id: 'vod-ReleaseDate',
    label: 'Release Date',
    align: 'left',
    width: '5%',
    sortable: true,
  },
  {
    id: 'vod-StartDate',
    label: 'Start Date Time',
    align: 'left',
    width: 'auto',
    sortable: true,
  },
  {
    id: 'vod-EndDate',
    label: 'End Date Time',
    align: 'left',
    width: 'auto',
    sortable: true,
  },
  {
    id: 'vod-Action',
    label: 'Action',
    align: 'left',
    width: '5%',
    sortable: true,
  },
];

export const VodTd = [
  {
    id: 'vodtd-01',
    data1: '17',
    data2: 'TvShow',
    data3: '1251181',
    data4: 'Technically a Tech Stock',
    data5: '2309',
    data6: '3',
    data7: '3',
    data8: '49',
    data9: 'short',
    data10: '2008',
    data11: '07/22/2024 15:46:32',
    data12: '07/22/2024 15:46:32',
    data13: 'Detaill',
  },
  {
    id: 'vodtd-02',
    data1: '16',
    data2: 'TvShow',
    data3: '1251181',
    data4: 'Technically a Tech Stock',
    data5: '2309',
    data6: '3',
    data7: '3',
    data8: '49',
    data9: 'short',
    data10: '2008',
    data11: '07/22/2024 15:46:32',
    data12: '07/22/2024 15:46:32',
    data13: 'Detaill',
  },
  {
    id: 'vodtd-03',
    data1: '15',
    data2: 'TvShow',
    data3: '1251181',
    data4: 'Technically a Tech Stock',
    data5: '2309',
    data6: '3',
    data7: '3',
    data8: '49',
    data9: 'short',
    data10: '2008',
    data11: '07/22/2024 15:46:32',
    data12: '07/22/2024 15:46:32',
    data13: 'Detaill',
  },
  {
    id: 'vodtd-04',
    data1: '14',
    data2: 'TvShow',
    data3: '1251181',
    data4: 'Technically a Tech Stock',
    data5: '2309',
    data6: '3',
    data7: '3',
    data8: '49',
    data9: 'short',
    data10: '2008',
    data11: '07/22/2024 15:46:32',
    data12: '07/22/2024 15:46:32',
    data13: 'Detaill',
  },
  {
    id: 'vodtd-05',
    data1: '13',
    data2: 'TvShow',
    data3: '1251181',
    data4: 'Technically a Tech Stock',
    data5: '2309',
    data6: '3',
    data7: '3',
    data8: '49',
    data9: 'short',
    data10: '2008',
    data11: '07/22/2024 15:46:32',
    data12: '07/22/2024 15:46:32',
    data13: 'Detaill',
  },
];

export const ChannelInfo = [
  { id: 'info-01', number: 100, title: 'Monthly Picks: NCAA Channel' },
  { id: 'info-02', number: 101, title: 'Majordomo TV with David Chang' },
  { id: 'info-03', number: 102, title: 'Nat Geo Sharks' },
  { id: 'info-04', number: 103, title: 'Team USA TV' },
  { id: 'info-05', number: 104, title: 'Team USA TV' },
  { id: 'info-06', number: 105, title: 'Team USA TV' },
  { id: 'info-07', number: 106, title: 'Team USA TV' },
  { id: 'info-08', number: 109, title: 'Team Fra TV' },
  { id: 'info-09', number: 110, title: 'Team USA TV' },
  { id: 'info-10', number: 111, title: 'Team USA TV' },
];
// SchedulesTime Header 시간
export const SchedulesTime = [
  { id: '4', time: '04:00(UTC)', timeCurrent: false },
  { id: '5', time: '05:00(UTC)', timeCurrent: false },
  { id: '6', time: '06:00(UTC)', timeCurrent: false },
  { id: '7', time: '07:00(UTC)', timeCurrent: false },
  { id: '8', time: '08:00(UTC)', timeCurrent: false },
  { id: '9', time: '09:00(UTC)', timeCurrent: true },
  { id: '10', time: '10:00(UTC)', timeCurrent: false },
  { id: '11', time: '11:00(UTC)', timeCurrent: false },
  { id: '12', time: '12:00(UTC)', timeCurrent: false },
  { id: '13', time: '13:00(UTC)', timeCurrent: false },
  { id: '14', time: '14:00(UTC)', timeCurrent: false },
  { id: '15', time: '15:00(UTC)', timeCurrent: false },
  { id: '16', time: '16:00(UTC)', timeCurrent: false },
  { id: '17', time: '17:00(UTC)', timeCurrent: false },
  { id: '18', time: '18:00(UTC)', timeCurrent: false },
  { id: '19', time: '19:00(UTC)', timeCurrent: false },
  { id: '20', time: '20:00(UTC)', timeCurrent: false },
  { id: '21', time: '21:00(UTC)', timeCurrent: false },
  { id: '22', time: '22:00(UTC)', timeCurrent: false },
  { id: '23', time: '23:00(UTC)', timeCurrent: false },
  { id: '24', time: '24:00(UTC)', timeCurrent: false },
];
export const ProgramsRow1 = [
  {
    id: 'p1',
    current: 'past',
    width: '16%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p2',
    current: 'past',
    width: '22%',
    title: '2024 DI Womens Lacrosse Semifinal: Boston College vs Syracuse',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p3',
    current: 'active',
    width: '25%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p4',
    current: '',
    width: '28%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p5',
    current: '',
    width: '19%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
];
export const ProgramsRow2 = [
  {
    id: 'r2-p1',
    current: 'past',
    width: '20%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'r2-p2',
    current: 'past',
    width: '18%',
    title: '2024 DI Womens Lacrosse Semifinal: Boston College vs Syracuse',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'r2-p3',
    current: 'active',
    width: '20%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'r2-p4',
    current: '',
    width: '22%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'r2-p5',
    current: '',
    width: '20%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'r2-p6',
    current: '',
    width: '22%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'r2-p7',
    current: '',
    width: '20%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
];
export const ProgramsRow3 = [
  {
    id: 'p11',
    current: 'past',
    width: '22%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p7',
    current: 'past',
    width: '24%',
    title: '2024 DI Womens Lacrosse Semifinal: Boston College vs Syracuse',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p8',
    current: 'active',
    width: '28%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p9',
    width: '19%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p10',
    current: '',
    width: '36%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
];

export const ProgramsRow4 = [
  {
    id: 'p11',
    current: 'past',
    width: '24%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p7',
    current: 'past',
    width: '20%',
    title: '2024 DI Womens Lacrosse Semifinal: Boston College vs Syracuse',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p8',
    current: 'active',
    width: '21%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p9',
    current: '',
    width: '19%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p10',
    current: '',
    width: '17%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
];
export const ProgramsRow5 = [
  {
    id: 'p11',
    current: 'past',
    width: '16%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p7',
    current: 'active',
    width: '34%',
    title: '2024 DI Womens Lacrosse Semifinal: Boston College vs Syracuse',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p8',
    current: '',
    width: '25%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p9',
    current: '',
    width: '19%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p10',
    current: '',
    width: '31%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
];
export const ProgramsRow6 = [
  {
    id: 'p12',
    current: 'past',
    width: '26%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p13',
    current: 'past',
    width: '22%',
    title: '2024 DI Womens Lacrosse Semifinal: Boston College vs Syracuse',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p14',
    current: 'active',
    width: '29%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p15',
    current: '',
    width: '26%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p16',
    current: '',
    width: '31%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
];
export const ProgramsRow7 = [
  {
    id: 'p11',
    current: 'past',
    width: '22%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p7',
    current: 'past',
    width: '24%',
    title: '2024 DI Womens Lacrosse Semifinal: Boston College vs Syracuse',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p8',
    current: 'active',
    width: '28%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p9',
    current: '',
    width: '19%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
  {
    id: 'p10',
    current: '',
    width: '36%',
    title: '2024 DI Baseball Mens College World Series Game 3: Tennessee vs Texas A&M',
    time: '03:36 ~ 07:05',
  },
];

export const chCountListData = [
  {
    id: 'count-01',
    label: '3.2',
    type: 'checkbox',
  },
  {
    id: 'count-02',
    label: '4.3',
    type: 'checkbox',
  },
  {
    id: 'count-03',
    label: 'WEE',
    type: 'checkbox',
  },
  {
    id: 'count-04',
    label: 'ME',
    type: 'checkbox',
  },
  {
    id: 'count-05',
    label: 'Auto',
    type: 'checkbox',
  },
  {
    id: 'count-06',
    label: 'Auto',
    type: 'checkbox',
  },
];
