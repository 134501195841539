export const countrysData = [
  {
    id: 'feed-info-01',
    name: 'country',
    value: 'Amagi',
    primary: '[AMG-AMG] Amagi',
    secondary: '2024-07-01   16:18:51',
    checked: true,
  },
  {
    id: 'feed-info-02',
    name: 'country',
    value: 'Redbox',
    primary: '[AMG-RBX] Redbox',
    secondary: '2024-07-01   16:18:51',
    checked: '',
  },
  {
    id: 'feed-info-03',
    name: 'country',
    value: 'alphonso',
    primary: '[APS] alphonso',
    secondary: '2024-07-01   16:18:51',
    checked: '',
  },
  {
    id: 'feed-info-04',
    name: 'country',
    value: 'harmonic',
    primary: '[HMN] harmonic',
    secondary: '2024-07-01   16:18:51',
    checked: '',
  },
  {
    id: 'feed-info-05',
    name: 'country',
    value: 'Player',
    primary: '[lGP] LG Player',
    secondary: '2024-07-01   16:18:51',
    checked: '',
  },
  {
    id: 'feed-info-06',
    name: 'country',
    value: 'NCAA',
    primary: '[NCAA] NCAA',
    secondary: '2024-07-01   16:18:51',
    checked: '',
  },
  {
    id: 'feed-info-07',
    name: 'country',
    value: 'NEW',
    primary: '[NEW] NEW ID',
    secondary: '2024-07-01   16:18:51',
    checked: '',
  },
  {
    id: 'feed-info-08',
    name: 'country',
    value: 'ottera',
    primary: '[OTTR] ottera',
    secondary: '2024-07-01   16:18:51',
    checked: '',
  },
  {
    id: 'feed-info-09',
    name: 'country',
    value: 'Frequency',
    primary: '[AMG-AMG] amagi',
    secondary: '2024-07-01   16:18:51',
    checked: '',
  },
  {
    id: 'feed-info-10',
    name: 'country',
    value: 'Rakuten',
    primary: '[WRL-RKT] Rakuten',
    secondary: '2024-07-01   16:18:51',
    checked: '',
  },
  {
    id: 'feed-info-11',
    name: 'country',
    value: 'Rakuten',
    primary: '[WRL-RKT] Rakuten',
    secondary: '2024-07-01   16:18:51',
    checked: '',
  },
  {
    id: 'feed-info-12',
    name: 'country',
    value: 'Rakuten',
    primary: '[FREQCY] Rakuten',
    secondary: '2024-07-01   16:18:51',
    checked: '',
  },
  {
    id: 'feed-info-13',
    name: 'country',
    value: 'Rakuten',
    primary: '[FREQCY] Rakuten',
    secondary: '2024-07-01   16:18:51',
    checked: '',
  },
];

export const Region = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'Eu', value: 'Eu', option: 'Eu', type: 'text' },
  { id: 'Asia', value: 'Asia', option: 'Asia', type: 'text' },
];

export const CountryData = [
  { id: 'country-1', value: 'Us', option: 'Us', type: 'text' },
  { id: 'country-2', value: 'Italy', option: 'Italy', type: 'text' },
  { id: 'country-3', value: 'Ger', option: 'Ger', type: 'text' },
];

export const statusData = [
  { id: 'status-1', value: 'status-1', option: 'status-1', type: 'text' },
  { id: 'status-2', value: 'status-2', option: 'status-2', type: 'text' },
  { id: 'status-3', value: 'status-3', option: 'status-3', type: 'text' },
];

export const selectOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const ContentType = [
  { id: 'Channel', value: 'Channel', option: 'Channel', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const DeviceTypeData = [
  { id: 'device-1', value: 'TV', option: 'TV', type: 'text' },
  { id: 'device-2', value: 'device-2', option: 'device-2', type: 'text' },
  { id: 'device-3', value: 'device-3', option: 'device-3', type: 'text' },
];

export const PlatformVersionData = [
  { id: 'platform-version-1', value: '3.4.0', option: '3.4.0', type: 'text' },
  { id: 'platform-version-2', value: '3.3.5', option: '3.3.5', type: 'text' },
  { id: 'platform-version-3', value: '3.3.0', option: '3.3.0', type: 'text' },
];

export const PrTh = [
  {
    id: 'areaCode',
    align: 'left',
    label: 'Area Code',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'name',
    align: 'left',
    label: 'Area Name',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'type',
    align: 'left',
    label: 'Area Type',
    width: '24%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'enable',
    align: 'left',
    label: 'Enable',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'action',
    align: 'left',
    label: 'Action',
    width: '12%',
    checkbox: false,
    sortable: false,
  },
];

export const PrTd = [
  {
    data01: 'T01',
    data02: 'Promotion Banner',
    data03: 'Banner(1920px x 305px)',
    data04: 'On',
    data05: '',
  },
  {
    data01: 'T02',
    data02: 'Web Live',
    data03: 'Branding Image-A type(1920px x 305px)',
    data04: 'On',
    data05: '',
  },
  {
    data01: 'T03',
    data02: 'Section1',
    data03: 'Branding Image-A type(850px x 490px)',
    data04: 'On',
    data05: '',
  },
  {
    data01: 'T04',
    data02: 'TOP 10 VOD',
    data03: 'Content-Channel',
    data04: 'On',
    data05: '',
  },
];

export const RegionSelectData = [
  {
    id: 1,
    value: 'option1',
    option: 'Option 1',
    type: 'text',
    defaultChecked: true,
  },
  {
    id: 2,
    value: 'option2',
    option: 'Option 2',
    type: 'text',
    defaultChecked: false,
  },
  {
    id: 3,
    value: 'option3',
    option: 'Option 3',
    type: 'text',
    defaultChecked: false,
  },
  {
    id: 4,
    value: 'option4',
    option: 'Option 4',
    type: 'text',
    defaultChecked: false,
  },
  {
    id: 5,
    value: 'option5',
    option: 'Option 5',
    type: 'text',
    defaultChecked: false,
  },
  {
    id: 6,
    value: 'option6',
    option: 'Option 6',
    type: 'text',
    defaultChecked: false,
  },
  {
    id: 7,
    value: 'option7',
    option: 'Option 7',
    type: 'text',
    defaultChecked: false,
  },
];

export const CountrySelectData = [
  {
    id: 1,
    value: 'option1',
    option: 'Option 1',
    type: 'text',
    defaultChecked: true,
  },
  {
    id: 2,
    value: 'option2',
    option: 'Option 2',
    type: 'text',
    defaultChecked: false,
  },
  {
    id: 3,
    value: 'option3',
    option: 'Option 3',
    type: 'text',
    defaultChecked: false,
  },
  {
    id: 4,
    value: 'option4',
    option: 'Option 4',
    type: 'text',
    defaultChecked: false,
  },
];

export const SelecteCodeData = [
  {
    id: 1,
    value: 'T01',
    option: 'T01',
    type: 'text',
    defaultChecked: true,
  },
  {
    id: 2,
    value: 'T02',
    option: 'T02',
    type: 'text',
    defaultChecked: false,
  },
  {
    id: 3,
    value: 'T03',
    option: 'T03',
    type: 'text',
    defaultChecked: false,
  },
];
export const AreaCodeData = [
  {
    id: 1,
    value: 'T07',
    option: 'T07',
    type: 'text',
    defaultChecked: true,
  },
  {
    id: 2,
    value: 'option2',
    option: 'Option 2',
    type: 'text',
    defaultChecked: false,
  },
  {
    id: 3,
    value: 'option3',
    option: 'Option 3',
    type: 'text',
    defaultChecked: false,
  },
  {
    id: 4,
    value: 'option4',
    option: 'Option 4',
    type: 'text',
    defaultChecked: false,
  },
];
