import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import SelectBox from 'components/component/BasicSelectBox';
import TableSortLabel from '@mui/material/TableSortLabel';
import CustomTabs from 'components/component/BasicTabs';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import CheckBox from 'components/component/BasicCheckBox';
import Button from 'components/component/BasicButton';
import TextLink from 'components/component/BasicTextLink';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';

// data
import { Region, CountryData, statusData, PrTh, PrTd } from './data/OrganizationData';

// Tab Name
const tabs = [{ label: 'PR Page' }, { label: 'Display Page' }, { label: 'Playback Page' }];

// Button
const crudButtons = [
  {
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    label: 'Delete',
    isIcon: false,
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'box',
    size: 'medium',
    label: 'Creat Area',
    isIcon: false,
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'box',
    size: 'medium',
    label: 'Testing',
    isIcon: false,
    autosize: true,
  },
];

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// 메인 컨텐츠
function WebService() {
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Web Service',
  };

  // Tab state
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Select
  const [openSelect1, setOpenSelect1] = useState(false);
  const [openSelect2, setOpenSelect2] = useState(false);
  const [openSelect3, setOpenSelect3] = useState(false);

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // TableBody Row
  const sortedPrTdRows = sortRows([...PrTd], orderBy, order);

  return (
    <Box sx={{ display: 'flex' }} className='wrapper'>
      <Box className='main-container pr-page-container' component='main'>
        {/* BreadCrumb Area */}
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Typography component='p' className='title'>
                Web Service
              </Typography>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>
        {/*  //BreadCrumb Area */}

        <Box className='content-wrap ' component='div'>
          <div className='tab-container'>
            <CustomTabs tabStyle='type-01' value={tabValue} handleChange={handleTabChange} tabs={tabs} />

            {tabValue === 0 && (
              // Tab 1
              <div className='tab-panel pr-page'>
                <div className='panel-wrap'>
                  {/* 필터영역 */}
                  <Box component='div' className='white-box filter-box'>
                    <Box className='form-wrap-box'>
                      <Box className='form-area left'>
                        <Box className='component-wrap col-5'>
                          {/* region */}
                          <Box component='div' className='col'>
                            <SelectBox
                              id='slt-country'
                              size='large'
                              shrink={true}
                              label='Region'
                              required={false}
                              selectOptions={Region}
                              disabled={false}
                              isFirstSelected={true}
                              placeholder=''
                              open={openSelect1}
                              setOpen={setOpenSelect1}
                            />
                          </Box>
                          {/* Country */}
                          <Box component='div' className='col'>
                            <SelectBox
                              id='slt-country'
                              size='large'
                              shrink={true}
                              label='Country'
                              required={true}
                              selectOptions={CountryData}
                              disabled={false}
                              isFirstSelected={true}
                              placeholder=''
                              open={openSelect2}
                              setOpen={setOpenSelect2}
                            />
                          </Box>
                          {/* status */}
                          <Box component='div' className='col'>
                            <SelectBox
                              id='slt-country'
                              size='large'
                              shrink={true}
                              label='Status'
                              required={false}
                              selectOptions={statusData}
                              disabled={false}
                              isFirstSelected={true}
                              placeholder=''
                              open={openSelect3}
                              setOpen={setOpenSelect3}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <div className='form-area right'></div>
                    </Box>
                  </Box>

                  <Box className='white-box white-box-wrap'>
                    <div className='box-content'>
                      <Box className='white-box-content-top-area'>
                        <Box className='left-area'>
                          <Box className='total-count'>
                            <Box component='span' className='label'>
                              Total
                            </Box>
                            <Box component='span' className='value'>
                              127
                            </Box>
                          </Box>
                        </Box>

                        <Box className='right-area'>
                          <CustomButtonGroup buttons={crudButtons} justifyContent='flex-end' />
                        </Box>
                      </Box>
                      <Box className='content-inner-wrap'>
                        {/* Table */}
                        <CustomTableContainer className='table-container'>
                          <Table className='custom-table' aria-label='table' stickyHeader>
                            {/* table Head */}
                            <TableHead className='thead'>
                              <TableRow className='tr'>
                                {PrTh.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    className='th'
                                    align={column.align}
                                    sx={{ width: column.width }}
                                  >
                                    {column.checkbox ? (
                                      <>
                                        <CheckBox
                                          label=''
                                          value='select-all'
                                          defaultChecked={false}
                                          inputProps={{
                                            'aria-label': 'select all',
                                          }}
                                        />
                                        <TableSortLabel
                                          active={orderBy === column.id}
                                          direction={orderBy === column.id ? order : 'desc'}
                                          onClick={() => handleRequestSort(column.id)}
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      </>
                                    ) : (
                                      <>
                                        {column.sortable ? (
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'desc'}
                                            onClick={() => handleRequestSort(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        ) : (
                                          column.label
                                        )}
                                      </>
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            {/* table Body */}
                            <TableBody className='tbody'>
                              {sortedPrTdRows.map((td, index) => (
                                <TableRow className='tr' key={td.id || index}>
                                  {PrTh.map((column, colIndex) => (
                                    <TableCell key={column.id} className='td' align={column.align}>
                                      {colIndex === 0 ? (
                                        <CheckBox
                                          label={td[`data0${colIndex + 1}`]}
                                          value='value'
                                          defaultChecked={false}
                                        />
                                      ) : colIndex === 1 ? (
                                        <TextLink href='' text={td[`data0${colIndex + 1}`]} />
                                      ) : colIndex === 4 ? (
                                        <Button
                                          color='tertiary'
                                          type='outline'
                                          size='small'
                                          label='Category'
                                          isIcon={false}
                                          Icon=''
                                          iconPos={undefined}
                                          autosize
                                        />
                                      ) : (
                                        td[`data0${colIndex + 1}`]
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </CustomTableContainer>
                        {/* // Table */}
                      </Box>

                      {/* Pagination */}
                      <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
                    </div>
                  </Box>
                </div>
              </div>
            )}
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default WebService;
