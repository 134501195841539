import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';

import './App.css';
import 'assets/scss/common/reset.scss';
import 'assets/scss/common/common.scss';
import 'assets/scss/common/theme.scss';

// Common
import Entry from 'pages/Main/Entry';
import TopBar from 'components/layout/TopBar';
import Gnb from 'components/layout/Gnb';
import { initialCurrentUser } from 'pages/Login/data/User';
import MyProfile from 'pages/Main/MyProfile';

import { getCountry } from 'actions/common/countryActions';
import { getProvider } from 'actions/common/providerActions';

// Inspection
import ChannelStatus from 'pages/Inspect/ChannelStatus/ChannelStatus';
import Metadata from 'pages/Inspect/Metadata/Metadata';

// Organization
import ChannelMapping from 'pages/Organization/ChannelMapping/ChannelMapping';
import TabCategoryList from 'pages/Organization/TabCategory/TabCategoryList';
import WebService from 'pages/Organization/WebService/WebService';
import ANTest from 'pages/Organization/ANTest/ANTest';
import ANService from 'pages/Organization/ANService/ANService';
import DeviceNotification from 'pages/Organization/DeviceNotification/DeviceNotification';

// Promotion
import FreeOnLGChannelsShelf from 'pages/Promotion/FreeOnLGChannelsShelf/FreeOnLGChannelsShelf';
import WatchOnLGChannelsShelf from 'pages/Promotion/WatchOnLGChannelsShelf/WatchOnLGChannelsShelf';
import LGChannelsShelf from 'pages/Promotion/LGChannelsShelf/LGChannelsShelf';
import InteractiveDock from 'pages/Promotion/InteractiveDock/InteractiveDock';
import CategoryTitle from 'pages/Promotion/InteractiveDock/CategoryTitle';
import InteractiveDockOrganize from 'pages/Promotion/InteractiveDock/InteractiveDockOrganize';

// Monitoring
import Emulate from 'pages/Monitoring/Emulate/Emulate';

function App({ getCountry, getProvider }) {
  const [currentUser, setCurrentUser] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [message, setMessage] = useState('');
  const [isDisplayApp, setDisplayApp] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [targetComponent, setTargetComponent] = useState('');

  const location = useLocation();

  useEffect(() => {
    setMessage('');
    setTargetComponent('');
    location.state = null;

    const savedCurrentUser = JSON.parse(localStorage.getItem('currentUser')) || initialCurrentUser;
    setCurrentUser(savedCurrentUser);

    if (savedCurrentUser && savedCurrentUser.isAuthenticated && !isSessionExpired(savedCurrentUser)) {
      setIsAuthenticated(true);
      updateSessionTime(savedCurrentUser);
    } else {
      setIsAuthenticated(false);
    }

    const darkMode = localStorage.getItem('darkMode') || 'off';
    if (darkMode === 'on') {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }

    setDisplayApp(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Admin 실행시에 로그인이 완료된 이후에 서버에서 국가 정보와 CP 정보를 가져옴
    if (isAuthenticated) {
      getCountry();
      getProvider();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    if (location.state) {
      if (location.state.auth) {
        const savedCurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        setCurrentUser(savedCurrentUser);

        if (location.state.auth === 'true') {
          setIsAuthenticated(true);
        } else if (location.state.auth === 'false') {
          setIsAuthenticated(false);
        }

        setMessage(location.state.message);
        setTargetComponent('');
      } else if (location.state.move) {
        if (location.state.targetComponent) {
          setMessage(location.state.message);
          setTargetComponent(location.state.targetComponent);
        } else {
          setMessage('');
          setTargetComponent('');
        }
      }

      location.state = null;
    } else {
      setMessage('');
      setTargetComponent('');
    }
  }, [location]);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }

    localStorage.setItem('darkMode', darkMode ? 'on' : 'off');
  }, [darkMode]);

  const updateSessionTime = (currentUser) => {
    const updateCurrentUser = {
      ...currentUser,
      loginTime: new Date().toISOString(),
    };

    localStorage.setItem('currentUser', JSON.stringify(updateCurrentUser));
  };

  const isSessionExpired = (currentUser) => {
    if (!currentUser.loginTime) return true;

    const loginTime = new Date(currentUser.loginTime);
    const currentTime = new Date();
    const timeDifference = currentTime - loginTime;
    const minutesDifference = timeDifference / 1000;

    return minutesDifference > currentUser.sessionTimeout;  // 30 minutes
  };

  const handleDarkMode = (value) => {
    setDarkMode(value);
  };

  return (
    isDisplayApp && <>
      <CssBaseline />
      <Box display='flex' className='wrapper'>
        { isAuthenticated && <Gnb darkMode={darkMode}/> }
          <Box className='main-container' sx={{ flexGrow: 1 }}>
            { isAuthenticated && <TopBar currentUser={currentUser} darkMode={darkMode}/> }
            <Routes>
              {/* Main */}
              <Route path='/' element={
                targetComponent === 'MyProfile' ? (
                  <MyProfile currentUser={currentUser} darkMode={darkMode} callBackDarkMode={handleDarkMode}/>
                ) : (
                  <Entry isAuthenticated={isAuthenticated} message={message} currentUser={currentUser} darkMode={darkMode}/>
                )
              }/>

              {/* Inspection */}
              <Route path='/ChannelStatus' element={isAuthenticated ? <ChannelStatus darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route path='/Metadata' element={isAuthenticated ? <Metadata darkMode={darkMode} /> : <Navigate to='/' replace />} />

              {/* Organization */}
              <Route path='/ChannelMapping' element={isAuthenticated ? <ChannelMapping darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route path='/TabCategoryList' element={isAuthenticated ? <TabCategoryList darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route path='/WebService' element={isAuthenticated ? <WebService darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route path='/ANTest' element={isAuthenticated ? <ANTest darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route path='/ANService' element={isAuthenticated ? <ANService darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route path='/DeviceNotification' element={isAuthenticated ? <DeviceNotification darkMode={darkMode} /> : <Navigate to='/' replace />} />

              {/* Promotion */}
              <Route path='/FreeOnLGChannelsShelf' element={isAuthenticated ? <FreeOnLGChannelsShelf darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route path='/WatchOnLGChannelsShelf' element={isAuthenticated ? <WatchOnLGChannelsShelf darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route path='/LGChannelsShelf' element={isAuthenticated ? <LGChannelsShelf darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route path='/InteractiveDock' element={isAuthenticated ?
                targetComponent === 'CategoryTitle' ? (
                  <CategoryTitle darkMode={darkMode} message={message} />
                ) : targetComponent === 'InteractiveDockOrganize' ? (
                  <InteractiveDockOrganize darkMode={darkMode} message={message} />
                ) : <InteractiveDock darkMode={darkMode} /> : <Navigate to='/' replace />} />

              {/* Monitoring */}
              <Route path='/Emulate' element={isAuthenticated ? <Emulate darkMode={darkMode} /> : <Navigate to='/' replace />} />
            </Routes>
          </Box>
       </Box>
    </>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  getCountry: () => dispatch(getCountry()),
  getProvider: () => dispatch(getProvider()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);