import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import CheckBox from 'components/component/BasicCheckBox';
import SelectBox from 'components/component/BasicSelectBox';
import CustomImage from 'components/component/BasicImage';
import BasicSwitches from 'components/component/BasicSwitch';
import TableSortLabel from '@mui/material/TableSortLabel';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import SearchField from 'components/component/BasicSearchField';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Menu } from '@mui/material';

import { styled } from '@mui/material/styles';

// Data
import {
  CountryData,
  cpNameOption,
  selectOption,
  ManualAutoOption,
  FreeOnTypeOption,
  WatchOnStatusOptioin,
  InteractiveTh,
  InteractiveTd,
} from './data/InteractiveDockData';

const Buttons = [
  {
    color: 'primary',
    type: 'box',
    size: 'small',
    label: 'All Service Change',
    isIcon: false,
    autosize: true,
  },
  {
    color: 'primary',
    type: 'box',
    size: 'small',
    label: 'All Auto Save',
    isIcon: false,
    autosize: true,
  },
  {
    color: 'primary',
    type: 'box',
    size: 'small',
    label: 'Save',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'box',
    size: 'small',
    label: 'Testing',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
];

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 200,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',

    '.dark &': {
      backgroundColor: '#1b1b1b',
      border: '1px solid #484848',
    },
    '& .MuiMenu-list': {
      padding: '4px 0',
      border: 0,
    },
    '& .MuiMenuItem-root': {
      '&:active': {
        backgroundColor: '#ddd',
      },
    },
  },
}));

// ************************** 메인 컨텐츠 ***********************************
function InteractiveDock() {
  const optionMenu = {
    gnbMenu: 'Promotion',
    lnbMenu: 'Interactive Dock - Recommendation',
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // TableBody Row
  const sortedTblTdRows = sortRows([...InteractiveTd], orderBy, order);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Table 내에 Switch
  const [switchStates, setSwitchStates] = useState({});
  const handleSwitchChange = (id, value) => {
    setSwitchStates((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <Box sx={{ display: 'flex' }} className='wrapper'>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Typography component='p' className='title'>
                Interactive Dock - Recommendation
              </Typography>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap category-list' component='div'>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  {/* Content Type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Country'
                      required={false}
                      selectOptions={CountryData}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>
                  {/* Country */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='slt-Name'
                      size='large'
                      shrink={true}
                      label='CP Name'
                      required={false}
                      selectOptions={cpNameOption}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>
                  {/* Service*/}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='Service-a1'
                      size='large'
                      shrink={true}
                      label='Service'
                      required={false}
                      selectOptions={FreeOnTypeOption}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>
                  {/* Mapping Rule*/}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='MappingRule-s4'
                      size='large'
                      shrink={true}
                      label='Mapping Rule'
                      required={false}
                      selectOptions={FreeOnTypeOption}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>

                  {/* Channel Genre*/}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='Genre-iuu'
                      size='large'
                      shrink={true}
                      label='Channel Genre'
                      required={false}
                      selectOptions={FreeOnTypeOption}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>
                  {/* Status*/}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='Status-oi'
                      size='large'
                      shrink={true}
                      label='Status'
                      required={false}
                      selectOptions={WatchOnStatusOptioin}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>
                </Box>
              </Box>
              <Box className='form-area right'></Box>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap'>
            <div className='box-content'>
              <Box className='white-box-content-top-area' alignItems='flex-end'>
                {/* left-area */}
                <Box className='left-area column'>
                  <Box className='total-count'>
                    <Box component='span' className='label'>
                      Total
                    </Box>
                    <Box component='span' className='value'>
                      6
                    </Box>
                  </Box>
                  <Box display='flex' gap={1}>
                    <SelectBox
                      id='select-search'
                      size='medium'
                      selectOptions={selectOption}
                      label=''
                      maxWidth='180px'
                      disabled={false}
                      placeholder=''
                    />

                    <SearchField placeholder='Please enter a search term' maxWidth='230px' />
                  </Box>
                </Box>
                <Box className='right-area'>
                  <CustomButtonGroup buttons={Buttons} />
                  <div className='type-button-dropdown'>
                    <Button
                      color=''
                      type='only-icon'
                      size='small'
                      label=''
                      isIcon={false}
                      className='btn-layer-popup'
                      onClick={handleClick}
                    />

                    <StyledMenu
                      id='demo-customized-menu'
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      className='btn-drop-paper'
                      PaperProps={{
                        sx: {
                          maxHeight: '230px',
                        },
                      }}
                    >
                      {/* Channel Count  */}
                      <div className='text-value'>Lonely here said tempest by demons</div>
                    </StyledMenu>
                  </div>
                </Box>
                {/* right-area */}
              </Box>

              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {InteractiveTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <Box display='flex' alignItems='center'>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  defaultChecked={false}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </Box>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedTblTdRows.map((td, index) => (
                        <TableRow className='tr' key={td.id || index}>
                          {InteractiveTh.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {colIndex === 0 ? (
                                <Box display='flex' gap='3px'>
                                  {/* CheckBox 컴포넌트 */}
                                  <CheckBox
                                    id={`checkbox-${td.id}`}
                                    label={td[`data0${colIndex + 1}`].text}
                                    value='value'
                                    defaultChecked={false}
                                  />

                                  <BasicSwitches
                                    id={td.id}
                                    disabled={false}
                                    onOffMode={switchStates[td.id] || false}
                                    setSwitchOnOff={(value) => handleSwitchChange(td.id, value)}
                                    isLabelText={false}
                                    onText=''
                                    offText=''
                                  />
                                </Box>
                              ) : colIndex === 1 ? (
                                <SelectBox
                                  id={`select-${td.id}`}
                                  fullWidth
                                  required={false}
                                  size='medium'
                                  maxWidth='155px'
                                  selectOptions={ManualAutoOption}
                                  disabled={false}
                                  label=''
                                />
                              ) : colIndex === 2 ? (
                                <CustomImage
                                  src={td[`data0${colIndex + 1}`]}
                                  fit={false}
                                  alt=''
                                  wrapWidth='192px'
                                  wrapHeight='64px'
                                  rounded
                                />
                              ) : colIndex === 8 ? (
                                <Button
                                  color='tertiary'
                                  type='outline'
                                  size='small'
                                  label='ORGANIZE'
                                  isIcon={false}
                                  Icon=''
                                  iconPos={undefined}
                                  autosize
                                />
                              ) : (
                                td[`data0${colIndex + 1}`]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>

              {/* Pagination */}
              <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default InteractiveDock;
