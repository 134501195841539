import React, { useState, useEffect, useRef } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import SelectBox from 'components/component/BasicSelectBox';
import TableSortLabel from '@mui/material/TableSortLabel';
import CustomTabs from 'components/component/BasicTabs';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import SearchField from 'components/component/BasicSearchField';
import DatePicker from 'components/component/BasicDatePicker';
import CheckBox from 'components/component/BasicCheckBox';
import Button from 'components/component/BasicButton';
import Snackbar from 'components/component/BasicSnackbar';
import CustomImage from 'components/component/BasicImage';
import TextLink from 'components/component/BasicTextLink';
import Tooltip from 'components/component/BasicTooltip';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';

// Icon

import { styled } from '@mui/material/styles';

// image
import ncaaImage from 'assets/images/img_ncaa.png';
import { InfoIcon } from 'components/component/BasicIcon';

// Schedule css
import styles from 'assets/scss/page/channelStatus.module.scss';

// data
import {
  CountryData,
  selectOption,
  CPMapData,
  CategoryTypeData,
  Region,
  SchedulesTh,
  SchedulesTd,
  ChannelMapTh,
  ChannelMapTd,
  ChannelInfo,
  SchedulesTime,
  ProgramsRow1,
  ProgramsRow2,
  ProgramsRow3,
  ProgramsRow4,
  ProgramsRow5,
  ProgramsRow6,
  ProgramsRow7,
  chCountListData,
} from './data/ChannelStatusData';

// Icon
import { RefreshIcon } from 'components/component/BasicIcon';

// Tab Name
const tabs = [{ label: 'Program Schedule(Grid)' }, { label: 'Program Schedule(List)' }, { label: 'Channel Map' }];

// Button Data
const buttons = [
  {
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    label: 'Reset',
    isIcon: true,
    Icon: RefreshIcon,
    iconPos: 'left',
    className: 'custom-save-icon',
    width: 107,
  },
  {
    color: 'primary',
    type: 'box',
    size: 'medium',
    label: 'Search',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
  },
];

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// Channel schedule Component
const ProgramList = ({ programs, current }) => (
  <Box className={styles['program-item-wrap']}>
    <Box component='div' className={styles.row}>
      {programs.map((program) => (
        <Box
          component='div'
          className={`${styles['program-item']} ${
            // 지난 시간 클래스: past / 현재 방송: active
            program.current === 'active' ? styles.active : program.current === 'past' ? styles.past : ''
          }`}
          key={program.id}
          sx={{ width: program.width }}
        >
          <Tooltip title={program.title} placement='top'>
            <Typography className={styles['program-title']}>{program.title}</Typography>
          </Tooltip>
          <Typography className={styles['program-time']}>
            <span className={styles.text}>{program.time}</span>
          </Typography>
        </Box>
      ))}
    </Box>
  </Box>
);

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 200,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',

    '.dark &': {
      backgroundColor: '#1b1b1b',
      border: '1px solid #484848',
    },
    '& .MuiMenu-list': {
      padding: '4px 0',
      border: 0,
    },
    '& .MuiMenuItem-root': {
      '&:active': {
        backgroundColor: '#ddd',
      },
    },
  },
}));

const CustomListItemText = styled(ListItemText)({
  '& .MuiTypography-root': {
    fontSize: '1.2rem',
    lineHeight: '16px',
  },
});

function ChannelStatus(props) {
  const { darkMode } = props;

  const optionMenu = {
    gnbMenu: 'Inspection',
    lnbMenu: 'Channel Status',
  };

  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // TableBody Row
  const sortedSchedulesTdRows = sortRows([...SchedulesTd], orderBy, order);
  const sortedChannelMapTdRows = sortRows([...ChannelMapTd], orderBy, order);

  const bodyAreaRef = useRef(null);
  const currentTimeRef = useRef(null);

  // 시간선
  useEffect(() => {
    const updateHeight = () => {
      if (bodyAreaRef.current && currentTimeRef.current) {
        const bodyAreaHeight = bodyAreaRef.current.offsetHeight + (40 - 16); //헤더높이 - time-text 높이
        currentTimeRef.current.style.height = `${bodyAreaHeight}px`;
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [tabValue]);

  // Channel Map - channel count
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex' }} className='wrapper'>
      <Box className='main-container inspect' component='main'>
        {/* BreadCrumb Area */}
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Typography component='p' className='title'>
                Channel Status
              </Typography>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>
        {/*  //BreadCrumb Area */}

        <Box className='content-wrap ' component='div'>
          <div className='tab-container'>
            <CustomTabs tabStyle='type-01' value={tabValue} handleChange={handleTabChange} tabs={tabs} />

            {tabValue === 0 && (
              // Tab 1
              <div className='tab-panel schedule'>
                <div className='panel-wrap'>
                  {/* 필터영역 */}
                  <Box component='div' className='white-box filter-box'>
                    <Box component='div' className='form-wrap-box'>
                      <Box component='div' className='form-area left'>
                        {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
                        <Box component='div' className='component-wrap col-5'>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='select-business'
                              size='large'
                              shrink={true}
                              label='Region'
                              required={true}
                              selectOptions={Region}
                              disabled={false}
                              placeholder=''
                            />
                          </Box>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='tab1-country'
                              size='large'
                              shrink={true}
                              label='Country'
                              required={true}
                              selectOptions={CountryData}
                              disabled={false}
                              placeholder=''
                            />
                          </Box>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='channel-map'
                              size='large'
                              shrink={true}
                              label='Channel Map ID'
                              required={false}
                              selectOptions={selectOption}
                              disabled={false}
                              placeholder=''
                            />
                          </Box>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='select-cp'
                              size='large'
                              shrink={true}
                              label='CP Name'
                              selectOptions={selectOption}
                              disabled={false}
                              placeholder=''
                            />
                          </Box>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='tab2-channel-name'
                              size='large'
                              shrink={true}
                              label='Channel Name'
                              selectOptions={selectOption}
                              disabled={false}
                              placeholder=''
                            />
                          </Box>
                        </Box>

                        {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
                        <Box component='div' className='component-wrap '>
                          <Box component='div' className='col auto'>
                            <DatePicker
                              className='input-date-picker'
                              format='MM/DD/YYYY'
                              size='large'
                              sx={{ maxWidth: '220px' }}
                              shrink
                              label='Date'
                              singleDate={true}
                            />

                            <Button
                              color='tertiary'
                              type='outline'
                              size='medium'
                              label='Today'
                              isIcon={false}
                              autosize
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box className={`program-schedule-container ${styles['program-schedule-container']}`}>
                    {/* Header Row */}
                    <Box component='div' className='align-set-wrap'>
                      <Box className='total-count' marginRight='45px'>
                        <Box component='span' className='label'>
                          Total
                        </Box>
                        <Box component='span' className='value'>
                          127
                        </Box>
                      </Box>
                      <Box component='div' className={styles['date-mark']}>
                        <Typography className={styles['date']} component='span'>
                          2024. 7.18(목)
                        </Typography>
                      </Box>
                    </Box>

                    <div className={styles['schedule-container']}>
                      <Box className={styles['schedule-wrap']}>
                        <Box className={styles['channel-area']}>
                          <List className={styles['channel-list-container']} disablePadding>
                            <ListItem className={styles['top-area']}>
                              <Typography component='p' className={styles.text}>
                                Channel
                              </Typography>
                            </ListItem>
                            {ChannelInfo.map((channel) => (
                              <ListItem
                                className={styles['channel-item']}
                                key={channel.id}
                                sx={{ height: ProgramsRow1.height }}
                              >
                                <Box className={styles['info-box']}>
                                  <Typography component='span' className={styles['channel-number']}>
                                    {channel.number}
                                  </Typography>
                                  <CustomListItemText className={styles['channel-name']} primary={channel.title} />
                                </Box>
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                        <Box component='div' className={styles['schedule-area']}>
                          <Box component='div' className={styles['program-wrap']}>
                            <Box className={styles['program-header-wrap']} wrap='nowrap'>
                              {/* 현재시간 - 시간표시 */}
                              <Box
                                className={styles['current-time']}
                                ref={currentTimeRef}
                                sx={{
                                  left: '45%', // 현재시간 위치
                                }}
                              >
                                <div className={styles['time-area']}>
                                  <span className={styles['time-text']}>07/22/2024 05:41(UTC)</span>
                                  <span className={styles['time-bar']}></span>
                                </div>
                              </Box>
                              <Box className={styles['header-inner']}>
                                {SchedulesTime.map((Schedule) => (
                                  // 현재시간 표시 클래스: current
                                  <div
                                    className={`${styles['header-item']} ${Schedule.timeCurrent ? styles.current : ''}`}
                                    key={Schedule.id}
                                  >
                                    <Typography component='span' className={styles.text}>
                                      {Schedule.time}
                                    </Typography>
                                  </div>
                                ))}
                              </Box>
                            </Box>
                            <Box ref={bodyAreaRef} className={styles['program-body-wrap']}>
                              <Box className={styles['body-area']}>
                                <ProgramList programs={ProgramsRow1} />
                                <ProgramList programs={ProgramsRow2} />
                                <ProgramList programs={ProgramsRow3} />
                                <ProgramList programs={ProgramsRow4} />
                                <ProgramList programs={ProgramsRow5} />
                                <ProgramList programs={ProgramsRow6} />
                                <ProgramList programs={ProgramsRow7} />
                                <ProgramList programs={ProgramsRow1} />
                                <ProgramList programs={ProgramsRow2} />
                                <ProgramList programs={ProgramsRow3} />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  </Box>
                </div>
              </div>
            )}

            {/* Program Schedule(List) */}
            {tabValue === 1 && (
              <div className='tab-panel schedule'>
                <Box className='panel-wrap'>
                  {/* 필터영역 */}
                  <Box component='div' className='white-box filter-box'>
                    <Box className='form-wrap-box'>
                      <Box className='form-area left'>
                        {/* 필터영역 : 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
                        <Box className='component-wrap col-5'>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='select-business'
                              size='large'
                              shrink={true}
                              label='Region'
                              required={false}
                              selectOptions={Region}
                              disabled={false}
                              placeholder=''
                            />
                          </Box>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='tab2-country'
                              size='large'
                              shrink={true}
                              label='Country'
                              required={true}
                              selectOptions={CountryData}
                              disabled={false}
                              placeholder='CP Name'
                            />
                          </Box>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='select-cp'
                              size='large'
                              shrink={true}
                              label='CP Name'
                              selectOptions={selectOption}
                              disabled={false}
                              placeholder='Genre'
                            />
                          </Box>
                          <Box component='div' className='col'>
                            <SelectBox
                              id='tab2-channel-name'
                              size='large'
                              shrink={true}
                              label='Channel Name'
                              selectOptions={CategoryTypeData}
                              disabled={false}
                              placeholder='Genre'
                            />
                          </Box>
                          <Box component='div' className='col auto'>
                            <DatePicker
                              className='input-date-picker'
                              format='MM/DD/YYYY'
                              size='large'
                              shrink
                              label='Date'
                              singleDate={true}
                              sx={{ width: '250px' }}
                            />
                            <Button
                              color='tertiary'
                              type='outline'
                              size='medium'
                              label='Today'
                              isIcon={false}
                              autosize
                            />
                          </Box>
                        </Box>
                      </Box>
                      <div className='form-area right'></div>
                    </Box>
                  </Box>

                  <Box className='white-box white-box-wrap'>
                    <Box component='div' className='title-wrap space-between flex-end'>
                      <Box component='div' className='left-area'>
                        <Box component='div' className='monthly-picks-wrap'>
                          <div className='img'>
                            <CustomImage
                              src={ncaaImage}
                              fit={true}
                              wrapWidth='120px'
                              wrapHeight='64px'
                              alt=''
                              rounded
                            />
                          </div>
                          <Box component='div' className='box-top-label'>
                            <Box component='span' className='label'>
                              Monthly Picks :
                            </Box>
                            <Box component='span' className='value'>
                              NCAA Channel
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box component='div' className='right-area'>
                        <Box display='flex' justifyContent='flex-end' gap={1} sx={{ width: '100%' }}>
                          <SelectBox
                            id='select-search'
                            size='medium'
                            selectOptions={selectOption}
                            label=''
                            maxWidth='180px'
                            disabled={false}
                            placeholder=''
                          />
                          {/* 검색영역 */}
                          <SearchField
                            id='search-1'
                            maxWidth='230px'
                            placeholder='Please enter a search term'
                            size='medium'
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box className='box-content'>
                      <Box className='content-inner-wrap'>
                        {/* Table */}
                        <CustomTableContainer className='table-container'>
                          <Table className='custom-table' aria-label='table' stickyHeader>
                            {/* table Head */}
                            <TableHead className='thead'>
                              <TableRow className='tr'>
                                {SchedulesTh.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    className='th'
                                    align={column.align}
                                    sx={{ width: column.width }}
                                  >
                                    {column.checkbox ? (
                                      <>
                                        <CheckBox
                                          label=''
                                          value='select-all'
                                          defaultChecked={false}
                                          inputProps={{
                                            'aria-label': 'select all',
                                          }}
                                        />
                                        <TableSortLabel
                                          active={orderBy === column.id}
                                          direction={orderBy === column.id ? order : 'desc'}
                                          onClick={() => handleRequestSort(column.id)}
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      </>
                                    ) : (
                                      <>
                                        {column.sortable ? (
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'desc'}
                                            onClick={() => handleRequestSort(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        ) : (
                                          column.label
                                        )}
                                      </>
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            {/* table Body */}
                            <TableBody className='tbody'>
                              {sortedSchedulesTdRows.map((td, index) => (
                                <TableRow className='tr' key={td.id || index}>
                                  {SchedulesTh.map((column, colIndex) => (
                                    <TableCell key={column.id} className='td' align={column.align}>
                                      {colIndex === 0 ? (
                                        td[`data0${colIndex + 1}`]
                                      ) : colIndex === 1 ? (
                                        <TextLink href='' text={td[`data0${colIndex + 1}`]} />
                                      ) : colIndex === 2 ? (
                                        td[`data0${colIndex + 1}`]
                                      ) : (
                                        <Button
                                          color='tertiary'
                                          type='outline'
                                          size='small'
                                          label='Detail'
                                          isIcon={false}
                                          Icon=''
                                          sx={{ minWidth: 'auto' }}
                                        />
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </CustomTableContainer>
                        {/* // Table */}
                      </Box>

                      <Pagination id='pagination-01' count={5} selOptionData={selOptionData} />
                    </Box>
                  </Box>
                </Box>
              </div>
            )}

            {/* Channel Map */}
            {tabValue === 2 && (
              <div className='tab-panel channel-map'>
                <div className='panel-wrap'>
                  {/* 필터영역 */}
                  <Box component='div' className='white-box filter-box'>
                    <Box className='form-wrap-box'>
                      <Box className='form-area left'>
                        {/* 필터영역 : 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
                        <Box className='component-wrap col-5'>
                          <Box component='div' className='col' sx={{ position: 'relative' }}>
                            {/* Country */}
                            <SelectBox
                              id='slt-country'
                              size='large'
                              shrink={true}
                              label='Country'
                              required={true}
                              selectOptions={CountryData}
                              disabled={false}
                              placeholder=''
                            />
                          </Box>
                          <Box component='div' className='col'>
                            {/* CP Name */}
                            <SelectBox
                              id='select-name'
                              size='large'
                              shrink={true}
                              label='CP Name'
                              selectOptions={CPMapData}
                              disabled={false}
                              placeholder=''
                            />
                          </Box>
                          <Box component='div' className='col'>
                            {/* Channel Id */}
                            <SelectBox
                              id='select-id'
                              size='large'
                              shrink={true}
                              label='Channel Id'
                              selectOptions={selectOption}
                              disabled={false}
                              placeholder=''
                              value=''
                            />
                          </Box>
                          <Box component='div' className='col'>
                            {/* Channel Name */}
                            <SelectBox
                              id='select-chname'
                              size='large'
                              shrink={true}
                              label='Channel Name'
                              selectOptions={selectOption}
                              disabled={false}
                              placeholder=''
                              value=''
                            />
                          </Box>
                          <Box component='div' className='col'>
                            {/* Status */}
                            <SelectBox
                              id='slt-status'
                              size='large'
                              shrink={true}
                              label='Status'
                              required={false}
                              selectOptions={selectOption}
                              disabled={false}
                              placeholder='Publish'
                            />
                          </Box>
                        </Box>

                        {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
                        <Box className='component-wrap'>
                          <Box component='div' className='col auto'>
                            <DatePicker
                              format='MM/DD/YYYY'
                              size='large'
                              singleDate={false}
                              rangeDate={true}
                              wave={true}
                              sx={{ maxWidth: '210px' }}
                            />

                            <Button
                              color='tertiary'
                              type='outline'
                              size='medium'
                              label='Today'
                              isIcon={false}
                              autosize
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box className='form-area right'>
                        <div className='type-button-dropdown'>
                          <Tooltip placement='top' title='Selcet ‘Country’ and ‘CP Name’ to active'>
                            <div className='button-container'>
                              <Button
                                color='tertiary'
                                type='box'
                                size='medium'
                                label='Channel Count'
                                isIcon={true}
                                Icon={InfoIcon}
                                iconPos='right'
                                sx={{ width: '100%' }}
                                onClick={handleClick}
                              />
                            </div>
                          </Tooltip>

                          <StyledMenu
                            id='demo-customized-menu'
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            className='btn-drop-paper'
                            PaperProps={{
                              sx: {
                                maxHeight: '230px',
                              },
                            }}
                          >
                            <li>
                              {/* Channel Count  */}
                              <div className='text-label'>
                                <span>
                                  Add or remove columns <br /> (Channel map id)
                                </span>
                              </div>
                            </li>

                            {chCountListData.map((list) => (
                              <MenuItem key={list.id}>
                                {list.type === 'checkbox' && (
                                  <CheckBox label={list.label} value={list.label} defaultChecked={false} />
                                )}
                              </MenuItem>
                            ))}
                          </StyledMenu>
                        </div>
                        <CustomButtonGroup buttons={buttons} justifyContent='flex-end' />
                      </Box>
                    </Box>
                  </Box>

                  {/* Result */}
                  <Box className='white-box white-box-wrap'>
                    <div className='box-content'>
                      <Box className='white-box-content-top-area'>
                        <Box className='left-area'>
                          <Box className='total-count'>
                            <Box component='span' className='label'>
                              Total
                            </Box>
                            <Box component='span' className='value'>
                              127
                            </Box>
                          </Box>
                        </Box>
                        <Box className='right-area'>
                          <Button
                            color='tertiary'
                            type='outline'
                            size='small'
                            label='Excel Dounload'
                            isIcon={false}
                            autosize
                          />
                          <SelectBox
                            id='select-search'
                            size='medium'
                            selectOptions={selectOption}
                            label=''
                            maxWidth='180px'
                            disabled={false}
                            placeholder=''
                          />

                          <SearchField
                            id='search-2'
                            size='medium'
                            maxWidth='190px'
                            placeholder='Please enter a search term'
                          />

                          <div className='type-button-dropdown'>
                            <Button
                              color=''
                              type='only-icon'
                              size='small'
                              label=''
                              isIcon={false}
                              className='btn-layer-popup'
                              onClick={handleClick}
                            />

                            <StyledMenu
                              id='demo-customized-menu'
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              className='btn-drop-paper'
                              PaperProps={{
                                sx: {
                                  maxHeight: '230px',
                                },
                              }}
                            >
                              <div className='text-label'>
                                <span>
                                  Add or remove columns <br /> (Channel map id)
                                </span>
                              </div>

                              {chCountListData.map((list) => (
                                <MenuItem key={list.id}>
                                  {list.type === 'checkbox' && (
                                    <CheckBox label={list.label} value={list.label} defaultChecked={false} />
                                  )}
                                </MenuItem>
                              ))}
                            </StyledMenu>
                          </div>
                        </Box>
                      </Box>
                      <Box className='content-inner-wrap'>
                        {/* Table */}
                        <CustomTableContainer className='table-container'>
                          <Table className='custom-table' aria-label='table' stickyHeader>
                            {/* table Head */}
                            <TableHead className='thead'>
                              <TableRow className='tr'>
                                {ChannelMapTh.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    className='th'
                                    align={column.align}
                                    sx={{ width: column.width }}
                                  >
                                    {column.checkbox ? (
                                      <>
                                        <CheckBox
                                          label=''
                                          value='select-all'
                                          defaultChecked={false}
                                          inputProps={{
                                            'aria-label': 'select all',
                                          }}
                                        />
                                        <TableSortLabel
                                          active={orderBy === column.id}
                                          direction={orderBy === column.id ? order : 'desc'}
                                          onClick={() => handleRequestSort(column.id)}
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      </>
                                    ) : (
                                      <>
                                        {column.sortable ? (
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'desc'}
                                            onClick={() => handleRequestSort(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        ) : (
                                          column.label
                                        )}
                                      </>
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            {/* table Body */}
                            <TableBody className='tbody'>
                              {sortedChannelMapTdRows.map((td, index) => (
                                <TableRow className='tr' key={td.id || index}>
                                  {ChannelMapTh.map((column, colIndex) => (
                                    <TableCell key={column.id} className='td' align={column.align}>
                                      {td[`data${colIndex + 1}`]}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </CustomTableContainer>
                        {/* // Table */}
                      </Box>

                      <Pagination id='pagination-01' count={5} selOptionData={selOptionData} />
                    </div>
                  </Box>
                </div>
              </div>
            )}
            {/* Channel Count */}
          </div>
        </Box>
      </Box>

      {/*
       Toast Pop-Up
       - severity: default, error, warning, info, success
      */}
      <Snackbar severity='success' message='success' open={false} duration={1000} bottom={50} darkMode={darkMode} />
    </Box>
  );
}

export default ChannelStatus;
